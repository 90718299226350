import { IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import './Matches.css'; // TODO - transfer relevant to matchmaking.css

import {
  IonSpinner,
  IonButton,
  IonCard,
  IonCardTitle,
  IonCardSubtitle,
  IonCardHeader,
  IonCardContent,
  IonImg
 } from '@ionic/react';

import './Matches.css';
import MatchCard from './MatchCard';
import MatchmakingCard from './MatchmakingCard';
import MatchmakingSearchFilter from './MatchmakingSearchFilter';
import MatchmakingCreateMatch from './MatchmakingCreateMatch';
import MatchmakingFindMatch from './MatchmakingFindMatch';

import { } from 'ionicons/icons';
import { useEffect, useState, useRef} from 'react';
import { useSelector } from 'react-redux';
import {
  readUserMatches,
  readUserMatchHistory,
  readUserMatchmakingMatches,
  readUserMatchById,
  readOpenMatchmakingMatches,
  readGame,
  readGames,
  readSplitById,
  readUserChallengeMatches
} from '../../firebase';

import moment from 'moment-timezone';

import { timezones } from '../../timezones';

interface MatchmakingProps {
  setCurrentPage?: any;
  matchId?: any;
}

const Matchmaking: React.FC<MatchmakingProps> = ({setCurrentPage}) => {
  const [busy, setBusy] = useState(true); //todo set to true when we use this

  const [matches, setMatches] = useState<any[]>([]);
  const [games, setGames] = useState<any[]>([]);
  const [splits, setSplits] = useState<any[]>([]);
  const [splitId, setSplitId] = useState<string>('');
  const [players, setPlayers] = useState<any[]>([]);
  const [gameId, setGameId] = useState<string>('');
  const [gameType, setGameType] = useState<string>('');
  const [gameRounds, setGameRounds] = useState<string>('');

  const [matchesFilters, setMatchesFilters] = useState<string[]>([]);
  const [matchesSort, setMatchesSort] = useState<string>('');
  const [matchesGamesFilter, setMatchesGamesFilter] = useState<any>("");
  const [tempOpponentSearchStrings, setTempOpponentSearchStrings] = useState<any>({});

  const [searchText, setSearchText] = useState('');

  const [count, setCount] = useState<number>(0);

  const [pageContent, setPageContent] = useState<any>('');

  const gamesSelectRef = useRef<HTMLIonSelectElement>(null);

  const userId = useSelector((state: any) => state.userData.uid);

  useEffect(() => {
    //console.log("Use Effect: [matches] Dependencies - Running - Matches.tsx - MatchesMatchmaking - Line 179"); //TODO - remove debugging

    /** Using isMounted to prevent React state updates on unmounted components based on this: https://stackoverflow.com/questions/53949393/cant-perform-a-react-state-update-on-an-unmounted-component **/
    let isMounted = true;
    readUserMatchmakingMatches(userId, (m: any[]) => {
        if (JSON.stringify(m) === JSON.stringify(matches)){
          if (isMounted) setBusy(false);
          //console.log("Matches are the same");
        }
        else {
          if (isMounted) setMatches(m);
          //console.log("Matches are different");
          //console.log(m);
        }
    });

    return () => { isMounted = false };

  }, []);

  useEffect(() => {
    //console.log("Use Effect: [matches] Dependencies - Running - Matches.tsx - MatchesMatchmaking - Line 179"); //TODO - remove debugging

    /** Using isMounted to prevent React state updates on unmounted components based on this: https://stackoverflow.com/questions/53949393/cant-perform-a-react-state-update-on-an-unmounted-component **/
    let isMounted = true;

    (async () => {
      await readGames(async (g:any)=>{
        if (isMounted) setGames(g);

        var tempSplits:any[] = [];
        var gamesAmount = Object.keys(g).length;
        var gamesCount = 0;

        for (var id in g){
          gamesCount++;
          if (g[id].splitIds) {
            var splitsAmount = Object.keys(g[id].splitIds).length;
            var splitsCount = 0;
            for (var splitId in g[id].splitIds){
              splitsCount++;
              if (g[id].splitIds[splitId]){
                await readSplitById(splitId, (s:any)=>{
                  tempSplits.push({
                    gameName: s.gameName,
                    gameType: s.gameType,
                    gameRounds: s.gameRounds,
                    id: s.splitId,
                    name: s.name
                  });
                });
              }
              if (splitsCount == splitsAmount && gamesCount == gamesAmount){
                if (isMounted) setSplits(tempSplits);
              }
            }
          }

        }
      });
      if (matchesGamesFilter){
          await readSplitById(matchesGamesFilter.id, (s:any)=>{
            //if (isMounted) setSplitId(s.id);
            var tempPlayers:string[] = [];

            for (var id in s.players){
              if (s.players[id]){
                tempPlayers.push(id);
              }
            }
            if (isMounted) setPlayers(tempPlayers);
          });
      }
    })();

    readUserMatchmakingMatches(userId, (m: any[]) => {
      if (matchesGamesFilter){

        //if (isMounted) setGameId(matchesGamesFilter.name);

        var gamesFilterNames: string[] = [];
        gamesFilterNames.push(matchesGamesFilter.gameName);

        var gamesFilteredMatches = m.filter((match) => {
          return gamesFilterNames.includes(match.gameName);
        });

        if (searchText){
          gamesFilteredMatches = gamesFilteredMatches.filter((ele:any) => {

            if (ele.gameId.replace(/_/g, ' ').toLowerCase().includes(searchText.toLowerCase())){
              return true;
            }
            else if (ele.gameName.replace(/_/g, ' ').toLowerCase().includes(searchText.toLowerCase())){
              return true;
            }
            else if (ele.gameRounds.replace(/_/g, ' ').toLowerCase().includes(searchText.toLowerCase())){
              return true;
            }
            else if (ele.gameType.replace(/_/g, ' ').toLowerCase().includes(searchText.toLowerCase())){
              return true;
            }
            else {

              if (typeof(tempOpponentSearchStrings[ele.id]) != "undefined"){
                for (var i=0; i<tempOpponentSearchStrings[ele.id].length; i++){
                  if (tempOpponentSearchStrings[ele.id][i].replace(/_/g, ' ').toLowerCase().includes(searchText.toLowerCase())){
                    return true;
                  }
                }
              }
            }
            return false;

          });
        }

        if (JSON.stringify(gamesFilteredMatches) !== JSON.stringify(matches)){
          if (isMounted) setMatches(gamesFilteredMatches);
        }

        //Set the splitId from the given game
        readGame(matchesGamesFilter.gameName, (g:any)=>{
          for (var id in g.splitIds){
            if (g.splitIds[id]){
              //if (isMounted) setSplitId(id); // Split ID should be set by the menu instead
            }
          }
        });

      }
      else if (matchesGamesFilter.length > 0){

        var gamesFilterNames: string[] = [];
        for(var i=0; i<matchesGamesFilter.length; i++){
          gamesFilterNames.push(matchesGamesFilter[i].name);
        }
        var gamesFilteredMatches = m.filter((match) => {
          return gamesFilterNames.includes(match.gameName);
        });
        if (JSON.stringify(gamesFilteredMatches) !== JSON.stringify(matches)){
          if (isMounted) setMatches(gamesFilteredMatches);
        }
      }
      else if (matchesGamesFilter.length == 0 && searchText == ''){
        if (JSON.stringify(m) === JSON.stringify(matches)){
          //console.log("Matches are the same");
        }
        else {
          if (isMounted) setMatches(m);
          //console.log("Matches are different");
          //console.log(m);
        }
      }
    });

    return () => { isMounted = false };
  }, [matchesGamesFilter]);

  useEffect(() => {
    let isMounted = true;

    if (isMounted) setBusy(false);
    //console.log("Use Effect: [matches, matchesSort, matchesGamesFilter] Dependencies - Running - Matches.tsx - 4"); //TODO - remove debugging
    switch(matchesSort){
      case 'dateAscending':
        if (isMounted) setMatches(matches.sort((a, b) => {
          if (moment(a.actual_time).isAfter(moment(b.actual_time))){
            return 1; //Sort B before A
          }
          else {
            return -1;
          }
        }));
        break;
      case 'dateDescending':
        if (isMounted) setMatches(matches.sort((a, b) => {
          if (moment(a.actual_time).isAfter(moment(b.actual_time))){
            return -1; //Sort A before B
          }
          else {
            return 1;
          }
        }));
        break;
      default:
        break;
    }

    return () => { isMounted = false };
  }, [matchesSort]);


  return (
    <>
    {busy ? <IonSpinner /> :
      <div className="matches-container container">
        <div className="matches-matchmaking-header">
          <h1 className="matches-matchmaking-title"> 2024 Season</h1>

          <MatchmakingSearchFilter setMatchesSort={setMatchesSort} setMatchesGamesFilter={setMatchesGamesFilter} matchesGamesFilter={matchesGamesFilter} gamesSelectRef={gamesSelectRef} matches={matches} setMatches={setMatches} searchText={searchText} setSearchText={setSearchText} tempOpponentSearchStrings={tempOpponentSearchStrings} setTempOpponentSearchStrings={setTempOpponentSearchStrings} setGameId={setGameId} setGameType={setGameType} splits={splits} setSplits={setSplits} setGameRounds={setGameRounds}/>
          {/*<MatchmakingCreateMatch setMatches={setMatches} matches={matches} splitId={splitId}/>*/}
          {/*(matchesGamesFilter ? <MatchmakingFindMatch setMatches={setMatches} matches={matches}/> : "")*/}
        </div>
        {/** TODO - Display "matchmaking" matches on the matchmaking view **/}
        {/**TODO - Filter out "matchmaking" matches that belong to the user from the matchmaking view  **/}
        <div className="match-card-container">
        {
          (matchesGamesFilter ?

            (players.length ?

              players.map((player:any, index:any) => {
                  if (player != userId){
                    return (<MatchmakingCard key={index} gameId={gameId} gameType={gameType} playerId={player} splitId={splitId} gameRounds={gameRounds}/>);
                  }
                })
            :
              (searchText ?
                <div>
                  <h1>Nothing found for "{searchText}".</h1>
                  <p> Try clearing the search bar</p>
                </div>
                :
                <div>
                  <h1>There are no available matches. <a className="matches-matchmaking-link"
                    onClick={()=>{
                      setMatchesGamesFilter("");
                      gamesSelectRef.current && (gamesSelectRef.current.value = null);
                    }}
                  >
                    Go back.
                  </a></h1>
                </div>
              )
            )
          :
          <div>
            <h2 className="matches-matchmaking-help-text"> Choose your game. </h2>
            <div className="matches-matchmaking-split-options">
              {
                splits.map((split:any, index)=>{

                  return (
                    <a className="matches-matchmaking-split-option-card-link-wrapper"
                    key={index}
                    onClick={() => {
                      setMatchesGamesFilter(split);
                      setSplitId(split.id);
                      gamesSelectRef.current && (gamesSelectRef.current.value = split);

                    }}>
                      <IonCard className="matches-matchmaking-split-option-card" key={index}>
                        <IonImg alt="Silhouette of mountains" className="matches-matchmaking-split-option-card-image" src={Object.values(games).filter((game:any) => game.name === split.gameName)[0].logoURL} />
                        <IonCardHeader className="matches-matchmaking-split-option-card-header">
                          <IonCardTitle>{split.gameName}</IonCardTitle>
                          <IonCardSubtitle>{split.name}</IonCardSubtitle>
                        </IonCardHeader>
                      </IonCard>
                    </a>
                  );
                })
              }
            </div>
          </div>
        )

        }
        </div>
      </div>
    }
    </>
  );
}

export default Matchmaking;
