import {
  IonButtons,
  IonButton,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
  IonSpinner,
  IonList,
  IonItem,
  IonImg,
  IonIcon,
  IonPopover,
  IonToast
} from '@ionic/react';
import './Users.css';

import {
  trashOutline,
  mailOutline,
  logoDiscord,
  checkmarkDoneCircleOutline,
  archiveOutline,
  refreshCircleOutline
} from 'ionicons/icons';

import UsersSearchFilter from './UsersSearchFilter';


import {
  readUserProfileById,
  readAllUsers,
  readGames,
  readBanners,
  readPictures,
  readBorders,
  archiveUser,
  undoArchiveUser
} from '../../firebase';

import { useEffect, useState, useRef} from 'react';
import { useSelector } from 'react-redux';

import moment from 'moment-timezone';
import { timezones } from '../../timezones';


const Users: React.FC = () => {

  const [busy, setBusy] = useState(true);
  const [filteredUsers, setFilteredUsers] = useState<any[]>([]);
  const [allUsers, setAllUsers] = useState<any[]>([]);

  const [banners, setBanners] = useState<any>([]);
  const [pictures, setPictures] = useState<any>([]);
  const [borders, setBorders] = useState<any>([]);

  const [archiveUserPopoverIsOpen, setArchiveUserPopoverIsOpen] = useState<boolean>(false);
  const [archivedUser, setArchivedUser] = useState<any>({});

  const [restoreUserPopoverIsOpen, setRestoreUserPopoverIsOpen] = useState<boolean>(false);

  const [showToast_archived, setShowToast_archived] = useState<boolean>(false);
  const [showToast_undoArchived, setShowToast_undoArchived] = useState<boolean>(false);

  const [showToast_restored, setShowToast_restored] = useState<boolean>(false);
  const [showToast_undoRestored, setShowToast_undoRestored] = useState<boolean>(false);


  useEffect(() => {
    /** Using isMounted to prevent React state updates on unmounted components based on this: https://stackoverflow.com/questions/53949393/cant-perform-a-react-state-update-on-an-unmounted-component **/
    let isMounted = true;
    document.title = "Esporter | Users";

    moment.relativeTimeThreshold('d', 40); //This makes it so that moment's .fromNow() function returns the precise number of days until it is past 40 days.

    readAllUsers((u: any[]) => {

      if (isMounted) setAllUsers(u.sort((a, b) => {
          if (moment(a.free_trial_started).isAfter(moment(b.free_trial_started))){
            return 1; //Sort A before B
          }
          else {
            return -1;
          }
        }));

      if (isMounted) setBusy(false);
    });

    return () => { isMounted = false };
  }, []);

  useEffect(() => {
    let isMounted = true;

    readBorders((borders: any)=>{
      var tempBorders = [];

      //For all of the borders in the database
      for (var id in borders){
        tempBorders.push(borders[id]);
      }

      if (isMounted) setBorders(tempBorders);
    });

    readBanners((banners: any)=>{
      var tempBanners = [];

      //For all of the borders in the database
      for (var id in banners){
        tempBanners.push(banners[id]);
      }
      if (isMounted) setBanners(tempBanners);
    });

    readPictures((pictures: any)=>{
      var tempPictures = [];

      //For all of the borders in the database
      for (var id in pictures){
        tempPictures.push(pictures[id]);
      }

      if (isMounted) setPictures(tempPictures);
    });

    return () => { isMounted = false };
  }, []);

  return (
    <>
    {busy ? <IonSpinner /> :
      <div className="users-container container">
        <div className="user-card-container">
        <UsersSearchFilter allUsers={allUsers} setAllUsers={setAllUsers} filteredUsers={filteredUsers} setFilteredUsers={setFilteredUsers}/>
          <p className="user-card-container-total-users-text"> Total Users: {filteredUsers.length}</p>
          <IonList className="user-card-list">
          {
              (filteredUsers.length ?
                  filteredUsers.map((user, index) => {
                      var tempLoginTime;

                      if (user.lastLoginAt){
                        tempLoginTime = parseInt(user.lastLoginAt);
                        if (tempLoginTime <= 9999999999){
                          tempLoginTime = tempLoginTime * 1000;
                        }
                      }

                      var pictureId = '';
                      var borderId = '';
                      var bannerId = '';

                      if (user.public && user.public.cosmetics){
                        if (user.public.cosmetics.picture){
                          pictureId = user.public.cosmetics.picture;
                        }

                        if (user.public.cosmetics.border){
                          borderId = user.public.cosmetics.border;
                        }

                        if (user.public.cosmetics.banner){
                          bannerId = user.public.cosmetics.banner;
                        }
                      }

                      return (
                        <IonItem key={index+""+user.userId} className ='user-card'>
                            <div className="user-card-bumper">
                            </div>
                            <div className="user-card-player-profile user-card-player-profile-left">
                              <IonImg src={(pictureId ? (pictures.filter((picture:any) => picture.id === pictureId)[0] ? pictures.filter((picture:any) => picture.id === pictureId)[0].imageURL : "https://esporter.win/wp-content/uploads/2022/03/AdobeStock_23725944-scaled.jpeg") : "https://esporter.win/wp-content/uploads/2022/03/AdobeStock_23725944-scaled.jpeg")} className="user-card-player-profile-image" />
                              <IonImg src={(borderId ? (borders.filter((border:any) => border.id === borderId)[0] ? borders.filter((border:any) => border.id === borderId)[0].imageURL : "https://esporter.win/wp-content/uploads/2022/07/esporter_profile_border_10.png") : "https://esporter.win/wp-content/uploads/2022/07/esporter_profile_border_10.png")} className="user-card-player-profile-border" />
                            </div>
                            <div className="user-card-name">
                              <div className="user-card-name-display">
                                {user.displayName}
                              </div>
                              <div className="user-card-name-id">
                                ID: {user.userId}
                              </div>
                            </div>
                            <div className="user-card-details">
                              <div className="user-card-details-top">
                              <div className="user-card-details-top-contact">
                                <div className="user-card-details-top-contact-text">
                                  <IonIcon slot="start" icon={mailOutline} className="user-card-details-top-contact-icon"/>
                                  {(user.email ? user.email : "email missing")}
                                </div>
                                <div className="user-card-details-top-contact-text">
                                  <IonIcon slot="start" icon={logoDiscord} className="user-card-details-top-contact-icon"/>
                                  {(user.discordId ? user.discordId : "discord missing")}
                                </div>
                              </div>
                              <div className="user-card-details-top-buttons">
                                {
                                  (
                                    user.isArchived ?
                                    <IonButton className="user-card-details-top-buttons-restore" fill="clear" onClick={()=>{
                                      setRestoreUserPopoverIsOpen(true);
                                      setArchivedUser(user);
                                    }}> <IonIcon icon={refreshCircleOutline}/></IonButton>
                                    :
                                    <IonButton className="user-card-details-top-buttons-delete" fill="clear" onClick={()=>{
                                      setArchiveUserPopoverIsOpen(true);
                                      setArchivedUser(user);
                                    }}> <IonIcon icon={archiveOutline}/></IonButton>
                                  )
                                }
                              </div>
                              </div>
                              <div className="user-card-details-info">
                                <p className="user-card-details-info-trial">
                                  Trial Started: {( user.free_trial_started ? moment(user.free_trial_started).startOf('day').fromNow() : "No Trial")}
                                </p>
                                <p className="user-card-details-info-registered">
                                  Registered: {( user.firebase_synced_at ? moment(user.firebase_synced_at*1000).startOf('day').fromNow() : "Invalid Reg.")}
                                </p>
                                <p className="user-card-details-info-login">
                                  Last Login: {( tempLoginTime ? moment(tempLoginTime).startOf('day').fromNow() : "Never")}
                                </p>
                              </div>
                            </div>
                        </IonItem>
                      );
                    })
              :
                  <div>
                    <h1>We could not find any users.</h1>
                    <p>Try searching by another term.</p>
                  </div>
              )

          }
          </IonList>
        </div>
      </div>
    }
      <IonPopover className="archive-user-popover" isOpen={archiveUserPopoverIsOpen} onDidDismiss={ () => {setArchiveUserPopoverIsOpen(false);}} >
        <div className="archive-user-popover-container">
          <h3> Archive {archivedUser.displayName}</h3>
          <IonButtons className="archive-user-buttons">
            <IonButton color="medium" onClick={()=>{
              setArchiveUserPopoverIsOpen(false);
            }}>Cancel</IonButton>
            <IonButton color="danger" onClick={()=>{
              setShowToast_archived(true);
              archiveUser(archivedUser.userId, ()=>{});
              setArchiveUserPopoverIsOpen(false);
            }}>Archive</IonButton>
          </IonButtons>
        </div>
      </IonPopover>
      <IonPopover className="archive-user-popover" isOpen={restoreUserPopoverIsOpen} onDidDismiss={ () => {setRestoreUserPopoverIsOpen(false);}} >
        <div className="archive-user-popover-container">
          <h3> Restore {archivedUser.displayName}</h3>
          <IonButtons className="archive-user-buttons">
            <IonButton color="medium" onClick={()=>{
              setRestoreUserPopoverIsOpen(false);
            }}>Cancel</IonButton>
            <IonButton color="primary" onClick={()=>{
              setShowToast_restored(true);
              undoArchiveUser(archivedUser.userId, ()=>{});
              setRestoreUserPopoverIsOpen(false);
            }}>Restore</IonButton>
          </IonButtons>
        </div>
      </IonPopover>
      <IonToast
        isOpen={showToast_archived}
        onDidDismiss={() => setShowToast_archived(false)}
        message="User Archived"
        duration={10000}
        color="primary"
        icon={checkmarkDoneCircleOutline}
        cssClass="success-toast"
        buttons={[
         {
           text: 'Undo',
           role: 'info',
           handler: () => {
             undoArchiveUser(archivedUser.userId, ()=>{});
             setShowToast_undoArchived(true);
           },
         }
       ]}
      />
      <IonToast
        isOpen={showToast_undoArchived}
        onDidDismiss={() => setShowToast_undoArchived(false)}
        message="User Restored"
        duration={2000}
        color="primary"
        icon={checkmarkDoneCircleOutline}
        cssClass="success-toast"
      />
      <IonToast
        isOpen={showToast_restored}
        onDidDismiss={() => setShowToast_restored(false)}
        message="User Restored"
        duration={10000}
        color="primary"
        icon={checkmarkDoneCircleOutline}
        cssClass="success-toast"
        buttons={[
         {
           text: 'Undo',
           role: 'info',
           handler: () => {
             archiveUser(archivedUser.userId, ()=>{});
             setShowToast_undoRestored(true);
           },
         }
       ]}
      />
      <IonToast
        isOpen={showToast_undoRestored}
        onDidDismiss={() => setShowToast_undoRestored(false)}
        message="User Archived"
        duration={2000}
        color="primary"
        icon={checkmarkDoneCircleOutline}
        cssClass="success-toast"
      />
    </>
  );
};

export default Users;
