import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { app, auth, user } from '../../firebase';

import {
  IonButtons,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
  IonImg,
  IonButton,
  IonSpinner,
  IonList,
  IonItem,
  IonLabel,
  IonInput,
  IonToast
} from '@ionic/react';

import { useSelector } from 'react-redux';
import moment from 'moment-timezone';

import {
  readGames
} from '../../firebase';

import './ProfileUpcomingMatch.css';

interface ProfileUpcomingMatchProps {
  match?: any;
}

const ProfileUpcomingMatch: React.FC<ProfileUpcomingMatchProps> = ({match}) => {

  const userId = useSelector((state: any) => state.userData.uid);

  const [game, setGame] = useState<any>("");

  var matchURL = "/Matches/" + match.id;

  useEffect(() => {
    /** Using isMounted to prevent React state updates on unmounted components based on this: https://stackoverflow.com/questions/53949393/cant-perform-a-react-state-update-on-an-unmounted-component **/
    let isMounted = true;

    readGames((g: any) => {

      for (var key in g) {
        if (key == match.gameId){
          if (isMounted) setGame(g[key]);
        }
      }
    });

    return () => { isMounted = false };
  }, []);


  return (
    <>
      <a href={matchURL} className="profile-upcoming-match">
        <IonImg className="profile-upcoming-match-image" src={(game.logoURL ? game.logoURL : "https://esporter.win/wp-content/uploads/2022/02/G_Arena_EN_1C_White_V14_Medium_72DPI1.svg")}/>
        <div className="profile-upcoming-match-info">
          <div className="profile-upcoming-match-info-details">
            <p className="profile-upcoming-match-info-details-game-type">
              {(match.gameType ? match.gameType : "Limited")}
            </p>
            <p className="profile-upcoming-match-info-details-game-rounds">
              {(match.gameRounds ? match.gameRounds : "Best of 3")}
            </p>
          </div>
          <div className="profile-upcoming-match-info-date-time">
            <p className="profile-upcoming-match-info-time">
              {(match.actual_time ? moment(match.actual_time).format("h:mma") : "Time TBD")}
            </p>
            <p className="profile-upcoming-match-info-time-date">
              {(match.actual_time ? moment(match.actual_time).format("dddd, MMM Do") : "Day TBD")}
            </p>
          </div>
        </div>
      </a>
    </>
  );
}

export default ProfileUpcomingMatch;
