import {
  IonButtons,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardSubtitle,
  IonCardContent,
  IonItem,
  IonLabel,
  IonList,
  IonAccordionGroup,
  IonAccordion,
  IonToggle,
  IonIcon,
  IonButton,
  IonBadge,
  IonImg,
  IonText,
  IonInput,
  IonRadioGroup,
  IonRadio,
  IonListHeader,
  IonToast,
  useIonAlert
 } from '@ionic/react';

 import {
   writeUserAvailabilityTime,
   readUserProfileById,
   updateMatchWithUserStatus,
   readUserMatchById,
   updateMatchStatus,
   updateMatchWithUserScore,
   updateMatchWithUser
 } from '../../firebase';

import { arrowDownCircle, pin, calendarClear, trophySharp, gameControllerSharp, logoDiscord, informationCircleSharp, pencilSharp, closeCircleOutline, playCircleOutline, checkmarkDoneCircleOutline } from 'ionicons/icons';
import './MatchCardPanel.css';

import React, { useRef, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import moment from 'moment-timezone';

var currentPage:any;
var setCurrentPage:any;

interface MatchCardPanelProps {
  match: any;
  setCurrentPage?: any;
  matchmaking?: boolean;
  showToasts?: any;
  setShowToasts?: any;
}

const MatchCardPanelAwaiting: React.FC<MatchCardPanelProps> = ({match, setCurrentPage}) => {

  const userId = useSelector((state: any) => state.userData.uid);

  useEffect(()=>{
    //console.log("Expected render for awaiting");
    if (match.id){
      //console.log("Expected Render for Match ID: "+match.id);
      readUserMatchById(match.id, (m: any) => {
        var allReady: boolean = false;
        var playersCount = 0;
        var status = "awaiting";

        for(var id in m.players){
          playersCount++;
        }

        var readyCount = 0;
        for(var id in m.players){
          if (m.players[id].status == "awaiting") {
            readyCount++;
          }
        }

        if (readyCount == playersCount){
          allReady = true;
        }

        if (allReady) {
          for(var id in m.players){
            updateMatchWithUserStatus(id, match, "inProgress");
          }
          updateMatchStatus(match, "inProgress");

          status = "inProgress";
        }

        setCurrentPage(status);

      });
    }
  }, [currentPage, match]);

  return (
        <div className="match-card-awaiting-details">
          <section className="animation-container">
            <div>
            <div>
              <span className="one h6"></span>
              <span className="two h3"></span>
            </div>
            </div>
            <div>
            <div>
              <span className="one h1"></span>
              <span className="two h4"></span>
            </div>
            </div>
            <div>
            <div>
              <span className="one h5"></span>
              <span className="two h2"></span>
            </div>
            </div>
          </section>
          <p>
            Waiting for your opponent to start...
          </p>
          <IonButton className="match-card-awaiting-cancel-button" fill="clear" onClick={(e: any)=>{
            //console.log("Start Match Cancel Button: "+match.id); //todo remove debugging
            updateMatchWithUserStatus(userId, match, "start");
            setCurrentPage("start");
          }}>
            Cancel
          </IonButton>
        </div>
  );
}

const MatchCardPanelStart: React.FC<MatchCardPanelProps> = ({match, setCurrentPage, matchmaking}) => {

  const userId = useSelector((state: any) => state.userData.uid);

  const [showToast_accept, setShowToast_accept] = useState<boolean>(false);

  return (
            matchmaking ?

               (match.players[userId] ? match.players[userId].status == "challenge-issued" : false)
               ?
               <IonButton className="match-card-start-button" disabled={true}>
                  Pending
               </IonButton>
               :
               <div>
                 <IonButton className="match-card-start-button" onClick={(e: any)=>{
                   //updateMatchWithUser(userId, match);
                   updateMatchStatus(match, "start");
                   setShowToast_accept(true);
                   //setCurrentPage("start");
                 }}>
                   Accept
                 </IonButton>
                 <IonToast
                   isOpen={showToast_accept}
                   onDidDismiss={() => setShowToast_accept(true)}
                   message="Match Accepted"
                   duration={2000}
                   color="primary"
                   icon={checkmarkDoneCircleOutline}
                   cssClass="accept-match-toast"
                 />
               </div>
            :
            <IonButton className="match-card-start-button" onClick={(e: any)=>{
              //console.log("Awaiting Match Button: "+match.id); //todo remove debugging
              updateMatchWithUserStatus(userId, match, "inProgress");
              updateMatchStatus(match, "inProgress");
              setCurrentPage("inProgress");
              //console.log("Current Page set to awaiting");
            }}>
              Start
            </IonButton>
      );
}

const MatchCardPanelDone: React.FC<MatchCardPanelProps> = ({match, setCurrentPage}) => {

  const userId = useSelector((state: any) => state.userData.uid);

  const [playerWon, setPlayerWon] = useState(true);
  const [title, setTitle] = useState("");
  const [recordedPlayerScore, setRecordedPlayerScore] = useState(0);
  const [recordedOpponentScore, setRecordedOpponentScore] = useState(0);

  useEffect(()=>{
    if (match.id){
      readUserMatchById(match.id, (m: any) => {
        var t = "Unsupported Match";
        var rps = 0;
        var ros = 0;
        var pw = false;

        for (var id in m.players){
          if (!m.players[userId]){
            break;
          }
          if (!m.players[userId].score){
            break;
          }

          if (id == userId){
            rps = m.players[userId].score[id];

          }
          else {
            ros = m.players[userId].score[id];
          }
        }

        if (!m.players[userId]){
          t = "Unsupported match";
        }
        else if (m.players[userId].isWinner){
          pw = true;
          t = "You Won!";
        }
        else {
          pw = false;
          t = "Your Opponent Won";
        }

        setRecordedPlayerScore(rps);
        setRecordedOpponentScore(ros);
        setTitle(t);
        setPlayerWon(pw);


      });
    }
  }, [currentPage, match]);



  return (
    <div className="match-card-center-details-item match-card-center-details-score">
      <div className="match-card-center-details-score-item match-card-center-details-score-left">
        <IonText className="match-card-center-details-score-left">
          <p>{recordedOpponentScore}</p>
        </IonText>
      </div>
      <div className="match-card-center-details-score-item match-card-center-details-score-vs">
        <div className="match-card-center-details-score-vs-diamond">
          <IonText className="match-card-center-details-vs-text">
            <p>VS</p>
          </IonText>
        </div>
      </div>
      <div className="match-card-center-details-score-item match-card-center-details-score-right">
        <IonText className="match-card-center-details-score-right">
          <p>{recordedPlayerScore}</p>
        </IonText>
      </div>
    </div>
    /**
      <div className="match-card-details">
        <div className="match-card-panel-done">
          <IonItem className="match-card-panel-done-title">
            <IonLabel>{title}</IonLabel>
          </IonItem>
          {match.wasConcededBy ? <p className="match-card-panel-done-conceded-text">CONCEDED</p> : ''}
          <IonItem className="match-card-details-list-item">
            <IonLabel>Your Score: <b>{recordedPlayerScore}</b></IonLabel>
          </IonItem>
          <IonItem className="match-card-details-list-item">
            <IonLabel>Opponent's Score: <b>{recordedOpponentScore}</b></IonLabel>
          </IonItem>
        </div>
      </div>
    **/
  );
}

const MatchCardPanelInProgress: React.FC<MatchCardPanelProps> = ({match, setCurrentPage}) => {

  const userId = useSelector((state: any) => state.userData.uid);

  const [scoreReporting, setScoreReporting] = useState<boolean>();
  const [playerScore, setPlayerScore] = useState(0);
  const [opponentScore, setOpponentScore] = useState(0);
  const [winner, setWinner] = useState('opponent');

  const [present] = useIonAlert();

  useEffect(()=>{
    if (match.id){
      readUserMatchById(match.id, (m: any) => {
        var allDone: boolean = false;
        var playersCount = 0;
        var status = "inProgress";

        for(var id in m.players){
          playersCount++;
        }

        var doneCount = 0;
        for(var id in m.players){
          if (m.players[id].status == "done") {
            doneCount++;
          }
        }

        if (doneCount == playersCount){
          allDone = true;
        }


        if (allDone) {
          status = "done";
          updateMatchStatus(match, "done");
        }

        setCurrentPage(status);
      });
    }
  }, [currentPage, match]);

  return (
      <div>
        <div className="match-card-center-details-item match-card-center-details-score">
          <div className="match-card-center-details-score-item match-card-center-details-score-left">
            <p className="match-card-center-details-score-label"> Opponent Score </p>
            <IonInput
              placeholder="0"
              inputmode="numeric"
              min="0"
              name="player-2-score"
              required={true}
              type="number"
              className="match-card-in-progress-scoreboard-input match-card-center-details-score-left"
              onIonChange={e => {
                const number = parseInt(e.detail.value!);
                setOpponentScore(number);
              }}
            ></IonInput>
          </div>
          <div className="match-card-center-details-score-item match-card-center-details-score-vs">
            <div className="match-card-center-details-score-vs-diamond">
              <IonText className="match-card-center-details-vs-text">
                <p>VS</p>
              </IonText>
            </div>
          </div>
          <div className="match-card-center-details-score-item match-card-center-details-score-right">
            <p className="match-card-center-details-score-label"> Your Score </p>
            <IonInput
              placeholder="0"
              inputmode="numeric"
              min="0"
              name="player-1-score"
              required={true}
              type="number"
              className="match-card-in-progress-scoreboard-input match-card-center-details-score-right"
              onIonChange={e => {
                const number = parseInt(e.detail.value!);
                setPlayerScore(number);
              }}
            ></IonInput>
          </div>
        </div>
        <div>
          <IonButton className="match-card-in-progress-submit" onClick={(e: any)=>{
            //TODO - show popup to the player showing them the score and asking them to confirm
            setScoreReporting(true);
            var winnerRenamed = '';
            if (playerScore > opponentScore){
              setWinner("player");
              winnerRenamed = "You won";
            }
            else if (playerScore == opponentScore){
              setWinner("tie");
              winnerRenamed = "You tied";
            }
            else {
              setWinner("opponent");
              winnerRenamed = "Your opponent won";
            }

            var message = "Your score: " + playerScore + "<br>Opponent Score: " + opponentScore + "<br>Winner: " + winnerRenamed;

            present({cssClass: 'popup',
            header: 'Please Confirm Score',
            message: message,
            buttons: [
              'Cancel',
              {
                text: 'Ok', handler: (d) => {
                  console.log("Updating match: "+match.id+" with player score: "+playerScore+" and opponent score: "+opponentScore); //TODO -remove debugging
                  updateMatchWithUserScore(userId, match, playerScore, opponentScore, winner);
                  setCurrentPage("done");
                }
              }
            ],
            onDidDismiss: (e) => console.log('did dismiss')
          });
          }}>
            Submit
          </IonButton>
        </div>
      </div>
      /**
      <div className="match-card-in-progress">
        <h3 className="match-card-in-progress-message">
          Game in progress... Good luck!
        </h3>
        <div className= "match-card-in-progress-scoreboard">
          <div className="match-card-in-progress-scoreboard-player-1">
            <p> Your Score </p>
            <IonInput placeholder="0" inputmode="numeric" min="0" name="player-1-score" required={true} type="number" className="match-card-in-progress-scoreboard-input" onIonChange={e => {
              const number = parseInt(e.detail.value!);
              setPlayerScore(number);
            }}></IonInput>
          </div>
          <div className="match-card-in-progress-scoreboard-player-2">
            <p> Opponent's Score </p>
            <IonInput placeholder="0" inputmode="numeric" min="0" name="player-2-score" required={true} type="number" className="match-card-in-progress-scoreboard-input" onIonChange={e => {
              const number = parseInt(e.detail.value!);
              setOpponentScore(number);
            }}></IonInput>
          </div>
        </div>
        <div className="match-card-in-progress-scoreboard-winner">
          <IonRadioGroup className="match-card-in-progress-scoreboard-winner-radio-group" onIonChange={e => {
            setWinner(e.detail.value!);
          }}>
            <IonItem className="match-card-in-progress-scoreboard-winner-radio-group-item">
              <IonRadio className="match-card-in-progress-scoreboard-winner-radio-group-radio" value="player" />
              <IonLabel className="match-card-in-progress-scoreboard-winner-radio-group-item-label">Winner?</IonLabel>
            </IonItem>
            <IonItem className="match-card-in-progress-scoreboard-winner-radio-group-item">
              <IonRadio className="match-card-in-progress-scoreboard-winner-radio-group-radio" value="opponent" />
              <IonLabel className="match-card-in-progress-scoreboard-winner-radio-group-item-label">Winner?</IonLabel>
            </IonItem>
          </IonRadioGroup>
        </div>
        <div className="match-card-in-progress-buttons">
          <IonButton className="match-card-in-progress-submit" fill="outline" onClick={(e: any)=>{
            //TODO - show popup to the player showing them the score and asking them to confirm
            setScoreReporting(true);
            var winnerRenamed = '';
            if (winner == "player"){
              winnerRenamed = "You won";
            }
            else {
              winnerRenamed = "Your opponent won";
            }
            var message = "Your score: " + playerScore + "<br>Opponent Score: " + opponentScore + "<br>Winner: " + winnerRenamed;

            present({cssClass: 'popup',
            header: 'Please Confirm Score',
            message: message,
            buttons: [
              'Cancel',
              {
                text: 'Ok', handler: (d) => {
                  updateMatchWithUserScore(userId, match, playerScore, opponentScore, winner);
                  setCurrentPage("done");
                }
              }
            ],
            onDidDismiss: (e) => console.log('did dismiss')
          });
          }}>
            Submit
          </IonButton>
          <IonButton className="match-card-in-progress-cancel" fill="clear" onClick={(e: any)=>{
            //TODO - Show popup to the player that asks them if they are sure
            //setCurrentPage("start");
            present({
              cssClass: 'popup',
              header: 'Are you sure?',
              message: 'Please confirm that you want to concede the game and accept a loss.',
              buttons: [
                'Cancel',
                {
                  text: 'Ok', handler: (d) => {
                    // TODO: Update the whole game to be done
                    // TODO: Update the opponent's screen to show a popup that their opponent has forfeited
                    updateMatchWithUserScore(userId, match, 0, 0, "opponent");
                    updateMatchStatus(match, "done", true, userId);
                    setCurrentPage("done");
                  }
                }
              ],
              onDidDismiss: (e) => console.log('did dismiss')
            });
          }}>
            Concede
          </IonButton>
        </div>
      </div>
       **/
  );
}

const MatchCardPanel: React.FC<MatchCardPanelProps> = ({match, matchmaking, showToasts, setShowToasts}) => {

  const userId = useSelector((state: any) => state.userData.uid);

  var status: any;

  if (!match.players){
    //do nothing
  }
  else {
    if (!match.players[userId]){
    status = "start";
    }
    else if (!match.players[userId]["status"]){
      status = "start";
    }
    else {
      status = match.players[userId]["status"];
    }
  }

  [currentPage, setCurrentPage] = useState(status);

  var matchCardPanelSectionKey: any;

  useEffect(()=>{
    let isMounted = true;

    readUserMatchById(match.id, (m:any)=>{
      if (isMounted) setCurrentPage(m.status);
    });

    return () => { isMounted = false };
  }, [currentPage, match]);

  switch (currentPage){
    case "start":
      //console.log("Start Match: "+match.id); //todo remove debugging
      matchCardPanelSectionKey = (match.id ? match.id : "") + " - " + match.id + " - MatchCardPanelSectionStart";
      return (<MatchCardPanelStart key={matchCardPanelSectionKey} match={match} matchmaking={matchmaking} setCurrentPage={setCurrentPage} showToasts={showToasts} setShowToasts={setShowToasts}/>);
    case "inProgress":
      matchCardPanelSectionKey = (match.id ? match.id : "") + " - " + match.id + " - MatchCardPanelSectionInProgress";
      //console.log("In Progress Match: "+match.id); //todo remove debugging
      return (<MatchCardPanelInProgress key={matchCardPanelSectionKey} match={match} setCurrentPage={setCurrentPage}/>);
    case "awaiting":
      matchCardPanelSectionKey = (match.id ? match.id : "") + " - " + match.id + " - MatchCardPanelSectionAwaiting";
      //console.log("Awaiting Match: "+match.id); //todo remove debugging
      return (<MatchCardPanelAwaiting key={matchCardPanelSectionKey} match={match} setCurrentPage={setCurrentPage}/>);
    case "done":
      matchCardPanelSectionKey = (match.id ? match.id : "") + " - " + match.id + " - MatchCardPanelSectionDone";
      //console.log("Done Match: "+match.id); //todo remove debugging
      return (<MatchCardPanelDone key={matchCardPanelSectionKey} match={match} setCurrentPage={setCurrentPage}/>);
    default:
      matchCardPanelSectionKey = (match.id ? match.id : "") + " - " + match.id + " - MatchCardPanelSectionStart";
      //console.log("DEFAULT Start Match: "+match.id); //todo remove debugging
      return (<MatchCardPanelStart key={matchCardPanelSectionKey} match={match} matchmaking={matchmaking} setCurrentPage={setCurrentPage}/>);
  }
};

export default MatchCardPanel;

/* export default ({match}: { match: any}) => (
  <MatchCardPanel match={match}>
  </MatchCardPanel>
)*/
