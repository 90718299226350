import {
  IonButtons,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
  IonSpinner,
  IonImg,
  IonToggle,
  IonButton,
  IonInput,
  IonItem,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardSubtitle,
  IonIcon,
  IonCardContent,
  IonList,
  IonLabel,
  IonToast,
  IonTextarea,
  IonSelect,
  IonSelectOption,
  IonPopover,
  IonDatetime
} from '@ionic/react';

import './Videos.css';

import { useEffect, useState, useRef} from 'react';

import {
  pencilOutline,
  trashOutline,
  addCircleOutline,
  checkmarkDoneCircleOutline,
  warningOutline
} from 'ionicons/icons';

import {
  readRecordings,
  readRecordingById,
  createRecording,
  updateRecording,
  readGame,
  readGames,
  writeGame,
  readSplitById
} from '../../firebase';

import moment from 'moment-timezone';

import VideoCard from './VideoCard';


interface VideosProps {
  setCurrentPage?: any;
  videoId?: any;
}

const VideosHome: React.FC<VideosProps> = ({setCurrentPage}) => {

  const [busy, setBusy] = useState(true);
  const [recordings, setRecordings] = useState<any[]>([]);

  useEffect(() => {
    /** Using isMounted to prevent React state updates on unmounted components based on this: https://stackoverflow.com/questions/53949393/cant-perform-a-react-state-update-on-an-unmounted-component **/
    let isMounted = true;

    document.title = "Admin | Videos";

    readRecordings((r: any[]) => {

      var tempRecordings:any[] = [];

      for (var recording in r){
        tempRecordings.push(r[recording]);
      }
      tempRecordings.sort((a,b)=>{
        if (!a.releasedOn){
          return 1;
        }
        if (!b.releasedOn){
          return 1;
        }
        if (moment(parseInt(a.releasedOn)).isAfter(moment(parseInt(b.releasedOn)))){
          return -1;
        }
        else {
          return 1;
        }
      });

      if (isMounted) setRecordings(tempRecordings);
      if (isMounted) setBusy(false);
    });
    return () => { isMounted = false };
  }, []);

  return (
    <>
      {
        (
          busy ?
          <IonSpinner />
          :
          <div className="container games-container">
            <IonButton expand="block" fill="outline" onClick={()=>{
              window.open("/Videos/New", "_self");
            }}><IonIcon slot="start" icon={addCircleOutline}/> New Recording </IonButton>
            <div className="game-cards-container">
              {
                (recordings.length ?

                  recordings.map((video, index) => {
                      return (<VideoCard video={video} key={index}/>);
                    })
                :
                  <div>
                    <h1>No videos found.</h1>
                    <p>Please try again</p>
                  </div>
                )
              }
            </div>
          </div>

        )
      }
    </>
  );
};

const VideosIndividualVideo: React.FC<VideosProps> = ({setCurrentPage, videoId}) => {

  const [busy, setBusy] = useState(true);
  const [video, setVideo] = useState<any>({});
  const [isAvailable, setIsAvailable] = useState<boolean>(false);
  const [isFree, setIsFree] = useState<boolean>(false);
  const [isHidden, setIsHidden] = useState<boolean>(false);
  const [games, setGames] = useState<any[]>([]);
  const [game, setGame] = useState<any>({});
  const [gameTypes, setGameTypes] = useState<any[]>([]);
  const [tags, setTags] = useState<any[]>([]);
  const [newTag, setNewTag] = useState<any>('');




  const [activeSplitId, setActiveSplitId] = useState<any>('');
  const [gameRounds, setGameRounds] = useState<any[]>([]);
  const [splits, setSplits] = useState<any[]>([]);

  const [newGameType, setNewGameType] = useState<any>('');
  const [newGameRound, setNewGameRound] = useState<any>('');
  const [newSplitId, setNewSplitId] = useState<any>('');

  const [showToast_save, setShowToast_save] = useState<boolean>(false);
  const [proposeTimePopoverIsOpen, setProposeTimePopoverIsOpen] = useState<boolean>(false);
  const datetime = useRef<null | HTMLIonDatetimeElement>(null);



  useEffect(() => {
    /** Using isMounted to prevent React state updates on unmounted components based on this: https://stackoverflow.com/questions/53949393/cant-perform-a-react-state-update-on-an-unmounted-component **/
    let isMounted = true;

    readRecordingById(videoId, (v: any) => {
      document.title = "Admin | "+v.title;

      if (isMounted) setVideo(v);
      if (isMounted) setIsAvailable(v.isAvailable);

      readGame(v.gameName, (g:any)=>{
        if (isMounted) setGame(g);

        if (isMounted) setBusy(false);
      });
    });
    return () => { isMounted = false };
  }, []);

  useEffect(()=> {
    let isMounted = true;

    var tempTags:any[] = [];

    for (var id in video.tags){
      if (video.tags[id]){
        tempTags.push(id);
      }
    }

    if (isMounted) setTags(tempTags);

    readGames((g:any)=>{
      var tempGames:any[] = [];

      for (var gameName in g){
        tempGames.push(g[gameName]);
      }

      if (isMounted) setGames(tempGames);
    });

    var tempGameTypes:any[] = [];

    for (var gameType in game.gameTypes){
      if (game.gameTypes[gameType]){
        tempGameTypes.push(gameType);
      }
    }

    if (isMounted) setGameTypes(tempGameTypes);

    return () => { isMounted = false };
  }, [game, video]);

  return (
    <>
    {
      (
        busy ?
        <IonSpinner />
        :
        <div className="container games-container">
          <div className="game-details">
            <div className="game-details-logo">
              <IonImg src={video.thumbnailImageURL} />
            </div>
            <div className="game-details-info">
              <div className="game-details-info-top">
              <div className="split-details-name-group">
                <p className="split-details-name-label">Title</p>
                <IonInput value={video.title}  className="split-details-name-input" onIonChange={(e)=>{
                  var tempVideo = JSON.parse(JSON.stringify(video));
                  tempVideo.title = e.detail.value;

                  setVideo(tempVideo);

                }}></IonInput>
              </div>
                <IonButton onClick={()=> {
                  updateRecording(video, ()=>{
                    setShowToast_save(true);
                  });
                }}> Save </IonButton>
              </div>
              <div className="video-details-toggles-container">
                <div className="game-details-info-active">
                  <IonToggle className="video-details-toggle" checked={isAvailable} onIonChange={(e)=>{

                    var tempIsAvailable = !isAvailable;
                    var tempVideo = JSON.parse(JSON.stringify(video));

                    setIsAvailable(tempIsAvailable);
                    tempVideo.isAvailable = tempIsAvailable;

                    setVideo(tempVideo);

                  }}></IonToggle>
                  <p>{(isAvailable ? "Featured" : "Not Featured")}</p>
                </div>
                <div className="game-details-info-active">
                  <IonToggle className="video-details-toggle" checked={isFree} onIonChange={(e)=>{

                    var tempIsFree = !isFree;
                    var tempVideo = JSON.parse(JSON.stringify(video));

                    setIsFree(tempIsFree);
                    tempVideo.isFree = tempIsFree;

                    setVideo(tempVideo);

                  }}></IonToggle>
                  <p>{(isFree ? "Free for All" : "Subs Only")}</p>
                </div>
                <div className="game-details-info-active">
                  <IonToggle className="video-details-toggle" checked={isHidden} onIonChange={(e)=>{

                    var tempIsHidden = !isHidden;
                    var tempVideo = JSON.parse(JSON.stringify(video));

                    setIsHidden(tempIsHidden);
                    tempVideo.isHidden = tempIsHidden;

                    setVideo(tempVideo);

                  }}></IonToggle>
                  <p>{(isHidden ? "Hidden" : "Visible")}</p>
                </div>
              </div>
            </div>
            <div className="game-details-logo-icon-options">
              <div className="video-details-logo-url">
                <p className="game-details-logo-url-label">Thumbnail Image URL</p>
                <IonInput className="video-details-logo-url-input" value={video.thumbnailImageURL} onIonChange={(e)=>{

                  var tempVideo = JSON.parse(JSON.stringify(video));
                  tempVideo.thumbnailImageURL = e.detail.value;
                  setVideo(tempVideo);

                }}></IonInput>
              </div>
              <div className="video-details-logo-url">
                <p className="game-details-logo-url-label">Video URL</p>
                <IonInput className="video-details-logo-url-input" value={video.videoURL} onIonChange={(e)=>{

                  var tempVideo = JSON.parse(JSON.stringify(video));
                  tempVideo.videoURL = e.detail.value;
                  setVideo(tempVideo);

                }}></IonInput>
              </div>
            </div>
            <div className="split-details-options">
              <div className="video-details-option-container">
                <p className="video-details-label">Game</p>
                <IonSelect className="split-details-input" value={video.gameName} onIonChange={(e)=>{

                  var tempVideo = JSON.parse(JSON.stringify(video));
                  tempVideo.gameName = e.detail.value;

                  setVideo(tempVideo);

                  for(var i=0; i<games.length; i++){
                    if (games[i].name == e.detail.value){
                      setGame(games[i]);
                    }
                  }
                }}>

                {
                  games.map((g, index)=>{
                    if (g) {
                      return (
                        <IonSelectOption key={index+"game"} value={g.name}> {g.name}</IonSelectOption>
                      );
                    }
                  })
                }

                </IonSelect>
              </div>
              <div className="video-details-option-container">
                <p className="video-details-label">Game Type</p>
                <IonSelect className="split-details-input" value={video.gameType} onIonChange={(e)=>{
                  var tempVideo = JSON.parse(JSON.stringify(video));
                  tempVideo.gameType = e.detail.value;

                  setVideo(tempVideo);
                }}>

                {
                  gameTypes.map((t, index)=>{
                      return (
                        <IonSelectOption key={index+"video_game_type"} value={t}>{t}</IonSelectOption>
                      );

                  })
                }

                </IonSelect>
              </div>
            </div>
            <div className="split-details-options">
              <div className="video-details-option-container">
                <p className="video-details-label">Description</p>
                <IonTextarea className="video-details-input-textarea" value={video.description} onIonChange={(e)=>{
                  var tempVideo = JSON.parse(JSON.stringify(video));
                  tempVideo.description = e.detail.value;

                  setVideo(tempVideo);
                }}>
                </IonTextarea>
              </div>
              <div className="video-details-option-container">
                <p className="video-details-label">Video ID</p>
                <IonInput className="split-details-input" value={video.id} disabled={true}></IonInput>
              </div>
            </div>
            <div className="split-details-options">
              <div className="video-details-option-container">
                <p className="video-details-label">Released On</p>
                <IonInput className="split-details-input" value={video.releasedOn} onClick={()=>{
                  setProposeTimePopoverIsOpen(true);
                }}>
                </IonInput>
              </div>
              <div className="video-details-option-container">
                <p className="video-details-label">Split ID</p>
                <IonInput className="split-details-input" value={video.splitId} onIonChange={(e)=>{
                  var tempVideo = JSON.parse(JSON.stringify(video));
                  tempVideo.splitId = e.detail.value;

                  setVideo(tempVideo);
                }}>
                </IonInput>
              </div>
            </div>
            <div className="split-details-options">
              <div className="video-details-option-container">
                <p className="video-details-label">Subtitle</p>
                <IonInput className="split-details-input" value={video.subtitle} onIonChange={(e)=>{
                  var tempVideo = JSON.parse(JSON.stringify(video));
                  tempVideo.subtitle = e.detail.value;

                  setVideo(tempVideo);
                }}>
                </IonInput>
              </div>
            </div>
          </div>
          <div className="game-options">
            <IonCard className="video-options-card">
              <IonCardHeader>
                <IonCardTitle>Tags</IonCardTitle>
              </IonCardHeader>
              <IonCardContent>
                <IonList>
                  {
                    (tags.length ?

                      tags.map((tag, index) => {
                          return (
                            <IonItem key={index+"tag"}>
                              <IonLabel>{tag}</IonLabel>
                              <IonButton slot="end" className="game-options-splits-delete-button" fill="clear" onClick={()=>{
                                var tempVideo = JSON.parse(JSON.stringify(video));
                                tempVideo.tags[tag] = false;
                                setVideo(tempVideo);
                              }}>
                                <IonIcon icon={trashOutline}/>
                              </IonButton>
                            </IonItem>
                          );
                        })
                    :
                      <div>
                        <h1>No tags found.</h1>
                        <p>Add one:</p>
                      </div>
                    )
                  }

                  <IonItem lines="none">
                    <IonButton slot="start" fill="clear" onClick={()=>{
                      if (newTag) {
                        var tempVideo = JSON.parse(JSON.stringify(video));
                        tempVideo.tags[newTag] = true;
                        setVideo(tempVideo);
                        setNewTag('');
                      }
                    }}>
                      <IonIcon icon={addCircleOutline} className="game-options-splits-add-button"/>
                    </IonButton>
                    <IonInput placeholder="New Tag" value={newTag} onIonChange={(e)=>{
                      setNewTag(e.detail.value);
                    }}> </IonInput>
                  </IonItem>

                </IonList>
              </IonCardContent>
            </IonCard>
          </div>
        </div>
      )
    }
    <IonToast
      isOpen={showToast_save}
      onDidDismiss={() => setShowToast_save(false)}
      message="Recording Saved"
      duration={2000}
      color="primary"
      icon={checkmarkDoneCircleOutline}
      cssClass="success-toast"
    />
    <IonPopover className="match-card-footer-propose-time-picker" isOpen={proposeTimePopoverIsOpen} onDidDismiss={ () => {setProposeTimePopoverIsOpen(false);}} >
      <IonDatetime
        showDefaultButtons={true}
        ref={datetime}
        value={(video.releasedOn ? video.releasedOn : moment().format())}
        onIonChange={(e) => {
          var tempVideo = JSON.parse(JSON.stringify(video));
          tempVideo.releasedOn = moment(e.detail.value).format();
          setVideo(tempVideo);
        }}
      >
      </IonDatetime>
    </IonPopover>
    </>
  );
};

const VideosNewVideo: React.FC<VideosProps> = ({setCurrentPage}) => {

  const [busy, setBusy] = useState(true);
  const [video, setVideo] = useState<any>({});
  const [isAvailable, setIsAvailable] = useState<boolean>(false);
  const [isFree, setIsFree] = useState<boolean>(false);
  const [isHidden, setIsHidden] = useState<boolean>(false);
  const [games, setGames] = useState<any[]>([]);
  const [game, setGame] = useState<any>({});
  const [gameTypes, setGameTypes] = useState<any[]>([]);
  const [tags, setTags] = useState<any[]>([]);
  const [newTag, setNewTag] = useState<any>('');

  const [showToast_save, setShowToast_save] = useState<boolean>(false);
  const [showToast_invalidSave, setShowToast_invalidSave] = useState<boolean>(false);
  const [proposeTimePopoverIsOpen, setProposeTimePopoverIsOpen] = useState<boolean>(false);
  const datetime = useRef<null | HTMLIonDatetimeElement>(null);


  useEffect(() => {
    /** Using isMounted to prevent React state updates on unmounted components based on this: https://stackoverflow.com/questions/53949393/cant-perform-a-react-state-update-on-an-unmounted-component **/
    let isMounted = true;

    document.title = "Admin | New Video";

    if (isMounted) setVideo({
      tags: {

      },
      description: '',
      gameName: '',
      gameType: '',
      thumbnailImageURL: '',
      id: '',
      isAvailable: '',
      isVisible: '',
      isFree: '',
      releasedOn: '',
      splitId: '',
      subtitle: '',
      title: '',
      videoURL: ''
    });

    if (isMounted) setBusy(false);

    return () => { isMounted = false };
  }, []);

  useEffect(()=> {
    let isMounted = true;

    var tempTags:any[] = [];

    for (var id in video.tags){
      if (video.tags[id]){
        tempTags.push(id);
      }
    }

    if (isMounted) setTags(tempTags);

    readGames((g:any)=>{
      var tempGames:any[] = [];

      for (var gameName in g){
        tempGames.push(g[gameName]);
      }

      if (isMounted) setGames(tempGames);
    });

    var tempGameTypes:any[] = [];

    for (var gameType in game.gameTypes){
      if (game.gameTypes[gameType]){
        tempGameTypes.push(gameType);
      }
    }

    if (isMounted) setGameTypes(tempGameTypes);

    return () => { isMounted = false };
  }, [game, video]);

  return (
    <>
    {
      (
        busy ?
        <IonSpinner />
        :
        <div className="container games-container">
          <div className="game-details">
            <div className="game-details-logo">
              {(video.thumbnailImageURL ? <IonImg src={video.thumbnailImageURL} /> : <IonImg src="https://esporter.win/wp-content/uploads/2023/01/placeholder.png" />)}
            </div>
            <div className="game-details-info">
              <div className="game-details-info-top">
                <div className="game-details-name">
                  <p className="game-details-name-label">Title</p>
                  <IonInput className="game-details-name-input" value={video.title} onIonChange={(e)=>{

                    var tempVideo = JSON.parse(JSON.stringify(video));
                    tempVideo.title = e.detail.value;
                    setVideo(tempVideo);

                  }}></IonInput>
                </div>
                <IonButton onClick={()=> {
                  if (video.title){
                    if (video.title.match(/^[\sA-Za-z0-9_-]*$/g)){
                      createRecording(video, ()=>{
                        setShowToast_save(true);
                      });
                    }
                    else {
                      setShowToast_invalidSave(true);
                    }
                  }
                  else {
                    setShowToast_invalidSave(true);
                  }

                }}> Save </IonButton>
              </div>
              <div className="video-details-toggles-container">

                <div className="game-details-info-active">
                  <IonToggle className="video-details-toggle" checked={isAvailable} onIonChange={(e)=>{

                    var tempIsAvailable = !isAvailable;
                    var tempVideo = JSON.parse(JSON.stringify(video));

                    setIsAvailable(tempIsAvailable);
                    tempVideo.isAvailable = tempIsAvailable;

                    setVideo(tempVideo);

                  }}></IonToggle>
                  <p>{(isAvailable ? "Featured" : "Not Featured")}</p>
                </div>
                <div className="game-details-info-active">
                  <IonToggle className="video-details-toggle" checked={isFree} onIonChange={(e)=>{

                    var tempIsFree = !isFree;
                    var tempVideo = JSON.parse(JSON.stringify(video));

                    setIsFree(tempIsFree);
                    tempVideo.isFree = tempIsFree;

                    setVideo(tempVideo);

                  }}></IonToggle>
                  <p>{(isFree ? "Free for All" : "Subs Only")}</p>
                </div>
                <div className="game-details-info-active">
                  <IonToggle className="video-details-toggle" checked={isHidden} onIonChange={(e)=>{

                    var tempIsHidden = !isHidden;
                    var tempVideo = JSON.parse(JSON.stringify(video));

                    setIsHidden(tempIsHidden);
                    tempVideo.isHidden = tempIsHidden;

                    setVideo(tempVideo);

                  }}></IonToggle>
                  <p>{(isHidden ? "Hidden" : "Visible")}</p>
                </div>
              </div>
            </div>
            <div className="game-details-logo-icon-options">
              <div className="video-details-logo-url">
                <p className="game-details-logo-url-label">Thumbnail Image URL</p>
                <IonInput className="video-details-logo-url-input" value={video.thumbnailImageURL} onIonChange={(e)=>{

                  var tempVideo = JSON.parse(JSON.stringify(video));
                  tempVideo.thumbnailImageURL = e.detail.value;
                  setVideo(tempVideo);

                }}></IonInput>
              </div>
              <div className="video-details-logo-url">
                <p className="game-details-logo-url-label">Video URL</p>
                <IonInput className="video-details-logo-url-input" value={video.videoURL} onIonChange={(e)=>{

                  var tempVideo = JSON.parse(JSON.stringify(video));
                  tempVideo.videoURL = e.detail.value;
                  setVideo(tempVideo);

                }}></IonInput>
              </div>
            </div>
            <div className="split-details-options">
              <div className="video-details-option-container">
                <p className="video-details-label">Description</p>
                <IonTextarea className="video-details-input-textarea" value={video.description} onIonChange={(e)=>{
                  var tempVideo = JSON.parse(JSON.stringify(video));
                  tempVideo.description = e.detail.value;

                  setVideo(tempVideo);
                }}>
                </IonTextarea>
              </div>
              <div className="video-details-option-container">
                <p className="video-details-label">Subtitle</p>
                <IonInput className="split-details-input" value={video.subtitle} onIonChange={(e)=>{
                  var tempVideo = JSON.parse(JSON.stringify(video));
                  tempVideo.subtitle = e.detail.value;

                  setVideo(tempVideo);
                }}>
                </IonInput>
              </div>
            </div>
            <div className="split-details-options">
              <div className="video-details-option-container">
                <p className="video-details-label">Game</p>
                <IonSelect className="split-details-input" value={video.gameName} onIonChange={(e)=>{

                  var tempVideo = JSON.parse(JSON.stringify(video));
                  tempVideo.gameName = e.detail.value;

                  setVideo(tempVideo);

                  for(var i=0; i<games.length; i++){
                    if (games[i].name == e.detail.value){
                      setGame(games[i]);
                    }
                  }
                }}>

                {
                  games.map((g, index)=>{
                    if (g) {
                      return (
                        <IonSelectOption key={index+"game"} value={g.name}> {g.name}</IonSelectOption>
                      );
                    }
                  })
                }

                </IonSelect>
              </div>
              <div className="video-details-option-container">
                <p className="video-details-label">Game Type</p>
                <IonSelect className="split-details-input" value={video.gameType} onIonChange={(e)=>{
                  var tempVideo = JSON.parse(JSON.stringify(video));
                  tempVideo.gameType = e.detail.value;

                  setVideo(tempVideo);
                }}>

                {
                  gameTypes.map((t, index)=>{
                      return (
                        <IonSelectOption key={index+"video_game_type"} value={t}>{t}</IonSelectOption>
                      );

                  })
                }

                </IonSelect>
              </div>
            </div>
            <div className="split-details-options">
              <div className="video-details-option-container">
                <p className="video-details-label">Released On</p>
                <IonInput className="split-details-input" value={video.releasedOn} onClick={()=>{
                  setProposeTimePopoverIsOpen(true);
                }}>
                </IonInput>
              </div>
              <div className="video-details-option-container">
                <p className="video-details-label">Split ID</p>
                <IonInput className="split-details-input" value={video.splitId} onIonChange={(e)=>{
                  var tempVideo = JSON.parse(JSON.stringify(video));
                  tempVideo.splitId = e.detail.value;

                  setVideo(tempVideo);
                }}>
                </IonInput>
              </div>
            </div>
            <div className="split-details-options">
              <div className="video-details-option-container">
                <p className="video-details-label">Video ID</p>
                <IonInput className="split-details-input" value={video.id} disabled={true}></IonInput>
              </div>
            </div>
          </div>
          <div className="game-options">
            <IonCard className="video-options-card">
              <IonCardHeader>
                <IonCardTitle>Tags</IonCardTitle>
              </IonCardHeader>
              <IonCardContent>
                <IonList>
                  {
                    (tags.length ?

                      tags.map((tag, index) => {
                          return (
                            <IonItem key={index+"tag"}>
                              <IonLabel>{tag}</IonLabel>
                              <IonButton slot="end" className="game-options-splits-delete-button" fill="clear" onClick={()=>{
                                var tempVideo = JSON.parse(JSON.stringify(video));
                                tempVideo.tags[tag] = false;
                                setVideo(tempVideo);
                              }}>
                                <IonIcon icon={trashOutline}/>
                              </IonButton>
                            </IonItem>
                          );
                        })
                    :
                      <div>
                        <h1>No tags found.</h1>
                        <p>Add one:</p>
                      </div>
                    )
                  }

                  <IonItem lines="none">
                    <IonButton slot="start" fill="clear" onClick={()=>{
                      if (newTag) {
                        var tempVideo = JSON.parse(JSON.stringify(video));
                        tempVideo.tags[newTag] = true;
                        setVideo(tempVideo);
                        setNewTag('');
                      }
                    }}>
                      <IonIcon icon={addCircleOutline} className="game-options-splits-add-button"/>
                    </IonButton>
                    <IonInput placeholder="New Tag" value={newTag} onIonChange={(e)=>{
                      setNewTag(e.detail.value);
                    }}> </IonInput>
                  </IonItem>

                </IonList>
              </IonCardContent>
            </IonCard>
          </div>
        </div>
      )
    }
    <IonToast
      isOpen={showToast_save}
      onDidDismiss={() => setShowToast_save(false)}
      message="Recording Saved"
      duration={2000}
      color="primary"
      icon={checkmarkDoneCircleOutline}
      cssClass="success-toast"
    />
    <IonToast
      isOpen={showToast_invalidSave}
      onDidDismiss={() => setShowToast_invalidSave(false)}
      message="Recording Title is required and must only include spaces, numbers, and letters"
      duration={10000}
      color="danger"
      icon={warningOutline}
      cssClass="success-toast"
    />
    <IonPopover className="match-card-footer-propose-time-picker" isOpen={proposeTimePopoverIsOpen} onDidDismiss={ () => {setProposeTimePopoverIsOpen(false);}} >
      <IonDatetime
        showDefaultButtons={true}
        ref={datetime}
        value={(video.releasedOn ? video.releasedOn : moment().format())}
        onIonChange={(e) => {
          var tempVideo = JSON.parse(JSON.stringify(video));
          tempVideo.releasedOn = moment(e.detail.value).format();
          setVideo(tempVideo);
        }}
      >
      </IonDatetime>
    </IonPopover>
    </>
  );
};

const Videos: React.FC<VideosProps> = ({videoId}) => {

  const [currentPage, setCurrentPage] = useState(()=>{
    if(videoId){
      if (videoId == "New"){
        return "new";
      }
      else {
        return "video";
      }
    }
    else {
      return "home";
    }
  });


  switch (currentPage){
    case "home":
      return (<VideosHome setCurrentPage={setCurrentPage}/>);
    case "video":
      return(<VideosIndividualVideo setCurrentPage={setCurrentPage} videoId={videoId}/>);
    case "new":
      return(<VideosNewVideo setCurrentPage={setCurrentPage}/>);
    default:
      return (<VideosHome setCurrentPage={setCurrentPage}/>);
  }

  return (
    <div className="container games-container">
      <strong>Videos</strong>
      <p>Coming Soon...</p>
    </div>
  );
};

export default Videos;
