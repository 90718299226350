import React, { useEffect, useState, useRef } from "react";
import { IonSpinner } from '@ionic/react';
import { useAuthState } from "react-firebase-hooks/auth";
import { app, auth, user } from '../../firebase';

import FullCalendar, { EventInput, EventApi, DateSelectArg, EventClickArg, EventContentArg, formatDate } from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import googleCalendarPlugin from '@fullcalendar/google-calendar';

import { IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import './Events.css';



var Events: React.FC = () => {

  var openEventInNewTab:any;

  const [busy, setBusy] = useState(true);


  useEffect(()=> {

    /** Using isMounted to prevent React state updates on unmounted components based on this: https://stackoverflow.com/questions/53949393/cant-perform-a-react-state-update-on-an-unmounted-component **/
    let isMounted = true;

    document.title = "Esporter | Events";

    return () => { isMounted = false };

  }, []);

  useEffect(() => {
    /** Using isMounted to prevent React state updates on unmounted components based on this: https://stackoverflow.com/questions/53949393/cant-perform-a-react-state-update-on-an-unmounted-component **/
    let isMounted = true;

    /** Must use this hack to get the full calendar to render: https://github.com/fullcalendar/fullcalendar/issues/4976**/
    setTimeout( function() {
      window.dispatchEvent(new Event('resize'))
    }, 1);

    openEventInNewTab = (info:any) => {
      alert("Hello");
      info.jsEvent.preventDefault(); // don't let the browser navigate

      if (info.event.url) {
        window.open(info.event.url, '_blank');
      }
    }

    setBusy(false);

    return () => { isMounted = false };
  }, []);

  return (
    (
      busy ?
      <IonSpinner />
      :
      <div className="container events-container">
        <div className="events-calendar">
          <FullCalendar
            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, googleCalendarPlugin]}
            googleCalendarApiKey='AIzaSyAVITKrb3Bb_pfuUUs6secbmaEbD9U00GA'
            initialView={(window.innerWidth < 769 ? 'timeGridDay' :'dayGridMonth')}
            headerToolbar={{
              left: 'prev,next today',
              center: 'title',
              right: 'dayGridMonth'
            }}
            initialEvents={[]}
            eventSources={[
              { googleCalendarId: 'c_j19i8rrdtc69rgr3cpoom61sm0@group.calendar.google.com', className: 'gcal-event'}
            ]}
            editable={false}
            selectable={false}
            selectMirror={false}
            dayMaxEvents={false}
            eventClick={openEventInNewTab}
          />
        </div>
      </div>
    )
  );
}

export default Events;
