import {
  IonIcon,
  IonImg,
  IonDatetime,
  IonPopover,
  IonToast,
  IonText,
  IonSpinner,
  useIonAlert
 } from '@ionic/react';

 import {
   readUserProfileById,
   updateMatchWithUserSuggestedTime,
   writeUserNotification,
   sendNotificationEmail,
   readBorders,
   readBanners,
   readPictures,
   readGames,
   readUserMatchById,
   countRecordInSplit
 } from '../../firebase';

import {
  gameControllerSharp,
  logoDiscord,
  checkmarkDoneCircleOutline,
  clipboard,
  paperPlane
} from 'ionicons/icons';

import './MatchmakingCard.css';

import React, { useRef, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import moment from 'moment-timezone';

import MatchCardPanel from './MatchCardPanel';
import MatchmakingCardPanel from './MatchmakingCardPanel';

interface MatchmakingCardProps {
  gameId: string;
  gameType: string;
  gameRounds?: string;
  playerId: string;
  splitId: string;
}

const MatchmakingCard: React.FC<MatchmakingCardProps> = ({gameId, gameType, playerId, splitId, gameRounds}) => {

  const userId = useSelector((state: any) => state.userData.uid);

  const [match, setMatch] = useState<any>({});
  const [busy, setBusy] = useState(true);

  const [matchDateTime, setMatchDateTime] = useState<string>('');
  const [matchTime, setMatchTime] = useState<string>('');

  const [playerProposedMatchDateTime, setPlayerProposedMatchDateTime] = useState<string>('');
  const [playerProposedMatchDateTimeLabel, setPlayerProposedMatchDateTimeLabel] = useState<string>('');
  const [playerTimezone, setPlayerTimezone] = useState<string>('US/Pacific');

  const [opponentProposedMatchDateTime, setOpponentProposedMatchDateTime] = useState<string>('');
  const [opponentProposedMatchDateTimeLabel, setOpponentProposedMatchDateTimeLabel] = useState<string>('');
  const [opponentTimezone, setOpponentTimezone] = useState<string>('US/Pacific');

  const [games, setGames] = useState<any>([]);
  const [banners, setBanners] = useState<any>([]);
  const [pictures, setPictures] = useState<any>([]);
  const [borders, setBorders] = useState<any>([]);

  const [winsCount, setWinsCount] = useState<number>(0);
  const [lossesCount, setLossesCount] = useState<number>(0);
  const [totalCount, setTotalCount] = useState<number>(0);

  const [player1IGN, setPlayer1IGN] = useState<string>('MissingNo.');
  const [player1Username, setPlayer1Username] = useState<string>('MissingNo.');
  const [player1Discord, setPlayer1Discord] = useState<string>('MissingNo.');
  const [player1Email, setPlayer1Email] = useState<string>('');
  const [player1StatusHTML, setPlayer1StatusHTML] = useState<any>('');
  const [player1BorderID, setPlayer1BorderID] = useState<string>('');
  const [player1PictureID, setPlayer1PictureID] = useState<string>('');

  const [player2Id, setPlayer2Id] = useState<string>('MissingNo.');
  const [player2Username, setPlayer2Username] = useState<string>('MissingNo.');
  const [player2IGN, setPlayer2IGN] = useState<string>('MissingNo.');
  const [player2Discord, setPlayer2Discord] = useState<string>('MissingNo.');
  const [player2Email, setPlayer2Email] = useState<string>('');
  const [player2BorderID, setPlayer2BorderID] = useState<string>('');
  const [player2PictureID, setPlayer2PictureID] = useState<string>('');
  const [player2BannerID, setPlayer2BannerID] = useState<string>('');

  const [proposeTimePopoverIsOpen, setProposeTimePopoverIsOpen] = useState<boolean>(false);
  const [showAcceptTimeButton, setShowAcceptTimeButton] = useState<boolean>(false);
  const [showMenu, setShowMenu] = useState<boolean>(false);

  const [showToast_timeProposed, setShowToast_timeProposed] = useState(false);
  const [showToast_timeAccepted, setShowToast_timeAccepted] = useState(false);
  const [showToast_copySuccess, setShowToast_copySuccess] = useState(false);
  const [showToast_scoresConflict_acceptedOpponentScore, setShowToast_scoresConflict_acceptedOpponentScore] = useState<boolean>(false);
  const [showToast_scoresConflict_yourOpponentWasEmailed, setShowToast_scoresConflict_yourOpponentWasEmailed] = useState<boolean>(false);

  const [showToasts, setShowToasts] = useState<any>(
    {
      accept_match: false
    }
  );

  const [matchMoment, setMatchMoment] = useState<any>(moment());

  const matchMenuWrapperRef = useRef<any>(null);

  const[menu, setMenu] = useState<any>();

  var match_moment = moment(match.actual_time).tz(match.timezone);

  var time_format = '';

  var date_format = "MMM Do";
  var time_format = "h:mma";

  var proposed_time_date_time_format = "MMM Do h:mma";

  const [present] = useIonAlert();

  useEffect(() => {
    let isMounted = true;

    readBorders((borders: any)=>{
      var tempBorders = [];

      //For all of the borders in the database
      for (var id in borders){
        tempBorders.push(borders[id]);
      }

      if (isMounted) setBorders(tempBorders);
    });

    readBanners((banners: any)=>{
      var tempBanners = [];

      //For all of the borders in the database
      for (var id in banners){
        tempBanners.push(banners[id]);
      }
      if (isMounted) setBanners(tempBanners);
    });

    readPictures((pictures: any)=>{
      var tempPictures = [];

      //For all of the borders in the database
      for (var id in pictures){
        tempPictures.push(pictures[id]);
      }

      if (isMounted) setPictures(tempPictures);
    });

    readGames((games:any )=>{
      var tempGames = [];

      //For all of the borders in the database
      for (var id in games){
        tempGames.push(games[id]);
      }

      if (isMounted) setGames(tempGames);
    });

    countRecordInSplit(playerId, splitId, (record:any)=>{
      if (isMounted) setWinsCount(record.wins);
      if (isMounted) setLossesCount(record.losses);
      if (isMounted) setTotalCount(record.total);
    });

    return () => { isMounted = false };
  }, []);

  useEffect(() => {
    document.addEventListener("click", handleMatchMenuClickOutside, false);
    return () => {
      document.removeEventListener("click", handleMatchMenuClickOutside, false);
    };
  }, [showMenu]);

  const handleMatchMenuClickOutside = (event: any) => {
    if (showMenu){
      if (matchMenuWrapperRef.current && !matchMenuWrapperRef.current.contains(event.target)) {
        setShowMenu(false);
      }
    }
  };

  useEffect(()=>{
    let isMounted = true;

    var opponentUsername: string = 'MissingNo.';
    var opponentEmail: string = 'MissingNo.';

    //Get Player 2's info
    readUserProfileById(playerId, (user: any) => {

      if (user == null) {
        return;
      }

      if (user.email){
        if (isMounted) setPlayer2Email(user.email);
      }

      var gameId_stripped = gameId.replace(/\s/g, "");

      if (user.igns){
        if (user.igns[gameId_stripped]){
          if (isMounted) setPlayer2IGN(user.igns[gameId_stripped]);
        }
      }
      else {
        if (isMounted) setPlayer2IGN("MissingNo.");
      }

      if (user.discordId){
        if (isMounted) setPlayer2Discord(user.discordId);
      }
      else {
        if (isMounted) setPlayer2Discord("MissingNo.");
      }

      if (user.public && user.public.cosmetics){
        if (user.public.cosmetics.picture){
          if (isMounted) setPlayer2PictureID(user.public.cosmetics.picture);
        }

        if (user.public.cosmetics.border){
          if (isMounted) setPlayer2BorderID(user.public.cosmetics.border);
        }

        if (user.public.cosmetics.banner){
          if (isMounted) setPlayer2BannerID(user.public.cosmetics.banner);
        }
      }

      if (user.availability){
        if (user.availability.timezone){
          if (isMounted) setOpponentTimezone(user.availability.timezone);
        }
        else {
          if (isMounted) setOpponentTimezone("America/Los_Angeles");
        }
      }

      if (!user.displayName){
        user.displayName = "MissingNo.";
      }
      else {
        if (isMounted) setPlayer2Username(user.displayName);
      }

      if (isMounted) setBusy(false);

    });

    return () => { isMounted = false };
  }, []);

  const datetime = useRef<null | HTMLIonDatetimeElement>(null);

  return (
    <>
    {
    busy ? <IonSpinner /> :

    <div className="matches-container">
      <div className='match-card' style={(player2BannerID
        ?
        (
          banners.filter((banner : any) => banner.id === player2BannerID)[0]
          ?
            {backgroundImage: `url(${banners.filter((banner: any) => banner.id === player2BannerID)[0].imageURL})`
          }
          :
          {
            backgroundImage: "url(https://esporter.win/wp-content/uploads/2023/01/Banners-1-Topography.png)"
          }
        ) :
        {
          backgroundImage: "url(https://esporter.win/wp-content/uploads/2023/01/Banners-1-Topography.png)"
        }
      )}>
        <div className="match-card-background-image-overlay">
        </div>
        <div className="match-card-bumper match-card-left-bumper">
        </div>
        <div className="matchmaking-card-challenge">
          <div className="match-card-center-details-item match-card-center-details-game">
            <IonText className="match-card-center-details-game-text">
              {(
                  games.filter((game:any) => game.name === gameId)[0]
                ?
                  <IonImg src={games.filter((game:any) => game.name === gameId)[0].logoURL} className="match-card-center-details-game-logo"/>
                :
                  <p>{gameId}</p>
              )}
            </IonText>
          </div>
          <div className="match-card-center-details-item match-card-center-details-type">
            <IonText className="match-card-center-details-type-text">
              <p>{gameType}</p>
            </IonText>
          </div>
          <MatchmakingCardPanel playerId={playerId} splitId={splitId} gameName={gameId} gameType={gameType} gameRounds={gameRounds}/>
        </div>
        <div className="match-card-record">
          <div className="match-card-record-score match-card-record-total">
            <div className="match-card-record-score-title">
              <h1> T </h1>
            </div>
            <div className="match-card-record-score-number">
              <h1> {totalCount} </h1>
            </div>
          </div>
          <div className="match-card-record-score match-card-record-wins">
            <div className="match-card-record-score-title">
              <h1> W </h1>
            </div>
            <div className="match-card-record-score-number">
              <h1> {winsCount} </h1>
            </div>
          </div>
          <div className="match-card-record-score match-card-record-losses">
            <div className="match-card-record-score-title">
              <h1> L </h1>
            </div>
            <div className="match-card-record-score-number">
              <h1> {lossesCount} </h1>
            </div>
          </div>
        </div>
        <div className="match-card-player match-card-right-player">
          <div className="match-card-player-profile match-card-player-profile-left">
            <IonImg src={(player2PictureID ? (pictures.filter((picture:any) => picture.id === player2PictureID)[0] ? pictures.filter((picture:any) => picture.id === player2PictureID)[0].imageURL : "https://esporter.win/wp-content/uploads/2022/03/AdobeStock_23725944-scaled.jpeg") : "https://esporter.win/wp-content/uploads/2022/03/AdobeStock_23725944-scaled.jpeg")} className="match-card-player-profile-image" />
            <IonImg src={(player2BorderID ? (borders.filter((border:any) => border.id === player2BorderID)[0] ? borders.filter((border:any) => border.id === player2BorderID)[0].imageURL : "https://esporter.win/wp-content/uploads/2022/07/esporter_profile_border_10.png") : "https://esporter.win/wp-content/uploads/2022/07/esporter_profile_border_10.png")} className="match-card-player-profile-border" />
          </div>
          <div className="match-card-player-details match-card-player-details-left">
            <div className="match-card-player-details-bumper">
            </div>
            <div className="match-card-player-details-item match-card-player-details-username">
              <IonText className="match-card-player-details-username-text match-card-player-details-text-left">
                <p>{player2Username}</p>
              </IonText>
            </div>
            <div className="match-card-player-details-item match-card-player-details-discord match-card-player-details-badge" title="Discord ID">
              <div className="match-card-player-details-badge match-card-player-details-badge-left" onClick={() => {
                navigator.clipboard.writeText(player2Discord);
                setShowToast_copySuccess(true);
              }}>
                <div className="match-card-player-details-badge-icon match-card-player-details-badge-icon-left">
                  <IonIcon icon={logoDiscord} slot="start" />
                </div>
                <div className="match-card-player-details-badge-text">
                  <IonText className="match-card-player-details-discord-text match-card-player-details-text-left">
                    <p>{player2Discord}</p>
                  </IonText>
                </div>
              </div>
            </div>
            <div className="match-card-player-details-item match-card-player-details-ign match-card-player-details-badge" title={(gameId + " ID")}>
              <div className="match-card-player-details-badge match-card-player-details-badge-left"  onClick={() => {
                navigator.clipboard.writeText(player2IGN);
                setShowToast_copySuccess(true);
              }}>
                <div className="match-card-player-details-badge-icon match-card-player-details-badge-icon-left">
                  <IonIcon icon={gameControllerSharp} slot="start" />
                </div>
                <div className="match-card-player-details-badge-text">
                  <IonText className="match-card-player-details-ign-text match-card-player-details-text-left">
                    <p>{player2IGN}</p>
                  </IonText>
                </div>
              </div>
            </div>
            <div className="match-card-player-details-bumper">
            </div>
          </div>
        </div>
      </div>

      <IonPopover className="match-card-footer-propose-time-picker" isOpen={proposeTimePopoverIsOpen} onDidDismiss={ () => {setProposeTimePopoverIsOpen(false);}} >
        <IonDatetime
          showDefaultButtons={true}
          ref={datetime}
          min={moment().format()}
          onIonChange={(e) => {
            var userSuggestedTime: string = moment(e.detail.value).tz(playerTimezone).format();
            //console.log("Player suggested time: "+userSuggestedTime+" in timezone: "+playerTimezone);
            updateMatchWithUserSuggestedTime(userId, match, userSuggestedTime);
            setPlayerProposedMatchDateTimeLabel("You Proposed Time: " + moment(e.detail.value).tz(playerTimezone).format(proposed_time_date_time_format));
            setShowToast_timeProposed(true);
            sendNotificationEmail(player2Email, "propose-match-time");

            var notification_description: string = "Your opponent wants to reschedule your match to " + moment(e.detail.value).tz(opponentTimezone).format(proposed_time_date_time_format) + ". Click here to manage your matches.";

            var notification: any = {
              content: {
                description: notification_description,
                title: "Reschedule Your Match"
              },
              url: "https://app.esporter.win/Matches",
              type: "proposed-time"
            }

            for (var playerId in match.players){
              if (playerId != userId){
                writeUserNotification(playerId, notification.type, notification.content.description, notification.type, notification.url, notification.content.title)
              }
            }

          }}
        >
        </IonDatetime>
      </IonPopover>
      <IonToast
        isOpen={showToast_timeProposed}
        onDidDismiss={() => setShowToast_timeProposed(false)}
        message="New Time Proposed"
        duration={2000}
        color="primary"
        icon={checkmarkDoneCircleOutline}
        cssClass="time-proposed-toast"
      />

      <IonToast
        isOpen={showToast_timeAccepted}
        onDidDismiss={() => setShowToast_timeAccepted(false)}
        message="New Time Accepted"
        duration={2000}
        color="primary"
        icon={checkmarkDoneCircleOutline}
        cssClass="time-accepted-toast"
      />

      <IonToast
        isOpen={showToast_copySuccess}
        onDidDismiss={() => setShowToast_copySuccess(false)}
        message="Copied"
        duration={2000}
        color="medium"
        icon={clipboard}
        cssClass="copy-toast"
      />
      <IonToast
        isOpen={showToast_scoresConflict_acceptedOpponentScore}
        onDidDismiss={() => setShowToast_scoresConflict_acceptedOpponentScore(false)}
        message="You have accepted your opponent's score."
        duration={2000}
        color="primary"
        icon={checkmarkDoneCircleOutline}
        cssClass="accepted-opponent-score-toast"
      />
      <IonToast
        isOpen={showToast_scoresConflict_yourOpponentWasEmailed}
        onDidDismiss={() => setShowToast_scoresConflict_yourOpponentWasEmailed(false)}
        message="We emailed your opponent and asked them to confirm this score."
        duration={4000}
        color="primary"
        icon={paperPlane}
        cssClass="emailed-opponent-score-toast"
      />

      <IonToast
        isOpen={showToasts.accept_match}
        onDidDismiss={() => setShowToasts({...showToasts, accept_match:false})}
        message="Match Accepted"
        duration={2000}
        color="primary"
        icon={checkmarkDoneCircleOutline}
        cssClass="accept-match-toast"
      />


    </div>
    }
    </>
  );
}

export default MatchmakingCard;
