import {
  IonApp,
  IonRouterOutlet,
  IonSplitPane,
  IonSpinner,
  setupIonicReact,
  useIonViewDidEnter,
 } from '@ionic/react';

 import moment from 'moment-timezone';

import { IonReactRouter } from '@ionic/react-router';
import { Redirect, Route } from 'react-router-dom';

import Menu from './components/Menu';
import Page from './pages/Page';
import Login from './pages/Login';
import Signup from './pages/Signup';
import Reset from './pages/Reset';


/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';

import { app, auth, user, getCurrentUser, readUserProfileById, writeFreeTrial, writeStudentSub, endFreeTrial, writeInitialCosmetics } from './firebase';
import { onAuthStateChanged } from 'firebase/auth';
import { useEffect, useState } from 'react';
import { setUserState } from './redux/actions';
import { useDispatch, useSelector } from 'react-redux';
import {
  setUserMetadata
} from './firebase';


setupIonicReact();

const RoutingSystemLoggedIn: React.FC = () => {
  return (
      <IonReactRouter>
        <IonSplitPane contentId="main">
          <Menu />
          <IonRouterOutlet id="main">
            <Route path="/" exact={true}>
              <Redirect to="/Profile"/>
            </Route>
            <Route path="/:name" exact={true}>
              <Page />
            </Route>
            <Route path="/:name/:id" exact={true}>
              <Page />
            </Route>
            <Route path="/Events" exact={true}>
              <Redirect to="/Profile"/>
            </Route>
            <Route path="/Recordings" exact={true}>
              <Redirect to="/Profile"/>
            </Route>
            <Route path="/Users" exact={true}>
              <Redirect to="/Profile"/>
            </Route>
            <Route path="/Splits" exact={true}>
              <Redirect to="/Profile"/>
            </Route>
            <Route path="/Games" exact={true}>
              <Redirect to="/Profile"/>
            </Route>
            <Route path="/Games/New" exact={true}>
              <Redirect to="/Profile"/>
            </Route>
            <Route path="/Videos" exact={true}>
              <Redirect to="/Profile"/>
            </Route>
            <Route path="/Videos/New" exact={true}>
              <Redirect to="/Profile"/>
            </Route>
            {
              /*
              <Route path="/Leaderboards" exact={true}>
                <Redirect to="/Profile"/>
              </Route>
              */
            }
            <Route path="/Login" exact={true}>
              <Redirect to="/Profile"/>
            </Route>
            <Route path="/Signup" exact={true}>
              <Redirect to="/Profile"/>
            </Route>
            <Route path="/Reset" exact={true}>
              <Redirect to="/Profile"/>
            </Route>
          </IonRouterOutlet>
        </IonSplitPane>
      </IonReactRouter>
  );
};

const RoutingSystemLoggedOut: React.FC = () => {
  return (
      <IonReactRouter>
        <IonSplitPane contentId="main">
          <IonRouterOutlet id="main">
            <Route>
              <Redirect to="/Login"/>
            </Route>
            <Route path="/Login" exact={true}>
              <Login />
            </Route>
            {/*
            <Route path="/Signup" exact={true}>
              <Login />
            </Route>
            */}
            <Route path="/Reset" exact={true}>
              <Reset />
            </Route>
          </IonRouterOutlet>
        </IonSplitPane>
      </IonReactRouter>
  );
};

const RoutingSystemSubscribed: React.FC = () => {
  return (
      <IonReactRouter>
        <IonSplitPane contentId="main">
          <Menu />
          <IonRouterOutlet id="main">
            <Route path="/" exact={true}>
              <Redirect to="/Profile"/>
            </Route>
            <Route path="/:name" exact={true}>
              <Page />
            </Route>
            <Route path="/:name/:id" exact={true}>
              <Page />
            </Route>
            <Route path="/Login" exact={true}>
              <Redirect to="/Profile"/>
            </Route>
            <Route path="/Users">
              <Redirect to="/Profile"/>
            </Route>
            <Route path="/Splits">
              <Redirect to="/Profile"/>
            </Route>
            <Route path="/Splits/New">
              <Redirect to="/Profile"/>
            </Route>
            <Route path="/Games">
              <Redirect to="/Profile"/>
            </Route>
            <Route path="/Games/New">
              <Redirect to="/Profile"/>
            </Route>
            <Route path="/Videos" exact={true}>
              <Redirect to="/Profile"/>
            </Route>
            <Route path="/Videos/New" exact={true}>
              <Redirect to="/Profile"/>
            </Route>
            {
              /*
              <Route path="/Leaderboards">
                <Redirect to="/Profile"/>
              </Route>
              */
            }
            <Route path="/Signup" exact={true}>
              <Redirect to="/Profile"/>
            </Route>
            <Route path="/Reset" exact={true}>
              <Redirect to="/Profile"/>
            </Route>
          </IonRouterOutlet>
        </IonSplitPane>
      </IonReactRouter>
  );
};

const RoutingSystemAdmin: React.FC = () => {
  return (
      <IonReactRouter>
        <IonSplitPane contentId="main">
          <Menu />
          <IonRouterOutlet id="main">
            <Route path="/" exact={true}>
              <Redirect to="/Profile"/>
            </Route>
            <Route path="/:name" exact={true}>
              <Page />
            </Route>
            <Route path="/:name/:id" exact={true}>
              <Page />
            </Route>
            <Route path="/Login" exact={true}>
              <Redirect to="/Profile"/>
            </Route>
            <Route path="/Signup" exact={true}>
              <Redirect to="/Profile"/>
            </Route>
            <Route path="/Reset" exact={true}>
              <Redirect to="/Profile"/>
            </Route>
          </IonRouterOutlet>
        </IonSplitPane>
      </IonReactRouter>
  );
};

const App: React.FC = () => {

  const [ loggedIn, setLoggedIn] = useState(false);
  const [ isSubscriber, setIsSubscriber] = useState(false);
  const [ busy, setBusy] = useState(true);
  const [routerContent, setRouterContent] = useState<any>(<IonSpinner />);
  const [menuContent, setMenuContent] = useState<any>();
  const dispatch = useDispatch();
  const [userId, setUserId] = useState('');

  useEffect(()=>{
    let isMounted = true;

    const fetchUser = async () => {
      await getCurrentUser().then( async (user: any) => {
        if (user){
          if (isMounted) setUserMetadata(user);
          if (isMounted) dispatch(setUserState(user));
          if (isMounted) setLoggedIn(true);
          //if (isMounted) setRouterContent(<RoutingSystemLoggedIn />);
          if (isMounted) setMenuContent("logged-in");
          if (isMounted) setBusy(false);
          if (isMounted) setUserId(user.uid);
        }
        else {
          if (isMounted) setLoggedIn(false);
          if (isMounted) setBusy(false);
          if (isMounted) setRouterContent(<RoutingSystemLoggedOut />);
          if (isMounted) setMenuContent("logged-out");
        }
      }).catch(e => {
        console.log(e);
        if (isMounted) setLoggedIn(false);
        if (isMounted) setRouterContent(<RoutingSystemLoggedOut />);
        if (isMounted) setMenuContent("logged-out");
        if (isMounted) setBusy(false);
      });
    };

    fetchUser();

    return () => {
      isMounted = false;
    };
  }, []);

  useEffect(()=> {
    let isMounted = true;
    if (userId){
      readUserProfileById(userId, (profile: any) => {


        if (profile){
          if (!profile.subscription_status){

            // ADD UNI EMAILS HERE
            if (profile.school_code || profile.email.includes("@uni.edu")){
              writeStudentSub(userId);
              profile.subscription_status = "active";
            }
            else {
              writeFreeTrial(userId);
              profile.subscription_status = "trial";
              profile.free_trial_started = moment().tz('US/Pacific').format();
            }
          }

          if (!profile.restricted){
            writeInitialCosmetics(userId);
          }
          else if (!profile.restricted.cosmetics){
            writeInitialCosmetics(userId);
          }

          if (profile.subscription_status == "trial" && moment().isAfter(moment(profile.free_trial_started).add(1, 'M'))){
            endFreeTrial(userId);
            profile.subscription_status = "cancelled";
          }


          switch(profile.role){
            case "admin":
              if (isMounted) setRouterContent(<RoutingSystemAdmin />);
              break;
            default:
              switch(profile.subscription_status){
                case "active":
                case "pending-cancel":
                case "trial":
                  if (isMounted) setIsSubscriber(true);
                  if (isMounted) setRouterContent(<RoutingSystemSubscribed />);
                  if (isMounted) setMenuContent("subscribed");
                  break;
                case "pending":
                case "on-hold":
                case "expired":
                case "cancelled":
                default:
                  if (isMounted) setRouterContent(<RoutingSystemLoggedIn />);
                  if (isMounted) setMenuContent("logged-in");
                  break;
              }
              break;
          }
        }
      });

      return () => {
        isMounted = false;
      };
    }

  }, [userId, isSubscriber, loggedIn]);

  return (
    <IonApp>

      { routerContent

        /** For function syntax see the top two comments here: https://dev.to/samba_code/nested-ternary-statements-in-react-jsx-35kp **/
        /*(() => {
        if (busy){
          return(<IonSpinner />);
        }
        else if (loggedIn){
          if (isSubscriber){
            return (<RoutingSystemSubscribed />);
          }
          else {
            return (<RoutingSystemLoggedIn />);
          }
        }
        else if (!loggedIn) {
          return (<RoutingSystemLoggedOut />);
        }
      }
    )()*/}
    </IonApp>
  );
};

export default App;
