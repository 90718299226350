import {
  IonSearchbar,
  IonSelect,
  IonSelectOption,
  IonToast,
  IonIcon,
  IonButton,
  IonPopover,
  IonContent,
  IonRadioGroup,
  IonRadio,
  IonListHeader,
  IonItem,
  IonLabel
 } from '@ionic/react';

 import {
   readUserProfileById,
   readUserMatchmakingMatches,
   readGames,
   readSplitById,
 } from '../../firebase';

import { checkmarkDoneCircleOutline, funnel, options } from 'ionicons/icons';
import './MatchmakingSearchFilter.css';

import React, { useRef, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import moment from 'moment-timezone';

import MatchCardPanel from './MatchCardPanel';

interface MatchmakingSearchFilterProps {
  setMatchesSort?: any;
  setMatchesGamesFilter?: any;
  matchesGamesFilter?: any;
  gamesSelectRef?: any;
  matches?: any;
  setMatches?: any;
  searchText?: any;
  setSearchText?: any;
  tempOpponentSearchStrings?: any;
  setTempOpponentSearchStrings?: any;
  setGameId?:any;
  setGameType?:any;
  splits?: any;
  setSplits?: any;
  setGameRounds?: any;
}

const compareWith = (o1: any, o2: any) => {
  return o1 && o2 ? o1.id === o2.id : o1 === o2;
};

const MatchmakingSearchFilter: React.FC<MatchmakingSearchFilterProps> = ({setMatchesSort, setMatchesGamesFilter, matchesGamesFilter, gamesSelectRef, matches, setMatches, searchText, setSearchText, tempOpponentSearchStrings, setTempOpponentSearchStrings, setGameType, setGameId, splits, setSplits, setGameRounds}) => {

  const [games, setGames] = useState([]);

  const [searchPlayersText, setSearchPlayersText] = useState(''); //TODO - implement player search in the parent component

  const [search, setSearch] = useState(games); //TODO - specify this to "gamesSearch, setGamesSearch" and refactor in all places
  const [playerSearch, setPlayerSearch] = useState([]); //TODO - implement player search in the parent component

  const [gamesFilter, setGamesFilter] = useState<String[]>([]);
  const [playersFilter, setPlayersFilter] = useState<String[]>([]);

  const [showToast_success, setShowToast_success] = useState(false);

  const [showFilters, setShowFilters] = useState(false);
  const [showSort, setShowSort] = useState(false);

  const [detectSearch, setDetectSearch] = useState(0);

  const [sortParameter, setSortParameter] = useState('');

  const userId = useSelector((state: any) => state.userData.uid);

  useEffect(() => {
    /** Using isMounted to prevent React state updates on unmounted components based on this: https://stackoverflow.com/questions/53949393/cant-perform-a-react-state-update-on-an-unmounted-component **/
    let isMounted = true;

    readUserMatchmakingMatches(userId, async (m: any[]) => {

      var tempOpponentSearchObject: any = {};

      for (var i=0; i<m.length; i++){
        if (typeof(m[i])!="undefined"){
          var tempMatch: any = m[i];

          tempOpponentSearchObject[tempMatch.id] = [];

          for (var playerId in tempMatch.players){

            await readUserProfileById(playerId, (user: any) => {

              if (user.displayName){
                tempOpponentSearchObject[tempMatch.id].push(user.displayName);
              }

              if (user.email){
                tempOpponentSearchObject[tempMatch.id].push(user.email);
              }

              if (user.igns){
                if (user.igns.MTGArena){
                  tempOpponentSearchObject[tempMatch.id].push(user.igns.MTGArena);
                }
              }

              if (user.discordId){
                tempOpponentSearchObject[tempMatch.id].push(user.discordId);
              }

            });
          }
        }
      }
      if (isMounted) setTempOpponentSearchStrings(tempOpponentSearchObject);
    });

    return () => { isMounted = false };
  }, []);

  useEffect(() => {
    /** Using isMounted to prevent React state updates on unmounted components based on this: https://stackoverflow.com/questions/53949393/cant-perform-a-react-state-update-on-an-unmounted-component **/
    let isMounted = true;

    readUserMatchmakingMatches(userId, (m: any[]) => {
      var tempSearchResult = m.filter((ele:any) => {

        for (var playerId in ele.players){

        }
        if (ele.gameId.replace(/_/g, ' ').toLowerCase().includes(searchText.toLowerCase())){
          return true;
        }
        else if (ele.gameName.replace(/_/g, ' ').toLowerCase().includes(searchText.toLowerCase())){
          return true;
        }
        else if (ele.gameRounds.replace(/_/g, ' ').toLowerCase().includes(searchText.toLowerCase())){
          return true;
        }
        else if (ele.gameType.replace(/_/g, ' ').toLowerCase().includes(searchText.toLowerCase())){
          return true;
        }
        else {

          if (typeof(tempOpponentSearchStrings[ele.id]) != "undefined"){
            for (var i=0; i<tempOpponentSearchStrings[ele.id].length; i++){
              if (tempOpponentSearchStrings[ele.id][i].replace(/_/g, ' ').toLowerCase().includes(searchText.toLowerCase())){
                return true;
              }
            }
          }
        }
        return false;

      });

      if (matchesGamesFilter){
        var gamesFilterNames: string[] = [];
        gamesFilterNames.push(matchesGamesFilter.name);

        var tempSearchResult = tempSearchResult.filter((match) => {
          return gamesFilterNames.includes(match.gameName);
        });

      }

      if (isMounted) setMatches([...tempSearchResult]);
    });

    return () => { isMounted = false };
  },[searchText, detectSearch, tempOpponentSearchStrings])

  return (
      <div className="matchmaking-search-filter">
        {/*<h2 className="matchmaking-search-filter-game-title"> Game </h2>*/}
        <div className="games-search-select-container">
          { /** Don't need the search here, but it is implemented and it works */
            /* <IonSearchbar
            value={searchText}
            className="timezone-search"
            onIonChange={(e) => {
              setSearchText(e.detail.value!);
            }
          }></IonSearchbar>*/}

          <IonSelect
            placeholder="Game..."
            multiple={false}
            compareWith={compareWith}
            onIonChange={e => {
              if (e.detail.value){
                setGamesFilter(e.detail.value);
                setMatchesGamesFilter(e.detail.value);
                setGameType(e.detail.value.gameType);
                setGameId(e.detail.value.gameName);
                setGameRounds(e.detail.value.gameRounds);
                //gamesSelectRef.current && (gamesSelectRef.current.value = e.detail.value)
                //setShowToast_success(true);
              }
            }}
            className="games-select"
            interface="popover"
            cancelText="Cancel"
            okText="Accept"
            ref={gamesSelectRef}
          >
            {splits.map((split:any, index:any) => {
                return (
                  <IonSelectOption value={split} key={index}>
                    {split.gameName} | {split.gameType}
                  </IonSelectOption>
                );
              }
            )}
          </IonSelect>
        </div>


      {/* <IonButton fill="outline" color="medium" id="matchmaking-filter-button">
          <IonIcon icon={options} className=""/>
        </IonButton>
        <IonPopover trigger="matchmaking-filter-button" triggerAction="click">
          <IonContent class="ion-padding">Advanced Filters Coming Soon!</IonContent>
        </IonPopover>

        <IonButton fill="outline" color="medium" id="matchmaking-sort-button">
          <IonIcon icon={funnel} className=""/>
        </IonButton>
        <IonPopover trigger="matchmaking-sort-button" triggerAction="click">
          <IonContent class="ion-padding">
          <IonRadioGroup
            allowEmptySelection={true}
            value={sortParameter}
            onIonChange={(e) => {
              setSortParameter(e.detail.value);
              setMatchesSort(e.detail.value);
              //console.log("Setting Matches Sort to: "+e.detail.value); //TODO - remove debugging
            }}
          >
            <IonListHeader>
              <IonLabel>
                Sort Matches By...
              </IonLabel>
            </IonListHeader>
            <IonItem>
              <IonLabel>Date ↑</IonLabel>
              <IonRadio value="dateDescending" />
            </IonItem>
            <IonItem>
              <IonLabel>Date ↓</IonLabel>
              <IonRadio value="dateAscending" />
            </IonItem>
            </IonRadioGroup>
          </IonContent>
        </IonPopover>
        */}

        {/*<IonSearchbar
          value={searchText}
          className="matchmaking-search"
          placeholder="Search by username, email, discord ID, etc."
          onIonChange={(e) => {
            setSearchText(e.detail.value!);
            setDetectSearch(detectSearch + 1);
          }
        }></IonSearchbar>*/}

        <IonToast
          isOpen={showToast_success}
          onDidDismiss={() => setShowToast_success(false)}
          message="Game Added"
          duration={2000}
          color="primary"
          icon={checkmarkDoneCircleOutline}
          cssClass="timezone-toast"
        />
      </div>
  );
}

export default MatchmakingSearchFilter;
