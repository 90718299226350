import React, { useEffect, useState, useRef } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { app, auth, user } from '../../firebase';
import { useSelector } from 'react-redux';

import {
  IonButtons,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
  IonImg,
  IonButton,
  IonIcon,
  IonModal,
  IonLabel,
  IonInput,
  IonItem,
  IonList,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardSubtitle,
  IonCardContent,
  IonRadioGroup,
  IonRadio
} from '@ionic/react';

import './Profile.css';

import {
  readUserProfileById,
  readGames,
  readBanners,
  readPictures,
  readBorders,
  writeUserCosmetics
} from '../../firebase';

import {
  pencil,
  checkbox,
  lockClosed
} from 'ionicons/icons';

import ProfileBody from './ProfileBody';
import ProfileIGNBadge from './ProfileIGNBadge';


const Profile: React.FC = () => {

  const userId = useSelector((state: any) => state.userData.uid);

  const banner_modal = useRef<HTMLIonModalElement>(null);
  const picture_border_modal = useRef<HTMLIonModalElement>(null);

  const [currentPage, setCurrentPage] = useState("home");
  const [homeMenuCSSClassName, setHomeMenuCSSClassName] = useState("profile-header-menu-item profile-header-menu-item-selected");
  const [historyMenuCSSClassName, setHistoryMenuCSSClassName] = useState("profile-header-menu-item");
  const [settingsMenuCSSClassName, setSettingsMenuCSSClassName] = useState("profile-header-menu-item");

  const [displayName, setDisplayName] = useState();
  const [ignData, setIGNData] = useState<any>([]);

  const [banners, setBanners] = useState<any>([]);
  const [selectedBannerID, setSelectedBannerID] = useState("");
  const [confirmedBannerID, setConfirmedBannerID] = useState("");

  const [pictures, setPictures] = useState<any>([]);
  const [selectedPictureID, setSelectedPictureID] = useState("");
  const [confirmedPictureID, setConfirmedPictureID] = useState("");

  const [borders, setBorders] = useState<any>([]);
  const [selectedBorderID, setSelectedBorderID] = useState("");
  const [confirmedBorderID, setConfirmedBorderID] = useState("");

  const[pictureBorderModalPage, setPictureBorderModalPage] = useState("picture");

  var userIdSlice = userId.slice(0, 5);
  var userNumber = "#";
  userNumber = userNumber + userIdSlice;

  function confirmBanner(bannerID:string) {
    banner_modal.current?.dismiss();
    setConfirmedBannerID(bannerID);
    var data = {bannerId: bannerID};

    writeUserCosmetics(userId, data, (result:any)=>{});
  }

  function cancelBanner(bannerID:string) {
    banner_modal.current?.dismiss();
    setSelectedBannerID(bannerID);
  }

  function confirmPictureBorder(pictureID:string, borderID:string){
    picture_border_modal.current?.dismiss();
    setConfirmedPictureID(pictureID);
    setConfirmedBorderID(borderID);

    var data = {pictureId: pictureID, borderId: borderID};

    writeUserCosmetics(userId, data, (result:any)=>{});

  }

  function cancelPictureBorder(pictureID:string, borderID:string){
    picture_border_modal.current?.dismiss();
    setSelectedPictureID(pictureID);
    setSelectedBorderID(borderID);
  }

  useEffect(() => {
    document.title = "Esporter | Profile";

    /** Using isMounted to prevent React state updates on unmounted components based on this: https://stackoverflow.com/questions/53949393/cant-perform-a-react-state-update-on-an-unmounted-component **/
    let isMounted = true;

    if (isMounted) setHomeMenuCSSClassName("profile-header-menu-item");
    if (isMounted) setHistoryMenuCSSClassName("profile-header-menu-item");
    if (isMounted) setSettingsMenuCSSClassName("profile-header-menu-item");

    switch (currentPage){
      case "home":
        if (isMounted) setHomeMenuCSSClassName("profile-header-menu-item profile-header-menu-item-selected");
        break;
      case "match-history":
        if (isMounted) setHistoryMenuCSSClassName("profile-header-menu-item profile-header-menu-item-selected");
        break;
      case "settings":
        if (isMounted) setSettingsMenuCSSClassName("profile-header-menu-item profile-header-menu-item-selected");
        break;
      default:
        break;
    }

    return () => { isMounted = false };
  }, [currentPage]);

  useEffect(() => {
    /** Using isMounted to prevent React state updates on unmounted components based on this: https://stackoverflow.com/questions/53949393/cant-perform-a-react-state-update-on-an-unmounted-component **/
    let isMounted = true;


    readUserProfileById(userId, (u: any)=>{

      var tempIGNData:any[] = [];

      if (u.displayName){
        if (isMounted) setDisplayName(u.displayName);
      }

      if (u.discordId){
        tempIGNData.push({
          game: "Discord",
          ign: u.discordId,
          iconURL: "https://esporter.win/wp-content/uploads/2023/01/discord-mark-white.png"
        });
      }

      readGames((g: any) => {
        if (u.igns){
          for (var key in u.igns){
            if (ignData.filter((e:any) => e.game === key).length > 0 || tempIGNData.filter((e:any) => e.game === key).length > 0 ) {

            }
            else {
              for (var game in g){
                if (key.replace(/\s/g,'') == game.replace(/\s/g,'')){
                  tempIGNData.push({
                    game: game,
                    ign: u.igns[key],
                    iconURL: g[game].iconURL
                  });
                }
              }
            }
          }
          if (isMounted) setIGNData(tempIGNData);
        }
      });

      readBanners((b: any) => {
        // TODO - this will need server-side authentication of banner permission in the future

        var tempBanners = [];

        //For all of the banners in the database
        for (var banner in b){

          if (!u.restricted){
            return;
          }
          if (!u.restricted.cosmetics){
            return;
          }

          //if the user has their list of available banners (every user should have this list present in their data)
          if (u.restricted.cosmetics.banners){

            //if the banner is present in the user's cosmetics list
            if (u.restricted.cosmetics.banners.hasOwnProperty(banner)){

              if (!u.public || !u.public.cosmetics || !u.public.cosmetics.banner){

              }
              else  {
                if (u.public.cosmetics.banner == banner){
                  if (isMounted) setSelectedBannerID(banner);
                  if (isMounted) setConfirmedBannerID(banner);
                }
              }
              //if the banner in the user's cosmetic's list is set to 'true' (the user has permission to use the banner)
              if (u.restricted.cosmetics.banners[banner]){
                b[banner].isUnlocked = true;
                tempBanners.push(b[banner]);
              }
              //otherwise, the user does not have permission to use the banner
              else {

                //include the banner only if the banner is not hidden at this time
                if (!b[banner].isHidden){
                  b[banner].isUnlocked = false;
                  tempBanners.push(b[banner]);
                }

              }
            }
            //if the banner is not present in the user's cosmetics list
            else {

              //include the banner only if the banner is not hidden at this time
              if (!b[banner].isHidden){
                b[banner].isUnlocked = false;
                tempBanners.push(b[banner]);
              }
            }
          }
        }

        //Sort the banners so that unlocked ones appear before locked ones
        tempBanners = tempBanners.sort((a:any, b:any)=>{
          if (a.isUnlocked && !b.isUnlocked){
            return -1;
          }
          else if (b.isUnlocked && !a.isUnlocked){
            return 1;
          }
          else {
            return 0;
          }
        });

        if (isMounted) setBanners(tempBanners);
      });

      readPictures((p: any) => {
        // TODO - this will need server-side authentication of picture permission in the future

        var tempPictures:any[] = [];

        //For all of the pictures in the database
        for (var picture in p){

          if (!u.restricted){
            return;
          }
          if (!u.restricted.cosmetics){
            return;
          }

          //if the user has their list of available pictures (every user should have this list present in their data)
          if (u.restricted.cosmetics.pictures){

            //if the picture is present in the user's cosmetics list
            if (u.restricted.cosmetics.pictures.hasOwnProperty(picture)){

              if (!u.public || !u.public.cosmetics || !u.public.cosmetics.picture){

              }
              else {
                if (u.public.cosmetics.picture == picture){
                  if (isMounted) setSelectedPictureID(picture);
                  if (isMounted) setConfirmedPictureID(picture);
                }
              }
              //if the picture in the user's cosmetic's list is set to 'true' (the user has permission to use the picture)
              if (u.restricted.cosmetics.pictures[picture]){
                p[picture].isUnlocked = true;
                tempPictures.push(p[picture]);
              }

              //otherwise, the user does not have permission to use the picture
              else {

                //include the picture only if the picture is not hidden at this time
                if (!p[picture].isHidden){
                  p[picture].isUnlocked = false;
                  tempPictures.push(p[picture]);
                }

              }
            }
            //if the picture is not present in the user's cosmetics list
            else {

              //include the picture only if the picture is not hidden at this time
              if (!p[picture].isHidden){
                p[picture].isUnlocked = false;
                tempPictures.push(p[picture]);
              }
            }
          }
        }

        //Sort the pictures so that unlocked ones appear before locked ones
        tempPictures = tempPictures.sort((a:any ,b:any)=>{
          if (a.isUnlocked && !b.isUnlocked){
            return -1;
          }
          else if (b.isUnlocked && !a.isUnlocked){
            return 1;
          }
          else {
            return 0;
          }
        });

        if (isMounted) setPictures(tempPictures);
      });

      readBorders((b: any) => {
        // TODO - this will need server-side authentication of border permission in the future

        var tempBorders:any[] = [];

        //For all of the borders in the database
        for (var border in b){

          //if the user has their list of available pictures (every user should have this list present in their data)
          if (!u.restricted){
            return;
          }
          if (!u.restricted.cosmetics){
            return;
          }
          if (u.restricted.cosmetics.borders){

            //if the border is present in the user's cosmetics list
            if (u.restricted.cosmetics.borders.hasOwnProperty(border)){

              if (!u.public || !u.public.cosmetics || !u.public.cosmetics.border){

              }
              else {
                if (u.public.cosmetics.border == border){
                  if (isMounted) setSelectedBorderID(border);
                  if (isMounted) setConfirmedBorderID(border);
                }
              }

              //if the border in the user's cosmetic's list is set to 'true' (the user has permission to use the border)
              if (u.restricted.cosmetics.borders[border]){
                b[border].isUnlocked = true;
                tempBorders.push(b[border]);
              }

              //otherwise, the user does not have permission to use the border
              else {

                //include the border only if the border is not hidden at this time
                if (!b[border].isHidden){
                  b[border].isUnlocked = false;
                  tempBorders.push(b[border]);
                }

              }
            }
            //if the border is not present in the user's cosmetics list
            else {

              //include the border only if the border is not hidden at this time
              if (!b[border].isHidden){
                b[border].isUnlocked = false;
                tempBorders.push(b[border]);
              }
            }
          }
        }

        //Sort the borders so that unlocked ones appear before locked ones
        tempBorders = tempBorders.sort((a:any,b:any)=>{
          if (a.isUnlocked && !b.isUnlocked){
            return -1;
          }
          else if (b.isUnlocked && !a.isUnlocked){
            return 1;
          }
          else {
            return 0;
          }
        });

        if (isMounted) setBorders(tempBorders);
      });
    });

    return () => { isMounted = false };
  }, []);

  return (
    <div className="container profile-container">
      <div className="profile-header">

        {
          /***********
            The style attribute on the following <a> element does the following:

            1. Checks if the `confirmedBannerID` is set. The state variable is not set with a value until the useEffect function sets it.
            2. Checks if the `banners` (state variable) array contains a banner (object) with the `confirmedBannerID` as its `id` property. This will not be the case until the `banners` array is set in the useEffect function.
            3. If the `confirmedBannerID` is set and there is a banner in the `banner`s array with that ID, then we can use its `imageURL` property to set the `backgroundImage` style.
            4. If the `confirmedBannerID` is not set OR the `banners` array does not contain an object with the `confirmedBannerID` in it, then we return a default value to the style attribute that sets the `backgroundImage` to a default image.

            This is not very performant and will need to be done differently when there are a large number of banners in the database. It is specifically bad because it calls array.filter(...) twice, requiring a search over the whole banner list twice.

            TODO - optimize this line for performance when (if) needed due to a large number of banners in the list.

          */
        }
        <a className="profile-header-banner" id="open-banner-modal" style={(confirmedBannerID ? (banners.filter((banner:any) => banner.id === confirmedBannerID)[0] ? {backgroundImage: `url(${banners.filter((banner:any) => banner.id === confirmedBannerID)[0].imageURL})`} : {backgroundImage: "url(https://esporter.win/wp-content/uploads/2023/01/Banners-1-Topography.png)"}) : {backgroundImage: "url(https://esporter.win/wp-content/uploads/2023/01/Banners-1-Topography.png)"} )}>
          <IonModal ref={banner_modal} trigger="open-banner-modal" onWillDismiss={()=>{cancelBanner(confirmedBannerID)}}>
            <IonHeader>
              <IonToolbar>
                <IonTitle className="profile-header-banner-modal-title">Choose Your Banner</IonTitle>
                <IonButtons slot="start">
                  <IonButton onClick={() => cancelBanner(confirmedBannerID)}>Cancel</IonButton>
                </IonButtons>
                <IonButtons slot="end">
                  <IonButton className="profile-header-banner-modal-confirm-button" strong={true} onClick={() => confirmBanner(selectedBannerID)}>
                    Confirm
                  </IonButton>
                </IonButtons>
              </IonToolbar>
            </IonHeader>
            <IonContent>
              <IonList className="profile-header-banner-modal-option-list">
                {/* This will be a list called in from the database */}
                {
                  (banners.length ?

                    banners.map((banner:any, index:string) => {
                        //console.log(match); //TODO - remove debugging
                        //var tempBannerId = Math.floor(Math.random() * 100000);
                        var bannerKey = (banner.id ? banner.id : "") + " - Banner";
                        return (
                          <a
                            className ="profile-header-banner-modal-option-card-wrapper"
                            key={bannerKey}
                            onClick={() => {
                              if (banner.isUnlocked){
                                setSelectedBannerID(banner.id);
                              }
                            }}
                          >
                            <IonCard className={(banner.id == selectedBannerID ? "profile-header-banner-modal-option-card profile-header-banner-modal-option-card-selected" : (banner.isUnlocked ? "profile-header-banner-modal-option-card" : "profile-header-banner-modal-option-card profile-header-banner-modal-option-card-locked"))}>
                              <IonImg alt={banner.title} src={banner.imageURL} />
                              <IonCardHeader>
                                <IonCardTitle>{banner.title}</IonCardTitle>
                                <IonCardSubtitle>{banner.subtitle}</IonCardSubtitle>
                              </IonCardHeader>

                              <IonCardContent>
                                {banner.description}
                              </IonCardContent>
                              <IonIcon icon={checkbox} className={(banner.id == selectedBannerID ? "profile-header-banner-modal-option-card-selected-icon-visible" : "profile-header-banner-modal-option-card-selected-icon-hidden")}/>
                              <IonIcon icon={lockClosed} className={(banner.isUnlocked ? "profile-header-banner-modal-option-card-locked-icon-hidden" : "profile-header-banner-modal-option-card-locked-icon-visible")}/>
                              <div className={banner.isUnlocked ? "profile-header-banner-modal-option-card-locked-overlay-hidden" : "profile-header-banner-modal-option-card-locked-overlay-visible"}> </div>
                            </IonCard>
                          </a>
                        );
                      })
                  :
                    <div>
                      <h1>There are no banners at this time.</h1>
                    </div>
                  )
                }
              </IonList>
            </IonContent>
          </IonModal>
        </a>
        <IonIcon icon={pencil} className="profile-header-banner-edit-icon" />
        <div className="profile-header-info">
          <a className="profile-header-profile-picture" id="open-picture-border-modal">
            <IonImg src={(confirmedPictureID ? (pictures.filter((picture:any) => picture.id === confirmedPictureID)[0] ? pictures.filter((picture:any) => picture.id === confirmedPictureID)[0].imageURL : "https://esporter.win/wp-content/uploads/2022/03/AdobeStock_23725944-scaled.jpeg") : "https://esporter.win/wp-content/uploads/2022/03/AdobeStock_23725944-scaled.jpeg" )} className="profile-header-profile-image" />
            <IonImg src={(confirmedBorderID ? (borders.filter((border:any) => border.id === confirmedBorderID)[0] ? borders.filter((border:any) => border.id === confirmedBorderID)[0].imageURL : "https://esporter.win/wp-content/uploads/2022/07/esporter_profile_border_10.png") : "https://esporter.win/wp-content/uploads/2022/07/esporter_profile_border_10.png" )} className="profile-header-profile-border" />
            <IonModal ref={picture_border_modal} trigger="open-picture-border-modal" onWillDismiss={()=>{cancelPictureBorder(confirmedPictureID, confirmedBorderID)}}>
              <IonHeader>
                <IonToolbar>
                  <IonButtons slot="start">
                    <IonButton onClick={() => cancelPictureBorder(confirmedPictureID, confirmedBorderID)}>Cancel</IonButton>
                  </IonButtons>
                  <IonButtons slot="end">
                    <IonButton className="profile-header-picture-border-modal-confirm-button" strong={true} onClick={() => confirmPictureBorder(selectedPictureID, selectedBorderID)}>
                      Confirm
                    </IonButton>
                  </IonButtons>
                </IonToolbar>
              </IonHeader>
              <div className="profile-header-picture-border-modal-menu">
                <div className={(pictureBorderModalPage == "picture" ? "profile-header-picture-border-modal-menu-item profile-header-picture-border-modal-menu-item-selected" : "profile-header-picture-border-modal-menu-item")}>
                  <IonButton fill="clear" className="profile-header-menu-item-text" onClick={() => {setPictureBorderModalPage("picture")}}>
                    PICTURE
                  </IonButton>
                </div>
                <div className={(pictureBorderModalPage == "border" ? "profile-header-picture-border-modal-menu-item profile-header-picture-border-modal-menu-item-selected" : "profile-header-picture-border-modal-menu-item")}>
                  <IonButton fill="clear" className="profile-header-menu-item-text" onClick={() => {setPictureBorderModalPage("border")}}>
                    BORDER
                  </IonButton>
                </div>
              </div>
              {(
                  pictureBorderModalPage == "picture"
                ?
                  <IonContent>
                    <IonList className="profile-header-picture-border-modal-option-list">
                      {
                        (pictures.length ?

                          pictures.map((picture:any, index:string) => {
                              var pictureKey = (picture.id ? picture.id : "") + " - Picture";
                              return (
                                <a
                                  className ="profile-header-picture-border-modal-option-card-wrapper"
                                  key={pictureKey}
                                  onClick={() => {
                                    if (picture.isUnlocked){
                                      setSelectedPictureID(picture.id);
                                    }
                                  }}
                                >
                                  <IonCard className={(picture.id == selectedPictureID ? "profile-header-picture-border-modal-option-card profile-header-picture-border-modal-option-card-selected" : (picture.isUnlocked ? "profile-header-picture-border-modal-option-card" : "profile-header-picture-border-modal-option-card profile-header-picture-border-modal-option-card-locked"))}>
                                    <IonImg alt={picture.title} src={picture.imageURL} />
                                    <IonCardHeader>
                                      <IonCardTitle>{picture.title}</IonCardTitle>
                                      <IonCardSubtitle>{picture.subtitle}</IonCardSubtitle>
                                    </IonCardHeader>

                                    <IonCardContent>
                                      {picture.description}
                                    </IonCardContent>
                                    <IonIcon icon={checkbox} className={(picture.id == selectedPictureID ? "profile-header-picture-border-modal-option-card-selected-icon-visible" : "profile-header-picture-border-modal-option-card-selected-icon-hidden")}/>
                                    <IonIcon icon={lockClosed} className={(picture.isUnlocked ? "profile-header-picture-border-modal-option-card-locked-icon-hidden" : "profile-header-picture-border-modal-option-card-locked-icon-visible")}/>
                                    <div className={picture.isUnlocked ? "profile-header-picture-border-modal-option-card-locked-overlay-hidden" : "profile-header-picture-border-modal-option-card-locked-overlay-visible"}> </div>
                                  </IonCard>
                                </a>
                              );
                            })
                        :
                          <div>
                            <h1>There are no pictures at this time.</h1>
                          </div>
                        )
                      }
                    </IonList>
                  </IonContent>
                :
                  <IonContent>
                    <IonList className="profile-header-picture-border-modal-option-list">
                      {
                        (borders.length ?

                          borders.map((border:any, index:string) => {
                              //console.log(match); //TODO - remove debugging
                              //var tempBannerId = Math.floor(Math.random() * 100000);
                              var borderKey = (border.id ? border.id : "") + " - Border";
                              return (
                                <a
                                  className ="profile-header-picture-border-modal-option-card-wrapper"
                                  key={borderKey}
                                  onClick={() => {
                                    if (border.isUnlocked){
                                      setSelectedBorderID(border.id);
                                    }
                                  }}
                                >
                                  <IonCard className={(border.id == selectedBorderID ? "profile-header-picture-border-modal-option-card profile-header-picture-border-modal-option-card-selected" : (border.isUnlocked ? "profile-header-picture-border-modal-option-card" : "profile-header-picture-border-modal-option-card profile-header-picture-border-modal-option-card-locked"))}>
                                    <IonImg alt={border.title} src={border.imageURL} />
                                    <IonCardHeader>
                                      <IonCardTitle>{border.title}</IonCardTitle>
                                      <IonCardSubtitle>{border.subtitle}</IonCardSubtitle>
                                    </IonCardHeader>

                                    <IonCardContent>
                                      {border.description}
                                    </IonCardContent>
                                    <IonIcon icon={checkbox} className={(border.id == selectedBorderID ? "profile-header-picture-border-modal-option-card-selected-icon-visible" : "profile-header-picture-border-modal-option-card-selected-icon-hidden")}/>
                                    <IonIcon icon={lockClosed} className={(border.isUnlocked ? "profile-header-picture-border-modal-option-card-locked-icon-hidden" : "profile-header-picture-border-modal-option-card-locked-icon-visible")}/>
                                    <div className={border.isUnlocked ? "profile-header-picture-border-modal-option-card-locked-overlay-hidden" : "profile-header-picture-border-modal-option-card-locked-overlay-visible"}> </div>
                                  </IonCard>
                                </a>
                              );
                            })
                        :
                          <div>
                            <h1>There are no borders at this time.</h1>
                          </div>
                        )
                      }
                    </IonList>
                  </IonContent>
              )}
            </IonModal>
          </a>
          <IonIcon icon={pencil} className="profile-header-profile-picture-edit-icon" />
          <div className="profile-header-info-details">
              <div className="profile-header-info-details-name">
                <h1 className="profile-header-info-details-name-text">
                  {(displayName ? displayName : "Missing No.")}<span className="profile-header-info-details-name-text-number">{(userNumber ? userNumber : "#1234")}</span>
                </h1>
              </div>
              <div className="profile-header-info-details-name-subheader">
                <p>
                  Legendary Player
                </p>
              </div>
              <div className="profile-header-info-details-name-igns">
                {
                  (ignData.length ?

                    ignData.map((ign:any, index:string) => {
                        if (ign.ign){
                          return (<ProfileIGNBadge key={index} game={ign.game} ign={ign.ign} iconURL={ign.iconURL} />);
                        }
                      })
                  :
                  <div></div>
                  )
                }
              </div>
          </div>
        </div>
        <div className="profile-header-menu">
          <div className={homeMenuCSSClassName}>
            <IonButton fill="clear" className="profile-header-menu-item-text" onClick={() => {setCurrentPage("home")}}>
              PROFILE
            </IonButton>
          </div>
          <div className={historyMenuCSSClassName}>
            <IonButton fill="clear" className="profile-header-menu-item-text" onClick={() => {setCurrentPage("match-history")}}>
              MATCH HISTORY
            </IonButton>
          </div>
          <div className={settingsMenuCSSClassName}>
            <IonButton fill="clear" className="profile-header-menu-item-text profile-header-menu-item-settings" onClick={() => {setCurrentPage("settings")}}>
              SETTINGS
            </IonButton>
          </div>
        </div>
      </div>
      <ProfileBody currentPage={currentPage}/>
    </div>
  );
}

export default Profile;
