import {
  IonButtons,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonToggle,
  IonButton,
  IonIcon,
  IonSpinner
 } from '@ionic/react';
import './SplitCard.css';

import { useEffect, useState, useRef} from 'react';

import {
  writeGame,
  readGame
} from '../../firebase';

import {
  pencilOutline,
  trashOutline
} from 'ionicons/icons';

interface SplitCardProps {
  split: any;
}

const SplitCard: React.FC<SplitCardProps> = ({split}) => {

  const [isBusy, setIsBusy] = useState<boolean>(true);

  const [isActive, setIsActive] = useState(false);
  const [activeSplitId, setActiveSplitId] = useState('');
  const [gameTypes, setGameTypes] = useState<any[]>([]);
  const [gameRounds, setGameRounds] = useState<any[]>([]);
  const [splits, setSplits] = useState<any[]>([]);
  const [game, setGame] = useState<any>({});

  useEffect(() => {
    /** Using isMounted to prevent React state updates on unmounted components based on this: https://stackoverflow.com/questions/53949393/cant-perform-a-react-state-update-on-an-unmounted-component **/
    let isMounted = true;

    if (!split.gameName){
      split.gameName = "";
    }

    readGame(split.gameName.replace(/\./g, ""), (g:any)=>{
      if (g){
        if (isMounted) setGame(g);
        if (isMounted) setIsBusy(false);
      }

      if (g.splitIds){
        console.log("Reading Game");

        if (g.splitIds[split.splitId]){
          if (isMounted) setIsActive(true);
          if (isMounted) setActiveSplitId(split.splitId);
        }
      }

      var tempGameTypes:any[] = [];

      for (var gameType in g.gameTypes){
        if (g.gameTypes[gameType]){
          tempGameTypes.push(gameType);
        }
      }

      if (isMounted) setGameTypes(tempGameTypes);

      var tempGameRounds:any[] = [];

      for (var gameRound in g.gameRounds){
        if (g.gameRounds[gameRound]){
          tempGameRounds.push(gameRound);
        }
      }

      if (isMounted) setGameRounds(tempGameRounds);

      var tempSplits:any[] = [];

      for (var id in g.splitIds){
        tempSplits.push({
          splitId: id,
          active: g.splitIds[id]
        });
      }

      if (isMounted) setSplits(tempSplits);
    });

    return () => { isMounted = false };
  }, []);

  return (
    <>
    {
      (
        isBusy ? <IonSpinner />
        :
        <>
        {
          (
            game ?
            <IonCard className="game-card-container">
              <img alt={split.name} src={game.logoURL} className="game-card-image"/>
              <IonCardHeader>
                <IonCardTitle>{split.name}</IonCardTitle>
                <IonCardSubtitle className="game-card-subtitle">
                    {(isActive ? "Active" : 'Inactive')}
                    {
                      <IonToggle checked={isActive} onIonChange={(e)=>{

                        var tempIsActive = !isActive;
                        var tempGame = JSON.parse(JSON.stringify(game));

                        setIsActive(tempIsActive);

                        if (tempIsActive){
                          for (var id in tempGame.splitIds){
                            if (tempGame.splitIds[id]){
                              tempGame.splitIds[id] = false;
                            }
                          }
                          if (!tempGame.splitIds){
                            tempGame.splitIds = {};
                          }
                          tempGame.splitIds[split.splitId] = true;
                          setActiveSplitId(split.splitId);
                          setIsActive(true);
                        }
                        else {
                          tempGame.splitIds[split.splitId] = false;
                          setActiveSplitId("");
                          setIsActive(false);
                        }

                        setGame(tempGame);
                        writeGame(tempGame, ()=>{});

                      }}></IonToggle>

                    }
                  </IonCardSubtitle>
                <IonCardSubtitle className="game-card-subtitle-split-id"> {split.splitId}</IonCardSubtitle>
              </IonCardHeader>
              <div className="game-card-content-container">
                <div className="game-card-content">
                    {/*gameTypes.map((type, index) => {return (<p>{type}</p>);})*/}
                    <IonButton fill="clear" className="game-card-button" onClick={(e)=>{
                      window.open("/Splits/"+split.splitId, "_self");
                    }}>
                      <IonIcon slot="start" icon={pencilOutline} className="game-card-icon"/>
                    </IonButton>
                    <IonButton fill="clear" className="game-card-button">
                      <IonIcon slot="start" icon={trashOutline} className="game-card-icon"/>
                    </IonButton>
                </div>
              </div>

            </IonCard>
            :
            ''
          )
        }
        </>
      )
    }
    </>
  );
};

export default SplitCard;
