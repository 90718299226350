import {
  IonButtons,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardSubtitle,
  IonCardContent,
  IonItem,
  IonLabel,
  IonList,
  IonAccordionGroup,
  IonAccordion,
  IonButton,
  IonImg,
  IonSelect,
  IonSelectOption,
  IonSpinner,
  IonToast,
  IonSearchbar
 } from '@ionic/react';

import './Availability.css';

import AvailabilityDayCard from './AvailabilityDayCard';

import { arrowDownCircle, checkmarkDoneCircleOutline, alertCircleSharp } from 'ionicons/icons';
import { timezones } from '../../timezones';
import { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import {
  writeUserAvailabilityTimezone,
  readUserAvailabilityTimezone
} from '../../firebase';

var currentPage: any;
var setCurrentPage: any;

const AvailabilityHome: React.FC = () => {

  useEffect(() => {
    document.title = "Esporter | Availability";
  }, []);

  return (
    <div className="availability-container container">
      <div className="availability-start">
        <div>
          <IonImg src="https://esporter.win/wp-content/uploads/2021/12/AdobeStock_255866715-scaled.jpeg" className="availability-image"/>
        </div>
        <p className="container-explainer">
          We schedule all activities based on the <span className="availability-emphasis">best shared availability </span> of the people in your Split. Click to continue and set your availability.
        </p>
        <IonButton
          className="availability-schedule-button"
          expand="block"
          onClick={() => {setCurrentPage("manage")}}
        >
          Manage Availability
        </IonButton>
      </div>
    </div>
  );
};

const AvailabilityManage: React.FC = () => {
  const [timezone, setTimezone] = useState<string>();
  const [busy, setBusy] = useState(true);
  const [currentTimezone, setCurrentTimezone] = useState<string>();
  const [showToast_error, setShowToast_error] = useState(false);
  const [showToast_success, setShowToast_success] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [filteredSearch, setFilteredSearch] = useState(timezones);

  const timezoneSelectRef = useRef<HTMLIonSelectElement>(null);

  const userId = useSelector((state: any) => state.userData.uid);

  var result: any;

  useEffect(() => {
    document.title = "Esporter | Availability";

    /** Using isMounted to prevent React state updates on unmounted components based on this: https://stackoverflow.com/questions/53949393/cant-perform-a-react-state-update-on-an-unmounted-component **/
    let isMounted = true;
    readUserAvailabilityTimezone(userId).then((tz:any)=> {
      if (isMounted) setCurrentTimezone(tz);
      if (isMounted) setBusy(false);
    }).catch((e) => {
      console.log(e);
      if (isMounted) setBusy(false);
    });

    return () => { isMounted = false };
  }, []);

  useEffect(() => {
    /** Using isMounted to prevent React state updates on unmounted components based on this: https://stackoverflow.com/questions/53949393/cant-perform-a-react-state-update-on-an-unmounted-component **/
    let isMounted = true;

    let tempSearchResult = timezones.filter(ele => ele.replace(/_/g, ' ').toLowerCase().includes(searchText.toLowerCase()));
    if (isMounted) setFilteredSearch([...tempSearchResult]);

  },[searchText])

  const pageContent = (
    <div className="availability-container container">
      <div className="availability-start">
        <p className="container-explainer">
          Select your <span className="availability-emphasis">timezone</span> and select all <span className="availability-emphasis">times that you are typically available</span> for each day of the week. All changes are automatically saved.
        </p>
      </div>
      <div className="availability-day-card-container">
      <div className="timezone-search-select-container availability-day-card">
        <p><i>Use the search to filter timezone options</i></p>
        <IonSearchbar
          value={searchText}
          className="timezone-search"
          onIonChange={(e) => {
            setSearchText(e.detail.value!);
            //timezoneSelectRef.current?.open();
          }
        }></IonSearchbar>
        <IonSelect
          value={timezone ? timezone : currentTimezone}
          placeholder="Select..."
          onIonChange={e => {
            setTimezone(e.detail.value);
            if (e.detail.value){
              var result = writeUserAvailabilityTimezone(userId, e.detail.value);
              if (result != "error"){
                setShowToast_success(true);
              }
              else {
                setShowToast_error(true);
              }
            }
          }}
          className="timezone-select"
          interface="popover"
          cancelText="Cancel"
          okText="Accept"
          ref={timezoneSelectRef}
        >
          {filteredSearch.map((timezone, index) => (
            <IonSelectOption value={timezone} key={index}>{timezone}</IonSelectOption>
          ))}
        </IonSelect>

        <IonToast
          isOpen={showToast_error}
          onDidDismiss={() => setShowToast_error(false)}
          message="Error Updating. Please try again later."
          duration={2000}
          color="danger"
          icon={alertCircleSharp}
          cssClass="timezone-toast"
        />
        <IonToast
          isOpen={showToast_success}
          onDidDismiss={() => setShowToast_success(false)}
          message="Timezone Saved"
          duration={2000}
          color="primary"
          icon={checkmarkDoneCircleOutline}
          cssClass="timezone-toast"
        />
      </div>
        <AvailabilityDayCard day="Sundays"/>
        <AvailabilityDayCard day="Mondays"/>
        <AvailabilityDayCard day="Tuesdays"/>
        <AvailabilityDayCard day="Wednesdays"/>
        <AvailabilityDayCard day="Thursdays"/>
        <AvailabilityDayCard day="Fridays"/>
        <AvailabilityDayCard day="Saturdays"/>
      </div>
      <IonButton
        className="availability-schedule-button"
        expand="block"
        onClick={() => {
          setCurrentPage("home");
        }}
      >
        Done
      </IonButton>
    </div>
  );

  return (
    <>
    {busy ? <IonSpinner /> : pageContent}
    </>
  );
}

const Availability: React.FC = () => {

  [currentPage, setCurrentPage] = useState("home");

  switch (currentPage){
    case "home":
      return (<AvailabilityManage />);
    case "manage":
      return (<AvailabilityManage />);
    default:
      return (<AvailabilityManage />);
  }
};

export default Availability;
