import {
  IonButtons,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardSubtitle,
  IonCardContent,
  IonItem,
  IonLabel,
  IonList,
  IonAccordionGroup,
  IonAccordion,
  IonToggle,
  IonIcon,
  IonButton,
  IonBadge,
  IonImg,
  IonDatetime,
  IonPopover,
  IonToast,
  IonText,
  IonSpinner,
  useIonAlert
 } from '@ionic/react';

 import {
   writeUserAvailabilityTime,
   readUserProfileById,
   updateMatchWithUserSuggestedTime,
   acceptOpponentSuggestedTime,
   writeUserNotification,
   sendNotificationEmail,
   updateMatchWithUserScore,
   updateMatchStatus,
   updateMatchWithUserStatus,
   readBorders,
   readBanners,
   readPictures,
   readGames,
   readUserMatchById,
   updateMatchToScoresConflict
 } from '../../firebase';

import {
  arrowDownCircle,
  pin,
  calendarClear,
  trophySharp,
  gameControllerSharp,
  logoDiscord,
  informationCircleSharp,
  pencilSharp,
  closeCircleOutline,
  timeOutline,
  checkmarkDoneCircleOutline,
  ellipsisHorizontal,
  flag,
  clipboard,
  reload,
  pencil,
  paperPlane,
  alertCircleOutline
} from 'ionicons/icons';

import './MatchCard.css';

import React, { useRef, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import moment from 'moment-timezone';

import MatchCardPanel from './MatchCardPanel';

interface MatchCardProps {
  matchId: any;
  matchmaking?: boolean;
}

const MatchCard: React.FC<MatchCardProps> = ({matchId, matchmaking}) => {
  //console.log("Start MatchCard..."); //TODO - remove debugging
  //console.log(match); //TODO - remove debugging

  const userId = useSelector((state: any) => state.userData.uid);

  const [match, setMatch] = useState<any>({});

  //const [matchState, setMatchState] = useState<any>(match);

  const [scoreReporting, setScoreReporting] = useState<boolean>();

  const [busy, setBusy] = useState(true);

  const [gameName, setGameName] = useState<string>('MTG Arena');
  const [gameId, setGameId] = useState<string>('MTGA');
  const [gameType, setGameType] = useState<string>('Limited');
  const [gameRounds, setGameRounds] = useState<string>('Best of 3');

  const [matchDateTime, setMatchDateTime] = useState<string>('');
  const [matchTime, setMatchTime] = useState<string>('');

  const [playerProposedMatchDateTime, setPlayerProposedMatchDateTime] = useState<string>('');
  const [playerProposedMatchDateTimeLabel, setPlayerProposedMatchDateTimeLabel] = useState<string>('');
  const [playerTimezone, setPlayerTimezone] = useState<string>('US/Pacific');

  const [opponentProposedMatchDateTime, setOpponentProposedMatchDateTime] = useState<string>('');
  const [opponentProposedMatchDateTimeLabel, setOpponentProposedMatchDateTimeLabel] = useState<string>('');
  const [opponentTimezone, setOpponentTimezone] = useState<string>('US/Pacific');

  const [games, setGames] = useState<any>([]);
  const [banners, setBanners] = useState<any>([]);
  const [pictures, setPictures] = useState<any>([]);
  const [borders, setBorders] = useState<any>([]);

  const [player1IGN, setPlayer1IGN] = useState<string>('MissingNo.');
  const [player1Username, setPlayer1Username] = useState<string>('MissingNo.');
  const [player1Discord, setPlayer1Discord] = useState<string>('MissingNo.');
  const [player1Email, setPlayer1Email] = useState<string>('');
  const [player1StatusHTML, setPlayer1StatusHTML] = useState<any>('');
  const [player1BorderID, setPlayer1BorderID] = useState<string>('');
  const [player1PictureID, setPlayer1PictureID] = useState<string>('');

  const [player2Id, setPlayer2Id] = useState<string>('MissingNo.');
  const [player2Username, setPlayer2Username] = useState<string>('MissingNo.');
  const [player2IGN, setPlayer2IGN] = useState<string>('MissingNo.');
  const [player2Discord, setPlayer2Discord] = useState<string>('MissingNo.');
  const [player2Email, setPlayer2Email] = useState<string>('');
  const [player2StatusHTML, setPlayer2StatusHTML] = useState<any>('');
  const [player2BorderID, setPlayer2BorderID] = useState<string>('');
  const [player2PictureID, setPlayer2PictureID] = useState<string>('');
  const [player2BannerID, setPlayer2BannerID] = useState<string>('');

  const [proposeTimePopoverIsOpen, setProposeTimePopoverIsOpen] = useState<boolean>(false);
  const [showAcceptTimeButton, setShowAcceptTimeButton] = useState<boolean>(false);
  const [showProposeTimeButton, setShowProposeTimeButton] = useState<boolean>(false);
  const [showMenu, setShowMenu] = useState<boolean>(false);

  const [showToast_timeProposed, setShowToast_timeProposed] = useState(false);
  const [showToast_timeAccepted, setShowToast_timeAccepted] = useState(false);
  const [showToast_copySuccess, setShowToast_copySuccess] = useState(false);
  const [showToast_scoresConflict_acceptedOpponentScore, setShowToast_scoresConflict_acceptedOpponentScore] = useState<boolean>(false);
  const [showToast_scoresConflict_yourOpponentWasEmailed, setShowToast_scoresConflict_yourOpponentWasEmailed] = useState<boolean>(false);

  const [showToasts, setShowToasts] = useState<any>(
    {
      accept_match: false
    }
  );

  const [matchMoment, setMatchMoment] = useState<any>(moment());

  const matchMenuWrapperRef = useRef<any>(null);

  const[menu, setMenu] = useState<any>();

  var match_moment = moment(match.actual_time).tz(match.timezone);

  var time_format = '';

  var date_format = "MMM Do";
  var time_format = "h:mma";

  var proposed_time_date_time_format = "MMM Do h:mma";

  var matchCardPanelKey;

  if (matchId){
    matchCardPanelKey = "" + matchId + " - MatchCardPanel";
  }
  else {
    matchCardPanelKey = Math.floor(Math.random() * 100000) + " - MatchCardPanel";
  }

  const [present] = useIonAlert();

  useEffect(() => {
    let isMounted = true;

    readBorders((borders: any)=>{
      var tempBorders = [];

      //For all of the borders in the database
      for (var id in borders){
        tempBorders.push(borders[id]);
      }

      if (isMounted) setBorders(tempBorders);
    });

    readBanners((banners: any)=>{
      var tempBanners = [];

      //For all of the borders in the database
      for (var id in banners){
        tempBanners.push(banners[id]);
      }
      if (isMounted) setBanners(tempBanners);
    });

    readPictures((pictures: any)=>{
      var tempPictures = [];

      //For all of the borders in the database
      for (var id in pictures){
        tempPictures.push(pictures[id]);
      }

      if (isMounted) setPictures(tempPictures);
    });

    readGames((games:any )=>{
      var tempGames = [];

      //For all of the borders in the database
      for (var id in games){
        tempGames.push(games[id]);
      }

      if (isMounted) setGames(tempGames);
    });

    readUserMatchById(matchId, (match: any)=>{
      if (match) {
        if (isMounted) setMatch(match);
        if (isMounted) setMatchMoment(moment(match.actual_time).tz(match.timezone));
        if (isMounted) setBusy(false);
      }
    });

    return () => { isMounted = false };
  }, []);

  useEffect(() => {
    document.addEventListener("click", handleMatchMenuClickOutside, false);
    return () => {
      document.removeEventListener("click", handleMatchMenuClickOutside, false);
    };
  }, [showMenu]);

  const handleMatchMenuClickOutside = (event: any) => {
    if (showMenu){
      if (matchMenuWrapperRef.current && !matchMenuWrapperRef.current.contains(event.target)) {
        setShowMenu(false);
      }
    }
  };

  useEffect(()=>{
    let isMounted = true;
    //console.log("UseEffect - Dependencies [matchMoment] - MatchCard.tsx - Line 149"); //TODO - remove debugging

    var player2Id_temp: string  = '';

    for (var id in match.players){
      //TODO remove everything in the TRUE portion of this if statement. Keep everything in the FALSE section of the statement. This is deprecated. Some of the older matches use array notation as keys because they were not set up correctly. This has been fixed and the if statement only serves to service the old matches.
      if (id == "0" || id == "1"){
        //console.log("Found old match"); // TODO - remove debugging
        if (match.players[id] != userId){
          if (isMounted) setPlayer2Id(match.players[id]);
          player2Id_temp = match.players[id];
        }
      }
      else {
        if (id != userId){
          //console.log("Player 2 found: "+ id);
          if (isMounted) setPlayer2Id(id);
          player2Id_temp = id;
        }
      }
    }

    if (!player2Id_temp){
      //console.log("There is no player 2"); //TODO - remove debugging
    }
    else {
      var opponentUsername: string = 'MissingNo.';
      var opponentEmail: string = 'MissingNo.';

      //Get Player 2's info
      readUserProfileById(player2Id_temp, (user: any) => {

        if (user == null) {
          //console.log("Player 2 with ID "+player2Id_temp+" does not exist.");
          return;
        }

        if (user.email){
          if (isMounted) setPlayer2Email(user.email);
        }
        else {
          //console.log("Player 2 doesn't have an email address");
        }

        if (user.igns){
          switch (match.gameId){
            case "MTG Arena":
              if (user.igns.MTGArena){
                //TODO - check this against the match game and choose the right IGN
                if (isMounted) setPlayer2IGN(user.igns.MTGArena);
              }
              else {
                if (isMounted) setPlayer2IGN("MissingNo.");
                //console.log("Player 2 doesn't have an MTGArena name and their ID is: "+player2Id_temp);
              }
              break;
            case "Hearthstone":
              if (user.igns.Hearthstone){
                //TODO - check this against the match game and choose the right IGN
                if (isMounted) setPlayer2IGN(user.igns.Hearthstone);
              }
              else {
                if (isMounted) setPlayer2IGN("MissingNo.");
                //console.log("Player 2 doesn't have an MTGArena name and their ID is: "+player2Id_temp);
              }
              break;
            default:
              if (user.igns.MTGArena){
                //TODO - check this against the match game and choose the right IGN
                if (isMounted) setPlayer2IGN(user.igns.MTGArena);
              }
              else {
                if (isMounted) setPlayer2IGN("MissingNo.");
                //console.log("Player 2 doesn't have an MTGArena name and their ID is: "+player2Id_temp);
              }
              break;
          }
        }
        else {
          if (isMounted) setPlayer2IGN("MissingNo.");
          //console.log("Player 2 doesn't have any igns and their ID is: "+player2Id_temp);
        }

        if (user.discordId){
          if (isMounted) setPlayer2Discord(user.discordId);
        }
        else {
          if (isMounted) setPlayer2Discord("MissingNo.");
          //console.log("Player 2 doesn't have a discord ID and their ID is: "+player2Id_temp);
        }

        if (user.public && user.public.cosmetics){
          if (user.public.cosmetics.picture){
            if (isMounted) setPlayer2PictureID(user.public.cosmetics.picture);
          }

          if (user.public.cosmetics.border){
            if (isMounted) setPlayer2BorderID(user.public.cosmetics.border);
          }

          if (user.public.cosmetics.banner){
            if (isMounted) setPlayer2BannerID(user.public.cosmetics.banner);
          }
        }

        if (user.availability.timezone){
          if (isMounted) setOpponentTimezone(user.availability.timezone);
        }
        else {
          if (isMounted) setOpponentTimezone("America/Los_Angeles");
        }

        if (!user.displayName){
          user.displayName = "MissingNo.";
          //console.log("Username not found. Set to default"); //TODO - remove debugging
        }
        //console.log("Username found: "+user.displayName); //TODO - remove debugging
        if (isMounted) setPlayer2Username(user.displayName);

        //We have to save the opponent name and email in this variable in order to use it in the same render cycle. Setting the state does not allow us to use that new value in the state during this render cycle. This is required below when we use present() to show options to the user when the scores conflict. Check it out there.
        opponentUsername = user.displayName;
        opponentEmail = user.email;

      });
    }

    switch (match.status){

      /** MATCHMAKING MENU CONTEXT **/
      case 'matchmaking':
        //TODO - implement
        break;

      /** START MENU CONTEXT **/
      case 'start':
        if (isMounted) setMenu((
          <div className="match-card-menu-container">
            <IonButton className="match-card-menu-button" fill="clear" onClick={(e: any)=>{
              setProposeTimePopoverIsOpen(true);
            }}>
              <IonIcon icon={timeOutline} className="match-card-menu-button-icon"/>
              <IonLabel className="match-card-menu-button-label">Reschedule</IonLabel>
            </IonButton>
            <IonButton className="match-card-menu-button" fill="clear" onClick={(e: any)=>{
              present({
                cssClass: 'popup',
                header: 'Are you sure?',
                message: 'Please confirm that you want to concede the game and accept a loss.',
                buttons: [
                          'Cancel',
                          {
                            text: 'Ok', handler: (d) => {
                              // TODO: Update the opponent's screen to show a popup that their opponent has forfeited
                              updateMatchWithUserScore(userId, match, 0, 0, "opponent");
                              updateMatchStatus(match, "done", true, userId);
                            }
                          }
                        ],
                onDidDismiss: (e) => console.log('did dismiss')
              });
            }}>
              <IonIcon icon={flag} className="match-card-menu-button-icon match-card-menu-button-icon-danger"/>
              <IonLabel className="match-card-menu-button-label match-card-menu-button-label-danger">Concede</IonLabel>
            </IonButton>
          </div>
        ));
        break;

      /** AWAITING MENU CONTEXT **/
      case 'awaiting':
        break;

      /** IN PROGRESS MENU CONTEXT **/
      case 'inProgress':
        // If the player is done, but the match is not done yet, then the player should be able to resubmit their score via the menu
        if (match.players[userId].status == 'done' && match.status == "inProgress"){

          if (isMounted) setMenu((
            <div className="match-card-menu-container">
              {/*<IonButton className="match-card-menu-button" fill="clear">
                <IonIcon icon={reload} className="match-card-menu-button-icon"/>
                <IonLabel className="match-card-menu-button-label">Rematch</IonLabel>
              </IonButton>*/}
              <IonButton className="match-card-menu-button" fill="clear" onClick={(e: any)=>{
                //updateMatchWithUserStatus(userId, match, "inProgress");
                updateMatchToScoresConflict(match, false);
                setMatch({...match, playersAgreeOnScore: false});
              }}>
                <IonIcon icon={alertCircleOutline} className="match-card-menu-button-icon"/>
                <IonLabel className="match-card-menu-button-label">Contest Score</IonLabel>
              </IonButton>
              <IonButton className="match-card-menu-button" fill="clear" onClick={(e: any)=>{
                present({
                  cssClass: 'popup',
                  header: 'Are you sure?',
                  message: 'Please confirm that you want to concede the game and accept a loss.',
                  buttons: [
                            'Cancel',
                            {
                              text: 'Ok', handler: (d) => {
                                // TODO: Update the whole game to be done
                                // TODO: Update the opponent's screen to show a popup that their opponent has forfeited
                                updateMatchWithUserScore(userId, match, 0, 0, "opponent");
                                updateMatchStatus(match, "done", true, userId);
                                //setCurrentPage("done");

                                //TODO: Update this to make the matchcard change. We may need to
                              }
                            }
                          ],
                  onDidDismiss: (e) => console.log('did dismiss')
                });
              }}>
                <IonIcon icon={flag} className="match-card-menu-button-icon match-card-menu-button-icon-danger"/>
                <IonLabel className="match-card-menu-button-label match-card-menu-button-label-danger">Concede</IonLabel>
              </IonButton>
            </div>
          ));
        }
        else if(match.players[userId].status == 'inProgress' && match.status == "inProgress" ){
          if (isMounted) setMenu((
            <div className="match-card-menu-container">
              <IonButton className="match-card-menu-button" fill="clear" onClick={(e: any)=>{
                setProposeTimePopoverIsOpen(true);
              }}>
                <IonIcon icon={timeOutline} className="match-card-menu-button-icon"/>
                <IonLabel className="match-card-menu-button-label">Reschedule</IonLabel>
              </IonButton>
              <IonButton className="match-card-menu-button" fill="clear" onClick={(e: any)=>{
                present({
                  cssClass: 'popup',
                  header: 'Are you sure?',
                  message: 'Please confirm that you want to concede the game and accept a loss.',
                  buttons: [
                            'Cancel',
                            {
                              text: 'Ok', handler: (d) => {
                                // TODO: Update the whole game to be done
                                // TODO: Update the opponent's screen to show a popup that their opponent has forfeited
                                updateMatchWithUserScore(userId, match, 0, 0, "opponent");
                                updateMatchStatus(match, "done", true, userId);
                                //setCurrentPage("done");

                                //TODO: Update this to make the matchcard change. We may need to
                              }
                            }
                          ],
                  onDidDismiss: (e) => console.log('did dismiss')
                });
              }}>
                <IonIcon icon={flag} className="match-card-menu-button-icon match-card-menu-button-icon-danger"/>
                <IonLabel className="match-card-menu-button-label match-card-menu-button-label-danger">Concede</IonLabel>
              </IonButton>
            </div>
          ));
        }
      break;

      /** DONE MENU CONTEXT **/
      case 'done':
        if (isMounted) setMenu((
          <div className="match-card-menu-container">
            {/*<IonButton className="match-card-menu-button" fill="clear">
              <IonIcon icon={reload} className="match-card-menu-button-icon"/>
              <IonLabel className="match-card-menu-button-label">Rematch</IonLabel>
            </IonButton>*/}
            <IonButton className="match-card-menu-button" fill="clear" onClick={(e: any)=>{
              //updateMatchWithUserStatus(userId, match, "inProgress");
              updateMatchToScoresConflict(match, false);
              setMatch({...match, playersAgreeOnScore: false});
            }}>
              <IonIcon icon={alertCircleOutline} className="match-card-menu-button-icon"/>
              <IonLabel className="match-card-menu-button-label">Contest Score</IonLabel>
            </IonButton>
          </div>
        ));
      break;

      /** DEFAULT MENU CONTEXT **/
      default:
        if (isMounted) setMenu((
          <div className="match-card-menu-container">
            {/*<IonButton className="match-card-menu-button" fill="clear">
              <IonIcon icon={reload} className="match-card-menu-button-icon"/>
              <IonLabel className="match-card-menu-button-label">Rematch</IonLabel>
            </IonButton>*/}
          </div>
        ));
      break;
    }

    if(match.gameRounds){
      if (isMounted) setGameRounds(match.gameRounds);
    }

    if(match.gameId){
      if (isMounted) setGameId(match.gameId);
    }

    if(match.gameName){
      if (isMounted) setGameName(match.gameName);
    }

    if(match.gameType){
      if (isMounted) setGameType(match.gameType);
    }

    if (match.status == "start"){
      if (isMounted) setShowProposeTimeButton(true);
    }

    if (match.players){
      for (var playerId in match.players){
        if (playerId == "0" || playerId == "1"){
          //Do nothing... these matches were created before statuses
        }
        else {
          //console.log("Detected Status: "+match.players[playerId].status+" For Player: "+playerId+" In match: "+match.id); //TODO - remove debugging

          //TODO - update this to work for more than 2 players in a match
          var setStatusFunction: any;
          if (playerId == userId){
            setStatusFunction = setPlayer1StatusHTML;
          }
          else {
            setStatusFunction = setPlayer2StatusHTML;
          }
          switch (match.status) {
              case "matchmaking":
                switch (match.players[playerId].status){
                  case "matchmaking":
                  case "start":
                  case "awaiting":
                  case "inProgress":
                  case "done":
                  default:
                    if (isMounted) setStatusFunction((
                      <div className="match-card-player-details-item match-card-player-details-status match-card-player-details-status-pending">
                        <p>Matchmaking</p>
                      </div>
                    ));
                  break;
                }
                break;
              case "start":
                switch (match.players[playerId].status){
                  case "start":
                    if (isMounted) setStatusFunction(
                      <div className="match-card-player-details-item match-card-player-details-status match-card-player-details-status-pending">
                        <p>Pending</p>
                      </div>
                    );
                  break;
                  case "inProgress":
                    /* if (isMounted) setStatusFunction((
                      <div className="match-card-player-details-item match-card-player-details-status match-card-player-details-status-inProgress">
                        <p>Playing...</p>
                      </div>
                    ));*/
                  break;
                  case "done":
                    /* if (isMounted) setStatusFunction((
                      <div className="match-card-player-details-item match-card-player-details-status match-card-player-details-status-inProgress">
                        <p>Score Submitted</p>
                      </div>
                    ));*/
                  break;
                  case "matchmaking":
                  case "awaiting":
                  default:
                  /*  if (isMounted) setStatusFunction((
                      <div className="match-card-player-details-item match-card-player-details-status match-card-player-details-status-pending">
                        <p>Pending</p>
                      </div>
                    )); */
                  break;
                }
                break;
              case "inProgress":
                switch (match.players[playerId].status){
                  case "inProgress":
                    /*if (isMounted) setStatusFunction((
                      <div className="match-card-player-details-item match-card-player-details-status match-card-player-details-status-inProgress">
                        <p>Playing...</p>
                      </div>
                    ));*/
                  break;
                  case "done":
                    /*if (isMounted) setStatusFunction((
                      <div className="match-card-player-details-item match-card-player-details-status match-card-player-details-status-inProgress">
                        <p>Score Submitted</p>
                      </div>
                    ));*/
                  break;
                  case "matchmaking":
                  case "start":
                  case "awaiting":
                  default:
                    /*if (isMounted) setStatusFunction((
                      <div className="match-card-player-details-item match-card-player-details-status match-card-player-details-status-pending">
                        <p>Pending</p>
                      </div>
                    ));*/
                  break;
                }
                break;

              /** Setting Statuses when the Match is DONE**/
              case "done":
                switch (match.players[playerId].status){
                  case "done":
                    //If the match was conceded...
                    if (match.wasConcededBy){
                      //If the person who conceded is the player we are evaluating...
                      if (match.wasConcededBy == playerId){
                        //Set the player's status to "Conceded" in our danger color...
                        if (isMounted) setStatusFunction((
                          <div className="match-card-player-details-item match-card-player-details-status match-card-player-details-status-danger">
                            <p>Conceded</p>
                          </div>
                        ));
                      }
                      //Otherwise, if the person who conceded is NOT the player we are evaluating...
                      else {
                        //Set the status of the person to "Winner" because the person who did not concede always wins.
                        if (isMounted) setStatusFunction((
                          <div className="match-card-player-details-item match-card-player-details-status match-card-player-details-status-success">
                            <p>Winner</p>
                          </div>
                        ));
                      }
                    }
                    //If the match was NOT conceded, AND if the players agree on the score...
                    else if (match.playersAgreeOnScore){
                      //If the player we are evaluating was the winner...
                      if (match.players[playerId].isWinner){
                        //Set the status of this player to "Winner"
                        if (isMounted) setStatusFunction((
                          <div className="match-card-player-details-item match-card-player-details-status match-card-player-details-status-success">
                            <p>Winner</p>
                          </div>
                        ));
                      }
                      //Otherwise, if the player we are evaluating  is NOT the winner
                      else {
                        //Do nothing, they will not have a badge. This is specifically a choice to not include a "Loser" badge

                        //setStatusFunction(''); //TODO - remove debugging
                      }
                    }
                    //Otherwise, if the match is "done", but it was not conceded AND the players do not agree on the score, show that the scores conflict
                    else {
                      //If the player is our user, show them that they can click on the badge to resolve the conflict
                      if (playerId != userId){

                        //Pull the scores that the opponent submitted
                        var opponentId: string = '';
                        var opponentScore: number;
                        var playerScore: number;
                        var winText: string = '';
                        var winner: string = '';

                        for (var p_id in match.players){
                          if (p_id != userId){
                            opponentId = p_id;
                            opponentScore = match.players[p_id].score[p_id];
                            playerScore = match.players[p_id].score[userId];

                            if (opponentScore > playerScore){
                              winText = "you lost";
                              winner = "opponent";
                            }
                            if (opponentScore < playerScore){
                              winText = "you won";
                              winner = "player";
                            }
                            if (opponentScore == playerScore){
                              winText = "you tied";
                              winner = "both";
                            }
                          }
                        }

                        if (isMounted) setStatusFunction((
                          <div className="match-card-player-details-item match-card-player-details-status match-card-player-details-status-danger match-card-player-details-status-clickable"
                            onClick= {()=> {
                              present({
                                cssClass: 'scores-conflict-popup',
                                header: 'Scores Conflict',
                                subHeader: 'Your opponent\'s says this was the score...',
                                message: opponentUsername+': '+opponentScore+' | You: '+playerScore,
                                buttons: [
                                          'Cancel',
                                          {
                                            text: 'Accept Opponent Score',
                                            role: 'accept-opponent-score',
                                            handler: (d) => {
                                              updateMatchWithUserScore(userId, match, playerScore, opponentScore, winner);
                                              updateMatchStatus(match, "done");
                                              setShowToast_scoresConflict_acceptedOpponentScore(true); // TODO - fix this. It doesn't work because before the toast can render, the match is updated. This triggers the whole component (MatchCard:FC, including the toast and the state variables) to reset. We do this intentionally (but poorly) to make sure realtime updates are coming through to all of the matches. We need a more elegant way to target updates to specific components when the database listeners detect a change.
                                            }
                                          },
                                          {
                                            text: 'Update the Score',
                                            role: 'edit-your-score',
                                            handler: (d) => {
                                              updateMatchWithUserStatus(userId, match, "inProgress");
                                              updateMatchStatus(match, "inProgress");
                                              setMatch({...match, players: {...match.players, [userId]: {...match.players[userId], status: "inProgress"}}});
                                              //setMatch({...match, status: "inProgress"});
                                              //TODO - set toast

                                            }
                                          },
                                          {
                                            text: 'I\'m Sure About My Score',
                                            role: 'reconfirm-your-score',
                                            handler: (d) => {
                                              sendNotificationEmail(opponentEmail, 'accept-score-request');
                                              setShowToast_scoresConflict_yourOpponentWasEmailed(true);
                                            }
                                          }
                                        ],
                                onDidDismiss: (e) => console.log('did dismiss')
                              });
                            }}
                          >
                            <p>Scores Conflict (Click Me)</p>
                          </div>
                        ));
                      }
                      //Otherwise, if the player is NOT our user, just show that the scores conflict, without the clickable element
                      else {
                        setStatusFunction((
                          <div className="match-card-player-details-item match-card-player-details-status match-card-player-details-status-danger">
                            <p>Scores Conflict</p>
                          </div>
                        ));
                      }

                    }
                  break;
                  case "matchmaking":
                  case "start":
                  case "awaiting":
                  case "inProgress":
                  default:
                    if (isMounted) setStatusFunction((
                      <div className="match-card-player-details-item match-card-player-details-status match-card-player-details-status-pending">
                        <p>Pending</p>
                      </div>
                    ));
                  break;
                }
                break;
              default:
                break;
            }
        }
      }
    }

    var days_difference;

    try {
      days_difference = matchMoment.diff(moment(), "days");
    }
    catch(e){
      console.log(e);
      days_difference = 0;
    }

    if (days_difference < 6 && days_difference >= 0) {
      date_format = "dddd";
    }
    else if (days_difference < 0){
      date_format = "MMM Do, YYYY";
    }
    else {
      date_format = "MMM Do";
    }

    //Get Player 1's info
    readUserProfileById(userId, (user: any) =>{
      if(user.availability){
        if (user.availability.timezone){
          if (isMounted) setPlayerTimezone(user.availability.timezone);
          if (isMounted) setMatchDateTime(matchMoment.tz(user.availability.timezone).format(date_format));
          if (isMounted) setMatchTime(matchMoment.tz(user.availability.timezone).format(time_format));
        }
        else {
          if (isMounted) setMatchDateTime(matchMoment.format(date_format));
          if (isMounted) setMatchTime(matchMoment.format(time_format));
        }
      }
      else {
        if (isMounted) setMatchDateTime(matchMoment.format(date_format));
        if (isMounted) setMatchTime(matchMoment.format(time_format))
      }

      if (user.igns){
        switch (match.gameId){
          case "MTG Arena":
            if (user.igns.MTGArena){
              if (isMounted) setPlayer1IGN(user.igns.MTGArena);
            }
            break;
          case "Hearthstone":
            if (user.igns.Hearthstone){
              if (isMounted) setPlayer1IGN(user.igns.MTGArena);
            }
            break;
          default:
            if (user.igns.MTGArena){
              if (isMounted) setPlayer1IGN(user.igns.MTGArena);
            }
            break;
        }
      }

      if(!user.displayName){
        user.displayName = "MissingNo.";
      }
      if (isMounted) setPlayer1Username(user.displayName);

      if (!user.discordId){
        user.discordId = "MissingNo.";
      }
      if (isMounted) setPlayer1Discord(user.discordId);

      if (user.public && user.public.cosmetics){
        if (user.public.cosmetics.border){
          if (isMounted) setPlayer1BorderID(user.public.cosmetics.border);
        }

        if (user.public.cosmetics.picture){
          if (isMounted) setPlayer1PictureID(user.public.cosmetics.picture);
        }
      }

      var proposed_moment = moment();

      const user_timezone = (user.availability.timezone ? user.availability.timezone : "US/Pacific"); // If the user doesn't have their timezone set in their availability, we have to assume our timezone unless they go and set it.

      /* Check if the opponent has proposed a new time and if they have, put it on the match and give the user the ability to accept it */
      for (var playerId in match.players){
        if(!match.players[playerId].suggestedTime){
          // This if statement catches all previous matches that did not have the suggestedTime field on each player in the list. Makes sure that the suggested time UI elements don't appear on deprecated matches.
        }
        else if (playerId != userId){
          if (!moment(match.players[playerId].suggestedTime).isSame(moment(match.actual_time))){
            proposed_moment = moment(match.players[playerId].suggestedTime).tz(user_timezone);
            if (isMounted) setOpponentProposedMatchDateTime(proposed_moment.format());
            if (isMounted) setOpponentProposedMatchDateTimeLabel("Opponent Proposed Time: " + proposed_moment.format(proposed_time_date_time_format));
            if (isMounted) setShowAcceptTimeButton(true);
          }
          else {
            //console.log("The other player has not proposed a new time"); //TODO - Remove debugging
          }
        }
        else {
          if (!moment(match.players[playerId].suggestedTime).isSame(moment(match.actual_time))){
            proposed_moment = moment(match.players[playerId].suggestedTime).tz(user_timezone);
            if (isMounted) setPlayerProposedMatchDateTime(proposed_moment.format());
            if (isMounted) setPlayerProposedMatchDateTimeLabel("You Proposed Time: " + proposed_moment.format(proposed_time_date_time_format));
          }

        }
      }
    });
    return () => { isMounted = false };
  }, [matchMoment]);

  //console.log("UseEffect - Dependencies [] - MatchCard.tsx - Line 751"); //TODO - remove debugging
  const datetime = useRef<null | HTMLIonDatetimeElement>(null);

  return (
    <>
    {
    busy ? <IonSpinner /> :

    <div className="matches-container">
      <div className='match-card' style={(player2BannerID ? (banners.filter((banner : any) => banner.id === player2BannerID)[0] ? {backgroundImage: `url(${banners.filter((banner: any) => banner.id === player2BannerID)[0].imageURL})`} : {backgroundImage: "https://esporter.win/wp-content/uploads/2023/01/Banners-1-Topography.png"}) : {backgroundImage: "https://esporter.win/wp-content/uploads/2023/01/Banners-1-Topography.png"} )}>
        <div className="match-card-background-image-overlay">
        </div>
        <div className="match-card-bumper match-card-left-bumper">
        </div>
        <div className="match-card-player match-card-left-player">
          <div className="match-card-player-profile match-card-player-profile-left">
            <IonImg src={(player2PictureID ? (pictures.filter((picture:any) => picture.id === player2PictureID)[0] ? pictures.filter((picture:any) => picture.id === player2PictureID)[0].imageURL : "https://esporter.win/wp-content/uploads/2022/03/AdobeStock_23725944-scaled.jpeg") : "https://esporter.win/wp-content/uploads/2022/03/AdobeStock_23725944-scaled.jpeg")} className="match-card-player-profile-image" />
            <IonImg src={(player2BorderID ? (borders.filter((border:any) => border.id === player2BorderID)[0] ? borders.filter((border:any) => border.id === player2BorderID)[0].imageURL : "https://esporter.win/wp-content/uploads/2022/07/esporter_profile_border_10.png") : "https://esporter.win/wp-content/uploads/2022/07/esporter_profile_border_10.png")} className="match-card-player-profile-border" />
          </div>
          <div className="match-card-player-details match-card-player-details-left">
            <div className="match-card-player-details-bumper">
            </div>
            {player2StatusHTML}
            <div className="match-card-player-details-item match-card-player-details-username">
              <IonText className="match-card-player-details-username-text match-card-player-details-text-left">
                <p>{player2Username}</p>
              </IonText>
            </div>
            <div className="match-card-player-details-item match-card-player-details-discord match-card-player-details-badge" title="Discord ID">
              <div className="match-card-player-details-badge match-card-player-details-badge-left" onClick={() => {
                navigator.clipboard.writeText(player2Discord);
                setShowToast_copySuccess(true);
              }}>
                <div className="match-card-player-details-badge-icon match-card-player-details-badge-icon-left">
                  <IonIcon icon={logoDiscord} slot="start" />
                </div>
                <div className="match-card-player-details-badge-text">
                  <IonText className="match-card-player-details-discord-text match-card-player-details-text-left">
                    <p>{player2Discord}</p>
                  </IonText>
                </div>
              </div>
            </div>
            <div className="match-card-player-details-item match-card-player-details-ign match-card-player-details-badge" title={(gameName + " ID")}>
              <div className="match-card-player-details-badge match-card-player-details-badge-left"  onClick={() => {
                navigator.clipboard.writeText(player2IGN);
                setShowToast_copySuccess(true);
              }}>
                <div className="match-card-player-details-badge-icon match-card-player-details-badge-icon-left">
                  <IonIcon icon={gameControllerSharp} slot="start" />
                </div>
                <div className="match-card-player-details-badge-text">
                  <IonText className="match-card-player-details-ign-text match-card-player-details-text-left">
                    <p>{player2IGN}</p>
                  </IonText>
                </div>
              </div>
            </div>
            <div className="match-card-player-details-bumper">
            </div>
          </div>
        </div>
        <div className="match-card-center-details">
          <div className="match-card-center-details-item match-card-center-details-game">
            <IonText className="match-card-center-details-game-text">
              {(
                  games.filter((game:any) => game.name === gameName)[0]
                ?
                  <IonImg src={games.filter((game:any) => game.name === gameName)[0].logoURL} className="match-card-center-detauls-game-logo"/>
                :
                  <p>{gameName}</p>
              )}
            </IonText>
          </div>
          <div className="match-card-center-details-item match-card-center-details-type">
            <IonText className="match-card-center-details-type-text">
              <p>{gameType} - {gameRounds}</p>
            </IonText>
          </div>
          <div className="match-card-center-details-item match-card-center-details-rounds">

          </div>
          <MatchCardPanel match={match} matchmaking={matchmaking} showToasts={showToasts} setShowToasts={setShowToasts}/>
          <div className="match-card-center-details-item match-card-center-details-time">
            <IonText className="match-card-center-details-time-text">
              {(playerProposedMatchDateTimeLabel && opponentProposedMatchDateTimeLabel ? '' : <h1>{matchDateTime} @ {matchTime}</h1>)}
            </IonText>
            <IonText className="match-card-center-proposed-time-text">
              {(playerProposedMatchDateTimeLabel ? <p>{playerProposedMatchDateTimeLabel}</p> : '')}
            </IonText>
            <IonText className="match-card-center-proposed-time-text">
              {(opponentProposedMatchDateTimeLabel ? <p>{opponentProposedMatchDateTimeLabel}</p> : '')}
            </IonText>
            {(showAcceptTimeButton ?
              <IonButton className="match-card-footer-accept-time-button" onClick={(e: any)=>{
                acceptOpponentSuggestedTime(match, opponentProposedMatchDateTime);
                setPlayerProposedMatchDateTimeLabel('');
                setOpponentProposedMatchDateTimeLabel('');
                setShowAcceptTimeButton(false);
                setShowToast_timeAccepted(true);
                setMatchMoment(moment(opponentProposedMatchDateTime));
                sendNotificationEmail(player2Email, "accept-match-time");

                /*var new_match = match;

                for (var playerId in new_match.players){
                  new_match.players[playerId].suggestedTime = moment(opponentProposedMatchDateTime).tz(match.timezone).format();
                }
                new_match.actual_time = moment(opponentProposedMatchDateTime).tz(match.timezone).format();

                setMatchState(new_match);*/

                // TODO - Update the date on the match itself to reflect the newly accepted time
                // TODO - fix the issue with timezones
                // TODO - Consider implementing calendar link on the date card if time allows https://www.npmjs.com/package/calendar-link
              }}>
                {/*<IonIcon icon={checkmarkDoneCircleOutline} slot="start"/>*/}
                <IonLabel>Accept Opponent's Time</IonLabel>
              </IonButton>
            :'')}
          </div>
        </div>
        <div className="match-card-player match-card-right-player">
          <div className="match-card-player-details match-card-player-details-right">
            <div className="match-card-player-details-bumper">
            </div>
            {player1StatusHTML}
            <div className="match-card-player-details-item match-card-player-details-username">
              <IonText className="match-card-player-details-username-text match-card-player-details-text-right">
                <p>{player1Username}</p>
              </IonText>
            </div>
            <div className="match-card-player-details-item match-card-player-details-discord match-card-player-details-badge" title="Discord ID">
              <div className="match-card-player-details-badge match-card-player-details-badge-right" onClick={() => {
                navigator.clipboard.writeText(player1Discord);
                setShowToast_copySuccess(true);
              }}>
                <div className="match-card-player-details-badge-text">
                  <IonText className="match-card-player-details-discord-text match-card-player-details-text-right">
                    <p>{player1Discord}</p>
                  </IonText>
                </div>
                <div className="match-card-player-details-badge-icon match-card-player-details-badge-icon-right">
                  <IonIcon icon={logoDiscord} slot="start" />
                </div>
              </div>
            </div>
            <div className="match-card-player-details-item match-card-player-details-ign match-card-player-details-badge" title={(gameName + " ID")}>
              <div className="match-card-player-details-badge match-card-player-details-badge-right" onClick={() => {
                navigator.clipboard.writeText(player1IGN);
                setShowToast_copySuccess(true);
              }}>
                <div className="match-card-player-details-badge-text">
                  <IonText className="match-card-player-details-ign-text match-card-player-details-text-right">
                    <p>{player1IGN}</p>
                  </IonText>
                </div>
                <div className="match-card-player-details-badge-icon match-card-player-details-badge-icon-right">
                  <IonIcon icon={gameControllerSharp} slot="start" />
                </div>
              </div>
            </div>
            <div className="match-card-player-details-bumper">
            </div>
          </div>
          <div className="match-card-player-profile match-card-player-profile-right">
            <IonImg src={(player1PictureID ? (pictures.filter((picture:any) => picture.id === player1PictureID)[0] ? pictures.filter((picture:any) => picture.id === player1PictureID)[0].imageURL : "https://esporter.win/wp-content/uploads/2022/03/AdobeStock_23725944-scaled.jpeg") : "https://esporter.win/wp-content/uploads/2022/03/AdobeStock_23725944-scaled.jpeg")} className="match-card-player-profile-image" />
            <IonImg src={(player1BorderID ? (borders.filter((border:any) => border.id === player1BorderID)[0] ? borders.filter((border:any) => border.id === player1BorderID)[0].imageURL : "https://esporter.win/wp-content/uploads/2022/07/esporter_profile_border_3.png") : "https://esporter.win/wp-content/uploads/2022/07/esporter_profile_border_3.png")} className="match-card-player-profile-border" />
          </div>
        </div>

        <div className="match-card-bumper match-card-right-bumper match-card-right-settings">
          {(menu ?
            <IonButton fill="clear" className="match-card-right-settings-button" onClick={(e)=>{
              setShowMenu(!showMenu);
            }}>
              <IonIcon icon={ellipsisHorizontal} className="match-card-right-settings-icon"/>
            </IonButton>
          :
            ''
          )}
          {(showMenu ? <div ref={matchMenuWrapperRef}>{menu}</div> : '')}
        </div>
        </div>

      <IonPopover className="match-card-footer-propose-time-picker" isOpen={proposeTimePopoverIsOpen} onDidDismiss={ () => {setProposeTimePopoverIsOpen(false);}} >
        <IonDatetime
          showDefaultButtons={true}
          ref={datetime}
          min={moment().format()}
          onIonChange={(e) => {
            var userSuggestedTime: string = moment(e.detail.value).tz(playerTimezone).format();
            //console.log("Player suggested time: "+userSuggestedTime+" in timezone: "+playerTimezone);
            updateMatchWithUserSuggestedTime(userId, match, userSuggestedTime);
            setPlayerProposedMatchDateTimeLabel("You Proposed Time: " + moment(e.detail.value).tz(playerTimezone).format(proposed_time_date_time_format));
            setShowToast_timeProposed(true);
            sendNotificationEmail(player2Email, "propose-match-time");

            var notification_description: string = "Your opponent wants to reschedule your match to " + moment(e.detail.value).tz(opponentTimezone).format(proposed_time_date_time_format) + ". Click here to manage your matches.";

            var notification: any = {
              content: {
                description: notification_description,
                title: "Reschedule Your Match"
              },
              url: "https://app.esporter.win/Matches",
              type: "proposed-time"
            }

            for (var playerId in match.players){
              if (playerId != userId){
                writeUserNotification(playerId, notification.type, notification.content.description, notification.type, notification.url, notification.content.title)
              }
            }

          }}
        >
        </IonDatetime>
      </IonPopover>
      <IonToast
        isOpen={showToast_timeProposed}
        onDidDismiss={() => setShowToast_timeProposed(false)}
        message="New Time Proposed"
        duration={2000}
        color="primary"
        icon={checkmarkDoneCircleOutline}
        cssClass="time-proposed-toast"
      />

      <IonToast
        isOpen={showToast_timeAccepted}
        onDidDismiss={() => setShowToast_timeAccepted(false)}
        message="New Time Accepted"
        duration={2000}
        color="primary"
        icon={checkmarkDoneCircleOutline}
        cssClass="time-accepted-toast"
      />

      <IonToast
        isOpen={showToast_copySuccess}
        onDidDismiss={() => setShowToast_copySuccess(false)}
        message="Copied"
        duration={2000}
        color="medium"
        icon={clipboard}
        cssClass="copy-toast"
      />
      <IonToast
        isOpen={showToast_scoresConflict_acceptedOpponentScore}
        onDidDismiss={() => setShowToast_scoresConflict_acceptedOpponentScore(false)}
        message="You have accepted your opponent's score."
        duration={2000}
        color="primary"
        icon={checkmarkDoneCircleOutline}
        cssClass="accepted-opponent-score-toast"
      />
      <IonToast
        isOpen={showToast_scoresConflict_yourOpponentWasEmailed}
        onDidDismiss={() => setShowToast_scoresConflict_yourOpponentWasEmailed(false)}
        message="We emailed your opponent and asked them to confirm this score."
        duration={4000}
        color="primary"
        icon={paperPlane}
        cssClass="emailed-opponent-score-toast"
      />

      <IonToast
        isOpen={showToasts.accept_match}
        onDidDismiss={() => setShowToasts({...showToasts, accept_match:false})}
        message="Match Accepted"
        duration={2000}
        color="primary"
        icon={checkmarkDoneCircleOutline}
        cssClass="accept-match-toast"
      />


    </div>
    }
    </>
  );
}

export default MatchCard;
