import { IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import './LeaderboardsAdmin.css';

const LeaderboardsAdmin: React.FC = () => {

  return (
    <div className="container leaderboards-admin-container">
      <strong>Leaderboards Admin Panel</strong>
      <p>Coming Soon...</p>
    </div>
  );
};

export default LeaderboardsAdmin;
