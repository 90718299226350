import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { app, auth, user } from '../../firebase';

import {
  IonButtons,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
  IonImg,
  IonButton,
  IonSpinner,
  IonList,
  IonItem,
  IonLabel,
  IonInput,
  IonToast,
  IonToggle
} from '@ionic/react';

import {
  readUserMatchHistory,
  readUserProfileById,
  writeUserInfo,
  readUserFutureMatches,
  readGames,
  writeUserGame,
  writeUserIGN
} from '../../firebase';

import {
  checkmarkDoneCircleOutline
} from 'ionicons/icons';

import { useSelector } from 'react-redux';

import MatchCard from './MatchCard';
import ProfileUpcomingMatch from './ProfileUpcomingMatch';

import './ProfileBody.css';

interface ProfileBodyProps {
  currentPage?: any;
}

const ProfileBodyHome: React.FC<ProfileBodyProps> = () => {

  const userId = useSelector((state: any) => state.userData.uid);

  const [matches, setMatches] = useState<any[]>([]);
  const [games, setGames] = useState<any[]>([]);
  const [user, setUser] = useState<any>();

  useEffect(() => {
    /** Using isMounted to prevent React state updates on unmounted components based on this: https://stackoverflow.com/questions/53949393/cant-perform-a-react-state-update-on-an-unmounted-component **/
    let isMounted = true;

    readUserFutureMatches(userId, (m: any[]) => {
      //console.log("Use Effect: [matches] Dependencies - Running - Matches.tsx - MatchesHome - Line 38"); //TOOD - remove debugging

      if (JSON.stringify(m) !== JSON.stringify(matches)){
        if (isMounted) setMatches(m);
      }
    });

    readGames((g:any)=>{
      var tempGames = [];
      for (var game in g){
        for (var splitId in g[game].splitIds){
          if (g[game].splitIds[splitId]){
            tempGames.push(g[game]);
          }
        }
      }
      if (isMounted) setGames(tempGames);
    });

    readUserProfileById(userId, (u: any)=>{
      if (!u.public){
        u.public = {};
      }
      if (!u.public.games){
        u.public.games = {};
      }

      if (isMounted) setUser(u);
    });

    return () => { isMounted = false };
  }, [matches]);

  return (
    <>
      <div className="profile-left-container">
      <div className="profile-upcoming-matches">
          <div className="profile-section-title">
            <p className="profile-section-title-text">
              UPCOMING MATCHES <span className="profile-upcoming-matches-number-of-matches-text"> {matches.length} </span>
            </p>
          </div>
          <div className="profile-section-body">

            <div className="profile-upcoming-matches-list">
              {
                (matches.length ?

                  matches.map((match, index) => {
                      var matchCardKey = (match.id ? match.id : "") + " - MatchCard";
                      return (<ProfileUpcomingMatch match={match} key={matchCardKey}/>);
                    })
                :
                  <div>
                    <h1>You have no upcoming matches.</h1>
                    <p>Go to <a href="/Matches"><b>Matchmaking</b></a> to get started!</p>
                  </div>
                )
              }
            </div>

            <div className="profile-upcoming-matches-more">
              <IonButton className="profile-upcoming-matches-more-button" href="/Matches">MORE</IonButton>
            </div>

          </div>
        </div>
        {/* <div className="profile-badges">
          <div className="profile-section-title">
          </div>
        </div> */}
        {/* <div className="profile-skills">
          <div className="profile-section-title">
          </div>
        </div> */}
      </div>

        <div className="profile-right-container">
          <div className="profile-games">
            <div className="profile-section-title">
              <p className="profile-section-title-text">
                My Games
              </p>
            </div>
            <div className="profile-section-body">
              <IonList className="profile-games-list">

                {
                  games.map((game, index) => {
                    var gameKey = (game.name ? game.name : "") + " - Game Select";
                    return (
                      <IonItem className="profile-settings-user-info-list-item" key={gameKey}>
                        <IonImg className="profile-game-image" src={(game.logoURL ? game.logoURL : "https://esporter.win/wp-content/uploads/2022/02/G_Arena_EN_1C_White_V14_Medium_72DPI1.svg")}/>
                        <IonLabel className="profile-settings-user-info-list-item-label"></IonLabel>
                        <IonToggle
                          className="profile-settings-user-info-list-item-input"
                          checked={(user.public.games[game.name] ? user.public.games[game.name] : false)}
                          onIonChange={(e) => {
                            if (typeof user.public.games[game.name] != "boolean"){
                              user.public.games[game.name] = false;
                            }
                            writeUserGame(userId, game.name, !user.public.games[game.name], ()=>{
                            });
                          }}
                          >
                        </IonToggle>
                      </IonItem>
                    );
                  })
                }

              </IonList>
            </div>
          </div>
          <div className="profile-achievements">
          </div>
        </div>

    </>
  );
}

const ProfileBodyMatchHistory: React.FC<ProfileBodyProps> = () => {
  const [busy, setBusy] = useState(true);
  const [matches, setMatches] = useState<any[]>([]);
  const [pageContent, setPageContent] = useState<any>();

  const userId = useSelector((state: any) => state.userData.uid);

  useEffect(() => {
    /** Using isMounted to prevent React state updates on unmounted components based on this: https://stackoverflow.com/questions/53949393/cant-perform-a-react-state-update-on-an-unmounted-component **/
    let isMounted = true;
    let shouldStop = false;

    //console.log("Use Effect: [matches] Dependencies - Running - Matches.tsx - MatchHistory - Line 110"); //TODO - remove debugging

    readUserMatchHistory(userId, (m: any[]) => {
      if (JSON.stringify(m) === JSON.stringify(matches)){
        if (isMounted) setBusy(false);
      }
      else {
        if (isMounted) setMatches(m);
        setPageContent(
          (
            <div className="profile-history-match-card-container">
            {
              (m.length ?

                m.map((match, index) => {
                    var matchCardKey = (match.id ? match.id : Math.floor(Math.random() * 100000)) + " - MatchCard";
                    return (<MatchCard matchId={match.id} key={matchCardKey} matchmaking={(match.status == "matchmaking" ? true : false)}/>);
                  })
              :
                <div>
                  <h1>You have no match history.</h1>
                  <p>Go to <a href="/Matches"><b>Matchmaking</b></a> to get started! </p>
                </div>
              )
            }
            </div>
          )
        );
      }
    });
    return () => { isMounted = false };
  }, [matches]);

  return (
    <>
    {busy ? <IonSpinner /> : pageContent}
    </>
  );
}

const ProfileBodySettings: React.FC<ProfileBodyProps> = () => {

  const userId = useSelector((state: any) => state.userData.uid);

  const [games, setGames] = useState<any[]>([]);
  const [user, setUser] = useState<any>();

  const [displayName, setDisplayName] = useState<string>("");
  const [newDisplayName, setNewDisplayName] = useState<string>("");

  const [phone, setPhone] = useState<string>("");
  const [newPhone, setNewPhone] = useState<string>("");

  const [firstName, setFirstName] = useState<string>("");
  const [newFirstName, setNewFirstName] = useState<string>("");

  const [lastName, setLastName] = useState<string>("");
  const [newLastName, setNewLastName] = useState<string>("");

  const [email, setEmail] = useState<string>("");
  const [uid, setUid] = useState<any>(userId);

  const [discordId, setDiscordId] = useState<string>("");
  const [newDiscordId, setNewDiscordId] = useState<string>("");

  const [ignmtga, setIGNMTGA] = useState<string>("");
  const [newIGNMTGA, setNewIGNMTGA] = useState<string>("");

  const [ignBattleNet, setIGNBattleNet] = useState<string>("");
  const [newIGNBattleNet, setNewIGNBattleNet] = useState<string>("");

  const [showToast_success_updated_IGNs, setShowToast_success_updated_IGNs] = useState<boolean>(false);
  const [showToast_success_updated_info, setShowToast_success_updated_info] = useState<boolean>(false);

  useEffect(() => {
    /** Using isMounted to prevent React state updates on unmounted components based on this: https://stackoverflow.com/questions/53949393/cant-perform-a-react-state-update-on-an-unmounted-component **/
    let isMounted = true;

    readUserProfileById(userId, (u: any) => {
      if (u.displayName){
        if (isMounted) setDisplayName(u.displayName);
      }

      if (u.phone) {
        if (isMounted) setPhone(u.phone);
      }

      if (u.firstName){
        if (isMounted) setFirstName(u.firstName);
      }

      if (u.lastName){
        if (isMounted) setLastName(u.lastName);
      }

      if (u.email){
        if (isMounted) setEmail(u.email);
      }

      if (u.discordId){
        if (isMounted) setDiscordId(u.discordId);
      }

      if (u.igns && u.igns["MTGArena"]){
        if (isMounted) setIGNMTGA(u.igns["MTGArena"]);
      }

      if (u.igns && u.igns["Hearthstone"]){
        if (isMounted) setIGNBattleNet(u.igns["Hearthstone"]);
      }

    });

    readGames((g:any)=>{
      var tempGames:any[] = [];
      for (var game in g){
        tempGames.push(g[game]);
      }
      if (isMounted) setGames(tempGames);
    });

    readUserProfileById(userId, (u: any)=>{
      if (isMounted) setUser(u);
    });
    return () => { isMounted = false };
  }, []);

  return (
    <>
      <div className="profile-left-container">
      <div className="profile-settings-user-info">
          <div className="profile-section-title">
            <p className="profile-section-title-text">
              USER INFO
            </p>
          </div>
          <div className="profile-section-body profile-settings-section-body">
          <IonList className="profile-settings-user-info-list">

            <IonItem className="profile-settings-user-info-list-item">
              <IonLabel className="profile-settings-user-info-list-item-label">Display Name:</IonLabel>
              <IonInput
                className="profile-settings-user-info-list-item-input"
                value={(displayName ? displayName : "")}
                onIonChange={(e) => {
                  setNewDisplayName(e.detail.value!);
                  setDisplayName(e.detail.value!);
                }}
                >
              </IonInput>
            </IonItem>

            <IonItem className="profile-settings-user-info-list-item">
              <IonLabel className="profile-settings-user-info-list-item-label">Phone:</IonLabel>
              <IonInput
                className="profile-settings-user-info-list-item-input"
                value={(phone ? phone : "")}
                onIonChange={(e) => {
                  setNewPhone(e.detail.value!);
                  setPhone(e.detail.value!);
                }}
                >
              </IonInput>
            </IonItem>

            <IonItem className="profile-settings-user-info-list-item">
              <IonLabel className="profile-settings-user-info-list-item-label">First Name:</IonLabel>
              <IonInput
                className="profile-settings-user-info-list-item-input"
                value={(firstName ? firstName : "")}
                onIonChange={(e) => {
                  setNewFirstName(e.detail.value!);
                  setFirstName(e.detail.value!);
                }}
                >
              </IonInput>
            </IonItem>

            <IonItem className="profile-settings-user-info-list-item">
              <IonLabel className="profile-settings-user-info-list-item-label">Last Name:</IonLabel>
              <IonInput
                className="profile-settings-user-info-list-item-input"
                value={(lastName ? lastName : "")}
                onIonChange={(e) => {
                  setNewLastName(e.detail.value!);
                  setLastName(e.detail.value!);
                }}
                >
              </IonInput>
            </IonItem>

            <IonItem className="profile-settings-user-info-list-item">
              <IonLabel className="profile-settings-user-info-list-item-label">Email:</IonLabel>
              {/* TODO - Allow the user to change their email but require password re-auth*/}
              <IonLabel className="profile-settings-user-info-list-item-label-data">
                {(email ? email : "")}
              </IonLabel>
            </IonItem>

            <IonItem className="profile-settings-user-info-list-item">
              <IonLabel className="profile-settings-user-info-list-item-label">User ID:</IonLabel>
              <IonLabel className="profile-settings-user-info-list-item-label-data">
                {(uid ? uid : "")}
              </IonLabel>
            </IonItem>

          </IonList>

          <div className="profile-settings-save">
            <IonButton
              className="profile-settings-save-button"
              onClick={(e) => {

                var data = {
                  displayName: newDisplayName,
                  firstName: newFirstName,
                  lastName: newLastName,
                  phone: newPhone
                }

                writeUserInfo(userId, data, (result:any) => {
                  setShowToast_success_updated_info(true);
                });

              }}
            >
              SAVE
            </IonButton>
          </div>

          </div>
        </div>
        {/* <div className="profile-badges">
          <div className="profile-section-title">
          </div>
        </div> */}
        {/* <div className="profile-skills">
          <div className="profile-section-title">
          </div>
        </div> */}
      </div>
      <div className="profile-right-container">
        <div className="profile-about">
          <div className="profile-section-title">
            <p className="profile-section-title-text">
              IN-GAME NAMES
            </p>
          </div>
          <div className="profile-section-body profile-settings-section-body">
            <IonList className="profile-settings-user-info-list">

              <IonItem className="profile-settings-user-info-list-item">
                <IonLabel className="profile-settings-user-info-list-item-label">
                  <IonImg src="https://esporter.win/wp-content/uploads/2023/01/discord-mark-white.png" className="profile-settings-user-info-item-label-icon" />
                  Discord:
                </IonLabel>
                <IonInput
                  className="profile-settings-user-info-list-item-input"
                  value={(discordId ? discordId : "")}
                  onIonChange={(e) => {
                    var data = {
                      discordId: e.detail.value
                    };

                    writeUserIGN(userId, data, (result:any) => {
                      //setShowToast_success_updated_IGNs(true);
                    });
                  }}
                  >
                </IonInput>
              </IonItem>

              {(games.length ?
                games.map((game, index) => {
                  return(
                    <IonItem key={index} className="profile-settings-user-info-list-item">
                      <IonLabel className="profile-settings-user-info-list-item-label">
                        <IonImg src={game.iconURL} className="profile-settings-user-info-item-label-icon" />
                        {game.name}
                      </IonLabel>
                      <IonInput
                        className="profile-settings-user-info-list-item-input"
                        value={(user.igns ? (user.igns[game.name.replace(/\s/g, "")] ? user.igns[game.name.replace(/\s/g, "")] : "") : "")}
                        onIonChange={(e) => {
                          var data = {
                            game: game.name,
                            ign: e.detail.value
                          }

                          writeUserIGN(userId, data, (result:any) => {
                            //setShowToast_success_updated_IGNs(true);
                          });
                        }}
                        >
                      </IonInput>
                    </IonItem>
                  );
                })
                :
                ""
              )}

            </IonList>
          </div>
        </div>
        {/* <div className="profile-achievements">
        </div> */}
      </div>
      <IonToast
        isOpen={showToast_success_updated_IGNs}
        onDidDismiss={() => setShowToast_success_updated_IGNs(false)}
        message="In-Game Names Saved"
        duration={2000}
        color="primary"
        icon={checkmarkDoneCircleOutline}
        cssClass="success-toast"
      />
      <IonToast
        isOpen={showToast_success_updated_info}
        onDidDismiss={() => setShowToast_success_updated_info(false)}
        message="User Info Saved"
        duration={2000}
        color="primary"
        icon={checkmarkDoneCircleOutline}
        cssClass="success-toast"
      />
    </>
  );
}

const ProfileBody: React.FC<ProfileBodyProps> = ({currentPage}) => {

  useEffect(() => {
    //console.log("I've detected a change in the page: "+currentPage);
  }, [currentPage]);

  switch (currentPage){
    case "home":
      return (<ProfileBodyHome />);
    case "match-history":
      return (<ProfileBodyMatchHistory />);
    case "settings":
      return (<ProfileBodySettings />);
    default:
      return (<ProfileBodyHome />);
  }
};

export default ProfileBody;
