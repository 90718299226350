import {
  IonButtons,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
  IonSpinner,
  IonImg,
  IonToggle,
  IonButton,
  IonInput,
  IonItem,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardSubtitle,
  IonIcon,
  IonCardContent,
  IonList,
  IonLabel,
  IonToast,
  IonSelect,
  IonSelectOption,
  IonPopover,
  IonDatetime,
  IonRadioGroup,
  IonListHeader,
  IonRadio
} from '@ionic/react';

import './Splits.css';

import { useEffect, useState, useRef} from 'react';

import moment from 'moment-timezone';

import {
  pencilOutline,
  trashOutline,
  addCircleOutline,
  checkmarkDoneCircleOutline,
  warningOutline,
  archiveOutline,
  refreshCircleOutline,
  clipboard
} from 'ionicons/icons';

import {
  readSplits,
  readGame,
  readGames,
  writeGame,
  readSplitById,
  readUserProfileById,
  readUserMatchById,
  updateSplit,
  createSplit
} from '../../firebase';

import SplitCard from './SplitCard';


interface SplitsProps {
  setCurrentPage?: any;
  splitId?: any;
}

const SplitsHome: React.FC<SplitsProps> = ({setCurrentPage}) => {

  const [busy, setBusy] = useState(true);
  const [splits, setSplits] = useState<any[]>([]);

  useEffect(() => {
    /** Using isMounted to prevent React state updates on unmounted components based on this: https://stackoverflow.com/questions/53949393/cant-perform-a-react-state-update-on-an-unmounted-component **/
    let isMounted = true;

    document.title = "Admin | Splits";

    readSplits((s: any[]) => {

      var tempSplits:any[] = [];

      for (var split in s){
        if (s[split].status != "closed"){
          tempSplits.push(s[split]);
        }
      }

      tempSplits.sort((a,b)=>{
        if (!a.startDate){
          return -1;
        }
        if (!b.startDate){
          return -1;
        }
        if (moment(a.startDate).isBefore(moment(b.startDate))){
          return 1;
        }
        else {
          return -1;
        }
      })

      if (isMounted) setSplits(tempSplits);
      if (isMounted) setBusy(false);
    });
    return () => { isMounted = false };
  }, []);

  return (
    <>
      {
        (
          busy ?
          <IonSpinner />
          :
          <div className="container games-container">
            <IonButton expand="block" fill="outline" onClick={()=>{
              window.open("/Splits/New", "_self");
            }}><IonIcon slot="start" icon={addCircleOutline}/> New Split </IonButton>
            <div className="game-cards-container">
              {
                (splits.length ?

                  splits.map((split, index) => {
                      return (<SplitCard split={split} key={index}/>);
                    })
                :
                  <div>
                    <h1>No splits found.</h1>
                    <p>Please try again</p>
                  </div>
                )
              }
            </div>
          </div>

        )
      }
    </>
  );
};

const SplitsIndividualSplit: React.FC<SplitsProps> = ({setCurrentPage, splitId}) => {

  const [busy, setBusy] = useState(true);
  const [split, setSplit] = useState<any>({});
  const [game, setGame] = useState<any>({});

  const [isActive, setIsActive] = useState<boolean>(false);

  const [gameTypes, setGameTypes] = useState<any[]>([]);
  const [gameRounds, setGameRounds] = useState<any[]>([]);
  const [games, setGames] = useState<any[]>([]);

  const [players, setPlayers] = useState<any[]>([]);
  const [archivedPlayer, setArchivedPlayer] = useState<any>({});
  const [restoredPlayer, setRestoredPlayer] = useState<any>({});
  const [showArchivedMatches, setShowArchivedMatches] = useState<boolean>(false);

  const [matches, setMatches] = useState<any[]>([]);
  const [archivedMatch, setArchivedMatch] = useState<any>({});
  const [restoredMatch, setRestoredMatch] = useState<any>({});
  const [showArchivedPlayers, setShowArchivedPlayers] = useState<boolean>(false);

  const [showToast_save, setShowToast_save] = useState<boolean>(false);
  const [showToast_archivedPlayer, setShowToast_archivedPlayer] = useState<boolean>(false);
  const [showToast_undoArchivedPlayer, setShowToast_undoArchivedPlayer] = useState<boolean>(false);
  const [showToast_archivedMatch, setShowToast_archivedMatch] = useState<boolean>(false);
  const [showToast_undoArchivedMatch, setShowToast_undoArchivedMatch] = useState<boolean>(false);
  const [showToast_restoredPlayer, setShowToast_restoredPlayer] = useState<boolean>(false);
  const [showToast_undoRestoredPlayer, setShowToast_undoRestoredPlayer] = useState<boolean>(false);
  const [showToast_restoredMatch, setShowToast_restoredMatch] = useState<boolean>(false);
  const [showToast_undoRestoredMatch, setShowToast_undoRestoredMatch] = useState<boolean>(false);
  const [showToast_invalidSplit_noGame, setShowToast_invalidSplit_noGame] = useState<boolean>(false);
  const [showToast_copySuccess, setShowToast_copySuccess] = useState<boolean>(false);


  const [startDatePopoverIsOpen, setStartDatePopoverIsOpen] = useState<boolean>(false);

  const [archiveMatchPopoverIsOpen, setArchiveMatchPopoverIsOpen] = useState<boolean>(false);
  const [archivePlayerPopoverIsOpen, setArchivePlayerPopoverIsOpen] = useState<boolean>(false);
  const [restoreMatchPopoverIsOpen, setRestoreMatchPopoverIsOpen] = useState<boolean>(false);
  const [restorePlayerPopoverIsOpen, setRestorePlayerPopoverIsOpen] = useState<boolean>(false);

  useEffect(() => {
    /** Using isMounted to prevent React state updates on unmounted components based on this: https://stackoverflow.com/questions/53949393/cant-perform-a-react-state-update-on-an-unmounted-component **/
    let isMounted = true;

    document.title = "Admin | Split: "+splitId;

    readSplitById(splitId, (s: any) => {
      if (isMounted) setSplit(s);

      readGame(s.gameName, (g:any)=>{
        if (isMounted) setGame(g)

        if (isMounted) setBusy(false);
      });
    });

    return () => { isMounted = false };
  }, []);

  useEffect(()=> {
    let isMounted = true;

    readGames((g:any)=>{
      var tempGames:any[] = [];

      for (var game in g){
        tempGames.push(g[game]);
      }

      if (isMounted) setGames(tempGames);
    });

    for (var id in game.splitIds){
      if (game.splitIds[id]){
        if (isMounted) setIsActive(true);
        break;
      }
    }

    var tempGameTypes:any[] = [];

    for (var gameType in game.gameTypes){
      if (game.gameTypes[gameType]){
        tempGameTypes.push(gameType);
      }
    }

    if (isMounted) setGameTypes(tempGameTypes);

    var tempGameRounds:any[] = [];

    for (var gameRound in game.gameRounds){
      if (game.gameRounds[gameRound]){
        tempGameRounds.push(gameRound);
      }
    }

    if (isMounted) setGameRounds(tempGameRounds);

    return () => { isMounted = false };
  }, [game, split]);

  useEffect(()=> {
    let isMounted = true;

    var tempPlayers:any[] = [];

    for (var id in split.players){
      readUserProfileById(id, (p:any)=>{
        if (p){
          if (p.userId){
            if (split.players[p.userId] != showArchivedPlayers){
              tempPlayers.push(p);
            }
          }
        }
      });
    }

    tempPlayers.sort((a,b)=>{
      if (!a.createdAt){
        return 1;
      }
      if (!b.createdAt){
        return 1;
      }
      if (moment(parseInt(a.createdAt)).isAfter(moment(parseInt(b.createdAt)))){
        return -1;
      }
      else {
        return 1;
      }
    });

    if (isMounted) setPlayers(tempPlayers);

    var tempMatches:any[] = [];

    for (var id in split.matches){
      readUserMatchById(id, (m:any)=>{
        if (split.matches[m.id] != showArchivedMatches){
          tempMatches.push(m);
        }
      });
    }

    if (isMounted) setMatches(tempMatches);

    return () => { isMounted = false };
  }, [split, showArchivedPlayers, showArchivedMatches]);

  const datetime = useRef<null | HTMLIonDatetimeElement>(null);

  return (
    <>
    {
      (
        busy ?
        <IonSpinner />
        :
        <div className="container splits-container">
          <div className="split-details">
            <div className="split-details-logo">
              <IonImg src={game.logoURL} />
            </div>
            <div className="split-details-info">
              <div className="split-details-info-top">
                <div className="split-details-name-group">
                  <p className="split-details-name-label">Name</p>
                  <IonInput value={split.name}  className="split-details-name-input" onIonChange={(e)=>{
                    var tempSplit = JSON.parse(JSON.stringify(split));
                    tempSplit.name = e.detail.value;

                    setSplit(tempSplit);

                  }}></IonInput>
                </div>
                <IonButton onClick={()=> {
                  if (split.gameName){
                    updateSplit(split, ()=>{
                      writeGame(game, ()=>{
                        setShowToast_save(true);
                      })
                    });
                  }
                  else {
                    setShowToast_invalidSplit_noGame(true);
                  }
                }}> Save </IonButton>
              </div>
              <div className="split-details-info-active">
                <IonToggle checked={isActive} onIonChange={(e)=>{

                  var tempIsActive = !isActive;
                  var tempGame = JSON.parse(JSON.stringify(game));

                  setIsActive(tempIsActive);

                  if (tempIsActive){
                    for (var id in game.splitIds){
                      tempGame.splitIds[id] = false;
                    }

                    tempGame.splitIds[split.splitId] = true;

                  }
                  else {
                    tempGame.splitIds[split.splitId] = false;
                  }

                  setGame(tempGame);

                }}></IonToggle>
                <p>{(isActive ? "Active": "Inactive")}</p>
              </div>
            </div>
            <div className="split-details-options">
              <div className="split-details-option-container">
                <p className="split-details-label">Game</p>
                <IonSelect className="split-details-input" value={game.name} onIonChange={(e)=>{

                  console.log(e);
                  var tempSplit = JSON.parse(JSON.stringify(split));
                  tempSplit.gameName = e.detail.value;

                  setSplit(tempSplit);

                  for(var i=0; i<games.length; i++){
                    if (games[i].name == e.detail.value){
                      setGame(games[i]);
                    }
                  }
                }}>

                {
                  games.map((g, index)=>{
                    if (g) {
                      return (
                        <IonSelectOption key={index+"game"} value={g.name}> {g.name}</IonSelectOption>
                      );
                    }
                  })
                }

                </IonSelect>
              </div>
              <div className="split-details-option-container">
                <p className="split-details-label">Split ID</p>
                <IonInput className="split-details-input" value={split.splitId} disabled={true}></IonInput>
              </div>
            </div>
            <div className="split-details-options">
              <div className="split-details-option-container">
                <p className="split-details-label">Rounds</p>
                <IonSelect className="split-details-input" value={split.gameRounds} onIonChange={(e)=>{
                  var tempSplit = JSON.parse(JSON.stringify(split));
                  tempSplit.gameRounds = e.detail.value;

                  setSplit(tempSplit);
                }}>

                {
                  gameRounds.map((r, index)=>{
                      return (
                        <IonSelectOption key={index+"game_round"} value={r}>{r}</IonSelectOption>
                      );

                  })
                }

                </IonSelect>
              </div>
              <div className="split-details-option-container">
                <p className="split-details-label">Game Type</p>
                <IonSelect className="split-details-input" value={split.gameType} onIonChange={(e)=>{
                  var tempSplit = JSON.parse(JSON.stringify(split));
                  tempSplit.gameType = e.detail.value;

                  setSplit(tempSplit);
                }}>

                {
                  gameTypes.map((t, index)=>{
                      return (
                        <IonSelectOption key={index+"game_type"} value={t}>{t}</IonSelectOption>
                      );

                  })
                }

                </IonSelect>
              </div>
            </div>
            <div className="split-details-options">
              <div className="split-details-option-container">
                <p className="split-details-label">Coach IGN</p>
                <IonInput className="split-details-input" value={split.coachIgn} onIonChange={(e)=>{
                  var tempSplit = JSON.parse(JSON.stringify(split));
                  tempSplit.coachIgn = e.detail.value;

                  setSplit(tempSplit);
                }}>
                </IonInput>
              </div>
              <div className="split-details-input-long">
                <p className="split-details-label">Coach ID</p>
                <IonInput className="split-details-input" value={split.coachId} onIonChange={(e)=>{
                  var tempSplit = JSON.parse(JSON.stringify(split));
                  tempSplit.coachId = e.detail.value;

                  setSplit(tempSplit);
                }}>
                </IonInput>
              </div>
            </div>
            <div className="split-details-options">
              <div className="split-details-option-container">
                <p className="split-details-label">Class Day</p>
                <IonSelect className="split-details-input" value={split.classDay} onIonChange={(e)=>{
                  var tempSplit = JSON.parse(JSON.stringify(split));
                  tempSplit.classDay = e.detail.value;

                  setSplit(tempSplit);
                }}>

                  <IonSelectOption value="Sundays">Sundays</IonSelectOption>
                  <IonSelectOption value="Mondays">Mondays</IonSelectOption>
                  <IonSelectOption value="Tuesdays">Tuesdays</IonSelectOption>
                  <IonSelectOption value="Wednesdays">Wednesdays</IonSelectOption>
                  <IonSelectOption value="Thursdays">Thursdays</IonSelectOption>
                  <IonSelectOption value="Fridays">Fridays</IonSelectOption>
                  <IonSelectOption value="Saturdays">Saturdays</IonSelectOption>

                </IonSelect>
              </div>
              <div className="split-details-option-container">
                <p className="split-details-label">Class Time (Pacific)</p>
                <IonSelect className="split-details-input" value={split.classTime} onIonChange={(e)=>{
                  var tempSplit = JSON.parse(JSON.stringify(split));
                  tempSplit.classTime = e.detail.value;

                  setSplit(tempSplit);
                }}>

                  <IonSelectOption value="0900">9:00am</IonSelectOption>
                  <IonSelectOption value="0930">9:30am</IonSelectOption>
                  <IonSelectOption value="1000">10:00am</IonSelectOption>
                  <IonSelectOption value="1030">10:30am</IonSelectOption>
                  <IonSelectOption value="1100">11:00am</IonSelectOption>
                  <IonSelectOption value="1130">11:30am</IonSelectOption>
                  <IonSelectOption value="1200">12:00pm</IonSelectOption>
                  <IonSelectOption value="1230">12:30pm</IonSelectOption>
                  <IonSelectOption value="1300">1:00pm</IonSelectOption>
                  <IonSelectOption value="1330">1:30pm</IonSelectOption>
                  <IonSelectOption value="1400">2:00pm</IonSelectOption>
                  <IonSelectOption value="1430">2:30pm</IonSelectOption>
                  <IonSelectOption value="1500">3:00pm</IonSelectOption>
                  <IonSelectOption value="1530">3:30pm</IonSelectOption>
                  <IonSelectOption value="1600">4:00pm</IonSelectOption>
                  <IonSelectOption value="1630">4:30pm</IonSelectOption>
                  <IonSelectOption value="1700">5:00pm</IonSelectOption>
                  <IonSelectOption value="1730">5:30pm</IonSelectOption>
                  <IonSelectOption value="1800">6:00pm</IonSelectOption>
                  <IonSelectOption value="1830">6:30pm</IonSelectOption>
                  <IonSelectOption value="1900">7:00pm</IonSelectOption>
                  <IonSelectOption value="1930">7:30pm</IonSelectOption>
                  <IonSelectOption value="2000">8:00pm</IonSelectOption>
                  <IonSelectOption value="2030">8:30pm</IonSelectOption>
                  <IonSelectOption value="2100">9:00pm</IonSelectOption>
                  <IonSelectOption value="2130">9:30pm</IonSelectOption>
                  <IonSelectOption value="2200">10:00pm</IonSelectOption>
                  <IonSelectOption value="2230">10:30pm</IonSelectOption>
                  <IonSelectOption value="2300">11:00pm</IonSelectOption>
                  <IonSelectOption value="2330">11:30pm</IonSelectOption>
                  <IonSelectOption value="0000">12:00am</IonSelectOption>
                  <IonSelectOption value="0030">12:30am</IonSelectOption>
                  <IonSelectOption value="0100">1:00am</IonSelectOption>
                  <IonSelectOption value="0130">1:30am</IonSelectOption>
                  <IonSelectOption value="0200">2:00am</IonSelectOption>
                  <IonSelectOption value="0230">2:30am</IonSelectOption>
                  <IonSelectOption value="0300">3:00am</IonSelectOption>
                  <IonSelectOption value="0330">3:30am</IonSelectOption>
                  <IonSelectOption value="0400">4:00am</IonSelectOption>
                  <IonSelectOption value="0430">4:30am</IonSelectOption>
                  <IonSelectOption value="0500">5:00am</IonSelectOption>
                  <IonSelectOption value="0530">5:30am</IonSelectOption>
                  <IonSelectOption value="0600">6:00am</IonSelectOption>
                  <IonSelectOption value="0630">6:30am</IonSelectOption>
                  <IonSelectOption value="0700">7:00am</IonSelectOption>
                  <IonSelectOption value="0730">7:30am</IonSelectOption>
                  <IonSelectOption value="0800">8:00am</IonSelectOption>
                  <IonSelectOption value="0830">8:30am</IonSelectOption>

                </IonSelect>
              </div>
            </div>
            <div className="split-details-options">
              <div className="split-details-option-container">
                <p className="split-details-label">Start Date</p>
                <IonInput className="split-details-input" value={split.startDate} onClick={()=>{
                  setStartDatePopoverIsOpen(true);
                }}>
                </IonInput>
              </div>
            </div>
          </div>
          <div className="split-options">
            <IonCard className="split-options-card split-options-types">
              <IonCardHeader className="split-options-card-title">
                <IonCardTitle>
                  Players
                </IonCardTitle>
                <IonButton fill="outline" color="medium" id="players-archive-button">
                  <IonIcon icon={archiveOutline} className=""/>
                </IonButton>
                <IonPopover trigger="players-archive-button" triggerAction="click">
                  <IonContent class="ion-padding">
                  <IonRadioGroup
                    allowEmptySelection={true}
                    value={showArchivedPlayers}
                    onIonChange={(e) => {
                      setShowArchivedPlayers(e.detail.value);
                    }}
                  >
                    <IonListHeader>
                      <IonLabel>
                        Archive...
                      </IonLabel>
                    </IonListHeader>
                    <IonItem>
                      <IonLabel>Show Archived</IonLabel>
                      <IonRadio value={true} />
                    </IonItem>
                    <IonItem>
                      <IonLabel>Hide Archived</IonLabel>
                      <IonRadio value={false}/>
                    </IonItem>
                  </IonRadioGroup>
                </IonContent>
                </IonPopover>
              </IonCardHeader>
              <IonCardContent>
                <IonList>
                  {
                    (players.length ?

                      players.map((player, index) => {
                          return (
                            <IonItem key={index+"player"}>
                              <IonLabel>{player.displayName}</IonLabel>
                              <IonLabel className="split-options-player-id" onClick={()=>{
                                navigator.clipboard.writeText(player.userId);
                                setShowToast_copySuccess(true);
                              }}>ID: {player.userId}</IonLabel>
                              {
                                (
                                  showArchivedPlayers ?
                                  <IonButton className="split-options-details-top-buttons-restore" fill="clear" onClick={()=>{
                                    setRestorePlayerPopoverIsOpen(true);
                                    setRestoredPlayer(player);
                                  }}> <IonIcon icon={refreshCircleOutline}/></IonButton>
                                  :
                                  <IonButton slot="end" className="split-options-splits-delete-button" fill="clear" onClick={()=>{
                                    setArchivedPlayer(player);
                                    setArchivePlayerPopoverIsOpen(true);
                                  }}> <IonIcon icon={archiveOutline}/></IonButton>
                                )
                              }
                            </IonItem>
                          );
                        })
                    :
                      <div>
                        <h1>No players found.</h1>
                      </div>
                    )
                  }

                </IonList>
              </IonCardContent>
            </IonCard>
            <IonCard className="split-options-card split-options-rounds">
              <IonCardHeader className="split-options-card-title">
                <IonCardTitle>
                  Matches
                </IonCardTitle>
                <IonButton fill="outline" color="medium" id="matches-archive-button">
                  <IonIcon icon={archiveOutline} className=""/>
                </IonButton>
                <IonPopover trigger="matches-archive-button" triggerAction="click">
                  <IonContent class="ion-padding">
                  <IonRadioGroup
                    allowEmptySelection={true}
                    value={showArchivedMatches}
                    onIonChange={(e) => {
                      setShowArchivedMatches(e.detail.value);
                    }}
                  >
                    <IonListHeader>
                      <IonLabel>
                        Archive...
                      </IonLabel>
                    </IonListHeader>
                    <IonItem>
                      <IonLabel>Show Archived</IonLabel>
                      <IonRadio value={true} />
                    </IonItem>
                    <IonItem>
                      <IonLabel>Hide Archived</IonLabel>
                      <IonRadio value={false}/>
                    </IonItem>
                  </IonRadioGroup>
                </IonContent>
                </IonPopover>
              </IonCardHeader>
              <IonCardContent>
                <IonList>
                  {
                    (matches.length ?

                      matches.map((match, index) => {
                          return (
                            <IonItem key={index}>
                              <IonLabel><a href={"/Matches/"+match.id}>{match.id}</a></IonLabel>
                              {
                                (
                                  showArchivedMatches ?
                                  <IonButton className="split-options-details-top-buttons-restore" fill="clear" onClick={()=>{
                                    setRestoreMatchPopoverIsOpen(true);
                                    setRestoredMatch(match);
                                  }}> <IonIcon icon={refreshCircleOutline}/></IonButton>
                                  :
                                  <IonButton slot="end" className="split-options-splits-delete-button" fill="clear" onClick={()=>{
                                    setArchivedMatch(match);
                                    setArchiveMatchPopoverIsOpen(true);
                                  }}>
                                    <IonIcon icon={archiveOutline}/>
                                  </IonButton>
                                )
                              }
                            </IonItem>
                          );
                        })
                    :
                      <div>
                        <h1>No matches found.</h1>
                      </div>
                    )
                  }
                </IonList>
              </IonCardContent>
            </IonCard>
          </div>
        </div>
      )
    }
    <IonToast
      isOpen={showToast_save}
      onDidDismiss={() => setShowToast_save(false)}
      message="Split Saved"
      duration={2000}
      color="primary"
      icon={checkmarkDoneCircleOutline}
      cssClass="success-toast"
    />
    <IonToast
      isOpen={showToast_invalidSplit_noGame}
      onDidDismiss={() => setShowToast_invalidSplit_noGame(false)}
      message="Selecting a Game is required."
      duration={10000}
      color="danger"
      icon={warningOutline}
      cssClass="success-toast"
    />
    <IonToast
      isOpen={showToast_copySuccess}
      onDidDismiss={() => setShowToast_copySuccess(false)}
      message="Copied"
      duration={1000}
      color="medium"
      icon={clipboard}
      cssClass="copy-toast"
    />
    <IonToast
      isOpen={showToast_archivedPlayer}
      onDidDismiss={() => setShowToast_archivedPlayer(false)}
      message="Player Archived"
      duration={10000}
      color="primary"
      icon={checkmarkDoneCircleOutline}
      cssClass="success-toast"
      buttons={[
       {
         text: 'Undo',
         role: 'info',
         handler: () => {
           var tempSplit = JSON.parse(JSON.stringify(split));
           tempSplit.players[archivedPlayer.userId] = true;
           setSplit(tempSplit);

           setShowToast_undoArchivedPlayer(true);
         },
       }
     ]}
    />
    <IonToast
      isOpen={showToast_undoArchivedPlayer}
      onDidDismiss={() => setShowToast_undoArchivedPlayer(false)}
      message="Player Restored"
      duration={2000}
      color="primary"
      icon={checkmarkDoneCircleOutline}
      cssClass="success-toast"
    />
    <IonToast
      isOpen={showToast_restoredPlayer}
      onDidDismiss={() => setShowToast_restoredPlayer(false)}
      message="Player Restored"
      duration={10000}
      color="primary"
      icon={checkmarkDoneCircleOutline}
      cssClass="success-toast"
      buttons={[
       {
         text: 'Undo',
         role: 'info',
         handler: () => {
           var tempSplit = JSON.parse(JSON.stringify(split));
           tempSplit.players[restoredPlayer.userId] = false;
           setSplit(tempSplit);

           setShowToast_undoRestoredPlayer(true);
         },
       }
     ]}
    />
    <IonToast
      isOpen={showToast_undoRestoredPlayer}
      onDidDismiss={() => setShowToast_undoRestoredPlayer(false)}
      message="Player Archived"
      duration={2000}
      color="primary"
      icon={checkmarkDoneCircleOutline}
      cssClass="success-toast"
    />
    <IonToast
      isOpen={showToast_archivedMatch}
      onDidDismiss={() => setShowToast_archivedMatch(false)}
      message="Match Archived"
      duration={10000}
      color="primary"
      icon={checkmarkDoneCircleOutline}
      cssClass="success-toast"
      buttons={[
       {
         text: 'Undo',
         role: 'info',
         handler: () => {
           var tempSplit = JSON.parse(JSON.stringify(split));
           tempSplit.matches[archivedMatch.id] = true;
           setSplit(tempSplit);

           setShowToast_undoArchivedMatch(true);
         },
       }
     ]}
    />
    <IonToast
      isOpen={showToast_undoArchivedMatch}
      onDidDismiss={() => setShowToast_undoArchivedMatch(false)}
      message="Match Restored"
      duration={2000}
      color="primary"
      icon={checkmarkDoneCircleOutline}
      cssClass="success-toast"
    />
    <IonToast
      isOpen={showToast_restoredMatch}
      onDidDismiss={() => setShowToast_restoredMatch(false)}
      message="Match Restored"
      duration={10000}
      color="primary"
      icon={checkmarkDoneCircleOutline}
      cssClass="success-toast"
      buttons={[
       {
         text: 'Undo',
         role: 'info',
         handler: () => {
           var tempSplit = JSON.parse(JSON.stringify(split));
           tempSplit.matches[restoredMatch.id] = false;
           setSplit(tempSplit);

           setShowToast_undoRestoredMatch(true);
         },
       }
     ]}
    />
    <IonToast
      isOpen={showToast_undoRestoredMatch}
      onDidDismiss={() => setShowToast_undoRestoredMatch(false)}
      message="Match Archived"
      duration={2000}
      color="primary"
      icon={checkmarkDoneCircleOutline}
      cssClass="success-toast"
    />
    {/* TODO - Include toast for a failed save */}
    <IonPopover className="split-start-date-time-picker" isOpen={startDatePopoverIsOpen} onDidDismiss={ () => {setStartDatePopoverIsOpen(false);}} >
      <IonDatetime
        showDefaultButtons={true}
        ref={datetime}
        onIonChange={(e) => {
          var startTime: string = moment(e.detail.value).tz("US/Pacific").format();
          var tempSplit = JSON.parse(JSON.stringify(split));
          tempSplit.startDate = startTime;
          setSplit(tempSplit);
        }}
      >
      </IonDatetime>
    </IonPopover>
    <IonPopover className="archive-user-popover" isOpen={archiveMatchPopoverIsOpen} onDidDismiss={ () => {setArchiveMatchPopoverIsOpen(false);}} >
      <div className="archive-user-popover-container">
        <h3> Archive {archivedMatch.id}</h3>
        <IonButtons className="archive-user-buttons">
          <IonButton color="medium" onClick={()=>{
            setArchiveMatchPopoverIsOpen(false);
          }}>Cancel</IonButton>
          <IonButton color="danger" onClick={()=>{
            setShowToast_archivedMatch(true);

            var tempSplit = JSON.parse(JSON.stringify(split));
            tempSplit.matches[archivedMatch.id] = false;
            setSplit(tempSplit);

            setArchiveMatchPopoverIsOpen(false);
          }}>Archive</IonButton>
        </IonButtons>
      </div>
    </IonPopover>
    <IonPopover className="archive-user-popover" isOpen={archivePlayerPopoverIsOpen} onDidDismiss={ () => {setArchivePlayerPopoverIsOpen(false);}} >
      <div className="archive-user-popover-container">
        <h3> Archive {archivedPlayer.displayName}</h3>
        <IonButtons className="archive-user-buttons">
          <IonButton color="medium" onClick={()=>{
            setArchivePlayerPopoverIsOpen(false);
          }}>Cancel</IonButton>
          <IonButton color="danger" onClick={()=>{
            setShowToast_archivedPlayer(true);

            var tempSplit = JSON.parse(JSON.stringify(split));
            tempSplit.players[archivedPlayer.userId] = false;
            setSplit(tempSplit);

            setArchivePlayerPopoverIsOpen(false);
          }}>Archive</IonButton>
        </IonButtons>
      </div>
    </IonPopover>
    <IonPopover className="archive-user-popover" isOpen={restoreMatchPopoverIsOpen} onDidDismiss={ () => {setRestoreMatchPopoverIsOpen(false);}} >
      <div className="archive-user-popover-container">
        <h3> Restore {restoredMatch.id}</h3>
        <IonButtons className="archive-user-buttons">
          <IonButton color="medium" onClick={()=>{
            setRestoreMatchPopoverIsOpen(false);
          }}>Cancel</IonButton>
          <IonButton color="primary" onClick={()=>{
            setShowToast_restoredMatch(true);

            var tempSplit = JSON.parse(JSON.stringify(split));
            tempSplit.matches[restoredMatch.id] = true;
            setSplit(tempSplit);

            setRestoreMatchPopoverIsOpen(false);
          }}>Restore</IonButton>
        </IonButtons>
      </div>
    </IonPopover>
    <IonPopover className="archive-user-popover" isOpen={restorePlayerPopoverIsOpen} onDidDismiss={ () => {setRestorePlayerPopoverIsOpen(false);}} >
      <div className="archive-user-popover-container">
        <h3> Restore {restoredPlayer.displayName}</h3>
        <IonButtons className="archive-user-buttons">
          <IonButton color="medium" onClick={()=>{
            setRestorePlayerPopoverIsOpen(false);
          }}>Cancel</IonButton>
          <IonButton color="primary" onClick={()=>{
            setShowToast_restoredPlayer(true);

            var tempSplit = JSON.parse(JSON.stringify(split));
            tempSplit.players[restoredPlayer.userId] = true;
            setSplit(tempSplit);

            setRestorePlayerPopoverIsOpen(false);
          }}>Restore</IonButton>
        </IonButtons>
      </div>
    </IonPopover>
    </>
  );
};

const SplitsNewSplit: React.FC<SplitsProps> = ({setCurrentPage}) => {

  const [busy, setBusy] = useState(true);
  const [split, setSplit] = useState<any>({});
  const [game, setGame] = useState<any>({});

  const [isActive, setIsActive] = useState<boolean>(false);

  const [gameTypes, setGameTypes] = useState<any[]>([]);
  const [gameRounds, setGameRounds] = useState<any[]>([]);
  const [games, setGames] = useState<any[]>([]);

  const [players, setPlayers] = useState<any[]>([]);
  const [archivedPlayer, setArchivedPlayer] = useState<any>({});
  const [restoredPlayer, setRestoredPlayer] = useState<any>({});
  const [showArchivedMatches, setShowArchivedMatches] = useState<boolean>(false);

  const [matches, setMatches] = useState<any[]>([]);
  const [archivedMatch, setArchivedMatch] = useState<any>({});
  const [restoredMatch, setRestoredMatch] = useState<any>({});
  const [showArchivedPlayers, setShowArchivedPlayers] = useState<boolean>(false);

  const [showToast_save, setShowToast_save] = useState<boolean>(false);
  const [showToast_archivedPlayer, setShowToast_archivedPlayer] = useState<boolean>(false);
  const [showToast_undoArchivedPlayer, setShowToast_undoArchivedPlayer] = useState<boolean>(false);
  const [showToast_archivedMatch, setShowToast_archivedMatch] = useState<boolean>(false);
  const [showToast_undoArchivedMatch, setShowToast_undoArchivedMatch] = useState<boolean>(false);
  const [showToast_restoredPlayer, setShowToast_restoredPlayer] = useState<boolean>(false);
  const [showToast_undoRestoredPlayer, setShowToast_undoRestoredPlayer] = useState<boolean>(false);
  const [showToast_restoredMatch, setShowToast_restoredMatch] = useState<boolean>(false);
  const [showToast_undoRestoredMatch, setShowToast_undoRestoredMatch] = useState<boolean>(false);

  const [showToast_invalidSplit_noGame, setShowToast_invalidSplit_noGame] = useState<boolean>(false);

  const [startDatePopoverIsOpen, setStartDatePopoverIsOpen] = useState<boolean>(false);

  const [archiveMatchPopoverIsOpen, setArchiveMatchPopoverIsOpen] = useState<boolean>(false);
  const [archivePlayerPopoverIsOpen, setArchivePlayerPopoverIsOpen] = useState<boolean>(false);
  const [restoreMatchPopoverIsOpen, setRestoreMatchPopoverIsOpen] = useState<boolean>(false);
  const [restorePlayerPopoverIsOpen, setRestorePlayerPopoverIsOpen] = useState<boolean>(false);

  useEffect(() => {
    /** Using isMounted to prevent React state updates on unmounted components based on this: https://stackoverflow.com/questions/53949393/cant-perform-a-react-state-update-on-an-unmounted-component **/
    let isMounted = true;

    document.title = "Admin | New Split";

    if (isMounted) setSplit({
      players: {

      },
      matches: {

      },
      classDay: '',
      classTime: '',
      coachId: '',
      coachIgn: '',
      gameName: '',
      gameRounds: '',
      gameType: '',
      name: '',
      officeHoursDay: '',
      officeHoursTime: '',
      shopURL: '',
      splitId: '',
      startDate: '',
      status: '',
      timezone: '',
      tournamentDateTime: '',
      tournamentDay: '',
      tournamentTimeET: '',
      tournamentTimePT: '',
      wc_product_id: ''
    });

    if (isMounted) setBusy(false);

    return () => { isMounted = false };
  }, []);

  useEffect(()=> {
    let isMounted = true;

    readGames((g:any)=>{
      var tempGames:any[] = [];

      for (var game in g){
        tempGames.push(g[game]);
      }

      if (isMounted) setGames(tempGames);
    });

    var tempGameTypes:any[] = [];

    for (var gameType in game.gameTypes){
      if (game.gameTypes[gameType]){
        tempGameTypes.push(gameType);
      }
    }

    if (isMounted) setGameTypes(tempGameTypes);

    var tempGameRounds:any[] = [];

    for (var gameRound in game.gameRounds){
      if (game.gameRounds[gameRound]){
        tempGameRounds.push(gameRound);
      }
    }

    if (isMounted) setGameRounds(tempGameRounds);

    return () => { isMounted = false };
  }, [game, split]);

  const datetime = useRef<null | HTMLIonDatetimeElement>(null);

  return (
    <>
    {
      (
        busy ?
        <IonSpinner />
        :
        <div className="container splits-container">
          <div className="split-details">
            <div className="split-details-logo">
              {(game.logoURL ? <IonImg src={game.logoURL} /> : <IonImg src="https://esporter.win/wp-content/uploads/2023/01/placeholder.png" />)}
            </div>
            <div className="split-details-info">
              <div className="split-details-info-top">
                <div className="split-details-name-group">
                  <p className="split-details-name-label">Name</p>
                  <IonInput value={split.name}  className="split-details-name-input" onIonChange={(e)=>{
                    var tempSplit = JSON.parse(JSON.stringify(split));
                    tempSplit.name = e.detail.value;

                    setSplit(tempSplit);

                  }}></IonInput>
                </div>
                <IonButton onClick={()=> {
                  if (split.gameName){
                    createSplit(split, (s:any)=>{
                      if (isActive){
                        var tempGame = JSON.parse(JSON.stringify(game));
                        for (var id in tempGame.splitIds){
                          tempGame.splitIds[id] = false;
                        }
                        tempGame.splitIds[s.splitId] = true;

                        writeGame(tempGame, ()=>{
                          setShowToast_save(true);
                        });
                      }
                      else {
                        var tempGame = JSON.parse(JSON.stringify(game));
                        tempGame.splitIds[s.splitId] = false;

                        writeGame(tempGame, ()=>{
                          setShowToast_save(true);
                        });
                      }
                    });
                  }
                  else {
                    setShowToast_invalidSplit_noGame(true);
                  }
                }}> Save </IonButton>
              </div>
              <div className="split-details-info-active">
                <IonToggle checked={isActive} onIonChange={(e)=>{
                  var tempIsActive = !isActive;
                  setIsActive(tempIsActive);
                }}></IonToggle>
                <p>{(isActive ? "Make Active": "Make Inactive")}</p>
              </div>
            </div>
            <div className="split-details-options">
              <div className="split-details-option-container">
                <p className="split-details-label">Game</p>
                <IonSelect className="split-details-input" value={game.name} onIonChange={(e)=>{

                  console.log(e);
                  var tempSplit = JSON.parse(JSON.stringify(split));
                  tempSplit.gameName = e.detail.value;

                  setSplit(tempSplit);

                  for(var i=0; i<games.length; i++){
                    if (games[i].name == e.detail.value){
                      setGame(games[i]);
                    }
                  }
                }}>

                {
                  games.map((g, index)=>{
                    if (g) {
                      return (
                        <IonSelectOption key={index+"game"} value={g.name}> {g.name}</IonSelectOption>
                      );
                    }
                  })
                }

                </IonSelect>
              </div>
            </div>
            <div className="split-details-options">
              <div className="split-details-option-container">
                <p className="split-details-label">Rounds</p>
                <IonSelect className="split-details-input" value={split.gameRounds} onIonChange={(e)=>{
                  var tempSplit = JSON.parse(JSON.stringify(split));
                  tempSplit.gameRounds = e.detail.value;
                  setSplit(tempSplit);
                }}>

                {
                  gameRounds.map((r, index)=>{
                      return (
                        <IonSelectOption key={index+"game_round"} value={r}>{r}</IonSelectOption>
                      );

                  })
                }

                </IonSelect>
              </div>
              <div className="split-details-option-container">
                <p className="split-details-label">Game Type</p>
                <IonSelect className="split-details-input" value={split.gameType} onIonChange={(e)=>{
                  var tempSplit = JSON.parse(JSON.stringify(split));
                  tempSplit.gameType = e.detail.value;

                  setSplit(tempSplit);
                }}>

                {
                  gameTypes.map((t, index)=>{
                      return (
                        <IonSelectOption key={index+"game_type"} value={t}>{t}</IonSelectOption>
                      );

                  })
                }

                </IonSelect>
              </div>
            </div>
            <div className="split-details-options">
              <div className="split-details-option-container">
                <p className="split-details-label">Coach IGN</p>
                <IonInput className="split-details-input" value={split.coachIgn} onIonChange={(e)=>{
                  var tempSplit = JSON.parse(JSON.stringify(split));
                  tempSplit.coachIgn = e.detail.value;

                  setSplit(tempSplit);
                }}>
                </IonInput>
              </div>
              <div className="split-details-input-long">
                <p className="split-details-label">Coach ID</p>
                <IonInput className="split-details-input" value={split.coachId} onIonChange={(e)=>{
                  var tempSplit = JSON.parse(JSON.stringify(split));
                  tempSplit.coachId = e.detail.value;

                  setSplit(tempSplit);
                }}>
                </IonInput>
              </div>
            </div>
            <div className="split-details-options">
              <div className="split-details-option-container">
                <p className="split-details-label">Class Day</p>
                <IonSelect className="split-details-input" value={split.classDay} onIonChange={(e)=>{
                  var tempSplit = JSON.parse(JSON.stringify(split));
                  tempSplit.classDay = e.detail.value;

                  setSplit(tempSplit);
                }}>

                  <IonSelectOption value="Sundays">Sundays</IonSelectOption>
                  <IonSelectOption value="Mondays">Mondays</IonSelectOption>
                  <IonSelectOption value="Tuesdays">Tuesdays</IonSelectOption>
                  <IonSelectOption value="Wednesdays">Wednesdays</IonSelectOption>
                  <IonSelectOption value="Thursdays">Thursdays</IonSelectOption>
                  <IonSelectOption value="Fridays">Fridays</IonSelectOption>
                  <IonSelectOption value="Saturdays">Saturdays</IonSelectOption>

                </IonSelect>
              </div>
              <div className="split-details-option-container">
                <p className="split-details-label">Class Time (Pacific)</p>
                <IonSelect className="split-details-input" value={split.classTime} onIonChange={(e)=>{
                  var tempSplit = JSON.parse(JSON.stringify(split));
                  tempSplit.classTime = e.detail.value;

                  setSplit(tempSplit);
                }}>

                  <IonSelectOption value="0900">9:00am</IonSelectOption>
                  <IonSelectOption value="0930">9:30am</IonSelectOption>
                  <IonSelectOption value="1000">10:00am</IonSelectOption>
                  <IonSelectOption value="1030">10:30am</IonSelectOption>
                  <IonSelectOption value="1100">11:00am</IonSelectOption>
                  <IonSelectOption value="1130">11:30am</IonSelectOption>
                  <IonSelectOption value="1200">12:00pm</IonSelectOption>
                  <IonSelectOption value="1230">12:30pm</IonSelectOption>
                  <IonSelectOption value="1300">1:00pm</IonSelectOption>
                  <IonSelectOption value="1330">1:30pm</IonSelectOption>
                  <IonSelectOption value="1400">2:00pm</IonSelectOption>
                  <IonSelectOption value="1430">2:30pm</IonSelectOption>
                  <IonSelectOption value="1500">3:00pm</IonSelectOption>
                  <IonSelectOption value="1530">3:30pm</IonSelectOption>
                  <IonSelectOption value="1600">4:00pm</IonSelectOption>
                  <IonSelectOption value="1630">4:30pm</IonSelectOption>
                  <IonSelectOption value="1700">5:00pm</IonSelectOption>
                  <IonSelectOption value="1730">5:30pm</IonSelectOption>
                  <IonSelectOption value="1800">6:00pm</IonSelectOption>
                  <IonSelectOption value="1830">6:30pm</IonSelectOption>
                  <IonSelectOption value="1900">7:00pm</IonSelectOption>
                  <IonSelectOption value="1930">7:30pm</IonSelectOption>
                  <IonSelectOption value="2000">8:00pm</IonSelectOption>
                  <IonSelectOption value="2030">8:30pm</IonSelectOption>
                  <IonSelectOption value="2100">9:00pm</IonSelectOption>
                  <IonSelectOption value="2130">9:30pm</IonSelectOption>
                  <IonSelectOption value="2200">10:00pm</IonSelectOption>
                  <IonSelectOption value="2230">10:30pm</IonSelectOption>
                  <IonSelectOption value="2300">11:00pm</IonSelectOption>
                  <IonSelectOption value="2330">11:30pm</IonSelectOption>
                  <IonSelectOption value="0000">12:00am</IonSelectOption>
                  <IonSelectOption value="0030">12:30am</IonSelectOption>
                  <IonSelectOption value="0100">1:00am</IonSelectOption>
                  <IonSelectOption value="0130">1:30am</IonSelectOption>
                  <IonSelectOption value="0200">2:00am</IonSelectOption>
                  <IonSelectOption value="0230">2:30am</IonSelectOption>
                  <IonSelectOption value="0300">3:00am</IonSelectOption>
                  <IonSelectOption value="0330">3:30am</IonSelectOption>
                  <IonSelectOption value="0400">4:00am</IonSelectOption>
                  <IonSelectOption value="0430">4:30am</IonSelectOption>
                  <IonSelectOption value="0500">5:00am</IonSelectOption>
                  <IonSelectOption value="0530">5:30am</IonSelectOption>
                  <IonSelectOption value="0600">6:00am</IonSelectOption>
                  <IonSelectOption value="0630">6:30am</IonSelectOption>
                  <IonSelectOption value="0700">7:00am</IonSelectOption>
                  <IonSelectOption value="0730">7:30am</IonSelectOption>
                  <IonSelectOption value="0800">8:00am</IonSelectOption>
                  <IonSelectOption value="0830">8:30am</IonSelectOption>

                </IonSelect>
              </div>
            </div>
            <div className="split-details-options">
              <div className="split-details-option-container">
                <p className="split-details-label">Start Date</p>
                <IonInput className="split-details-input" value={split.startDate} onClick={()=>{
                  setStartDatePopoverIsOpen(true);
                }}>
                </IonInput>
              </div>
            </div>
          </div>
        </div>
      )
    }
    <IonToast
      isOpen={showToast_save}
      onDidDismiss={() => setShowToast_save(false)}
      message="Game Saved"
      duration={2000}
      color="primary"
      icon={checkmarkDoneCircleOutline}
      cssClass="success-toast"
    />
    <IonToast
      isOpen={showToast_invalidSplit_noGame}
      onDidDismiss={() => setShowToast_invalidSplit_noGame(false)}
      message="Selecting a Game is required."
      duration={10000}
      color="danger"
      icon={warningOutline}
      cssClass="success-toast"
    />
    <IonPopover className="split-start-date-time-picker" isOpen={startDatePopoverIsOpen} onDidDismiss={ () => {setStartDatePopoverIsOpen(false);}} >
      <IonDatetime
        showDefaultButtons={true}
        ref={datetime}
        onIonChange={(e) => {
          var startTime: string = moment(e.detail.value).tz("US/Pacific").format();
          var tempSplit = JSON.parse(JSON.stringify(split));
          tempSplit.startDate = startTime;
          setSplit(tempSplit);
        }}
      >
      </IonDatetime>
    </IonPopover>
    </>
  );
};

const Splits: React.FC<SplitsProps> = ({splitId}) => {

  const [currentPage, setCurrentPage] = useState(()=>{
    if(splitId){
      if (splitId == "New"){
        return "new";
      }
      else {
        return "game";
      }
    }
    else {
      return "home";
    }
  });


  switch (currentPage){
    case "home":
      return (<SplitsHome setCurrentPage={setCurrentPage}/>);
    case "game":
      return(<SplitsIndividualSplit setCurrentPage={setCurrentPage} splitId={splitId}/>);
    case "new":
      return(<SplitsNewSplit setCurrentPage={setCurrentPage}/>);
    default:
      return (<SplitsHome setCurrentPage={setCurrentPage}/>);
  }

  return (
    <div className="container games-container">
      <strong>Splits</strong>
      <p>Coming Soon...</p>
    </div>
  );
};

export default Splits;
