import {
  IonButtons,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonToggle,
  IonButton,
  IonIcon
 } from '@ionic/react';
import './GameCard.css';

import { useEffect, useState, useRef} from 'react';

import {
  writeGame
} from '../../firebase';

import {
  pencilOutline,
  trashOutline
} from 'ionicons/icons';

interface GameCardProps {
  game: any;
}

const GameCard: React.FC<GameCardProps> = ({game}) => {

  const [isActive, setIsActive] = useState(false);
  const [activeSplitId, setActiveSplitId] = useState('');
  const [gameTypes, setGameTypes] = useState<any[]>([]);
  const [gameRounds, setGameRounds] = useState<any[]>([]);
  const [splits, setSplits] = useState<any[]>([]);

  useEffect(() => {
    /** Using isMounted to prevent React state updates on unmounted components based on this: https://stackoverflow.com/questions/53949393/cant-perform-a-react-state-update-on-an-unmounted-component **/
    let isMounted = true;

    for (var split in game.splitIds){
      if (game.splitIds[split]){
        if (isMounted) setIsActive(true);
        if (isMounted) setActiveSplitId(split);
        break;
      }
    }

    var tempGameTypes:any[] = [];

    for (var gameType in game.gameTypes){
      if (game.gameTypes[gameType]){
        tempGameTypes.push(gameType);
      }
    }

    if (isMounted) setGameTypes(tempGameTypes);

    var tempGameRounds:any[] = [];

    for (var gameRound in game.gameRounds){
      if (game.gameRounds[gameRound]){
        tempGameRounds.push(gameRound);
      }
    }

    if (isMounted) setGameRounds(tempGameRounds);

    var tempSplits:any[] = [];

    for (var split in game.splitIds){
      tempSplits.push({
        splitId: split,
        active: game.splitIds[split]
      });
    }

    if (isMounted) setSplits(tempSplits);

    return () => { isMounted = false };
  }, []);



  return (
    <IonCard className="game-card-container">
      <img alt={game.name} src={game.logoURL} className="game-card-image"/>
      <IonCardHeader>
        <IonCardTitle>{game.name}</IonCardTitle>
          <IonCardSubtitle className="game-card-subtitle">{(isActive ? "Active" : "Inactive")}
            {/*
              <IonToggle checked={isActive} onIonChange={(e)=>{

                var tempIsActive = !isActive;
                var tempGame = JSON.parse(JSON.stringify(game));

                setIsActive(tempIsActive);

                if (tempIsActive){
                  var maxSplitId = 0;
                  for (var id in game.splitIds){
                    if (parseInt(id) > maxSplitId){
                      maxSplitId = parseInt(id);
                    }
                  }
                  setActiveSplitId(""+maxSplitId);
                  tempGame.splitIds[maxSplitId] = true;
                }
                else {
                  tempGame.splitIds[activeSplitId] = false;
                  setActiveSplitId("");
                }

                game = tempGame;

                writeGame(game, ()=>{});

                // TODO - Write Game

              }}></IonToggle>

            */}
          </IonCardSubtitle>
        <IonCardSubtitle className="game-card-subtitle-split-id"> {(isActive ? "Split #: "+activeSplitId : '')}</IonCardSubtitle>
      </IonCardHeader>
      <div className="game-card-content-container">
        <div className="game-card-content">
            {/*gameTypes.map((type, index) => {return (<p>{type}</p>);})*/}
            <IonButton fill="clear" className="game-card-button" onClick={(e)=>{
              window.open("/Games/"+game.name, "_self");
            }}>
              <IonIcon slot="start" icon={pencilOutline} className="game-card-icon"/>
            </IonButton>
            <IonButton fill="clear" className="game-card-button">
              <IonIcon slot="start" icon={trashOutline} className="game-card-icon"/>
            </IonButton>
        </div>
      </div>

    </IonCard>
  );
};

export default GameCard;
