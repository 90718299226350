import './NotificationsContainer.css';

import {
  IonList,
  IonIcon,
  IonLabel,
  IonImg,
  IonItem,
  IonButton,
  IonText,
  IonTitle
} from '@ionic/react';

import {
  closeOutline,
  calendarClear,
  notificationsOutline
} from 'ionicons/icons';

import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useState, useEffect} from 'react';
import { readUserNotifications, updateUserNotification } from '../firebase';


interface NotificationsProps {
  notifications_list?: any[];
}

const NotificationsContainer: React.FC<NotificationsProps> = ({ notifications_list }) => {

  const userId = useSelector((state: any) => state.userData.uid);
  const location = useLocation();

  const [notifications, setNotifications] = useState<any[]>([])

  //const { name } = useParams<{ name: string; }>();


  useEffect(() => {
    readUserNotifications(userId, (n: any[]) => {
         setNotifications(n);
         //console.log("Notifications set to..."); //TODO - Remove debugging
         //console.log(notifications); //TODO - Remove Debugging
    });
  }, []);

  return (
    <IonList className="notifications-container" lines="full">
      {notifications.map((notification, index) => {
        var notification_icon: string = '';

        //Switch over icon names from the DB to set them to their proper icon
        switch (notification.icon){
          case "calendarClear":
            notification_icon = calendarClear;
            break;
          default:
            notification_icon = notificationsOutline;
            break;
        }

        return (
          <IonItem key={index} className="notification-item" href={(notification.content.url ? notification.content.url : "")}>
            <IonIcon icon={notification_icon} className="notification-item-icon"/>
            <div>
              <IonLabel className="notification-item-label">{notification.content.title}</IonLabel>
              <IonText className="notification-item-description">{notification.content.description}</IonText>
            </div>
            <IonButton className="notification-item-button" fill="clear" size="small" onClick={(event) =>{
              event.nativeEvent.preventDefault();
              updateUserNotification(userId, notification, "archived");
              setNotifications(notifications.filter(e => e.id !== notification.id ));
            }}>
              <IonIcon icon={closeOutline}/>
            </IonButton>
          </IonItem>
        );
      })}
    </IonList>
  );
};

export default NotificationsContainer;
