import './ExploreContainer.css';

import Profile from './containers/Profile';
import Availability from './containers/Availability';
import Matchmaking from './containers/Matchmaking';
import Achievements from './containers/Achievements';
import Tournaments from './containers/Tournaments';
import Matches from './containers/Matches';
import Events from './containers/Events';
import Recordings from './containers/Recordings';
import Users from './containers/Users';
import Splits from './containers/Splits';
import Games from './containers/Games';
import Leaderboards from './containers/Leaderboards';
import LeaderboardsAdmin from './containers/LeaderboardsAdmin';
import Videos from './containers/Videos';

interface ContainerProps {
  name: string;
  id?: string;
}

const ExploreContainer: React.FC<ContainerProps> = ({ name, id }) => {
  switch (name) {
    case 'Profile':
      return (
        <Profile />
      );

    case 'Events':
      return (
        <Events />
      );

    case 'Availability':
      return (
        <Availability />
      );

    case 'Matches':
      return (
        <Matches matchId={id}/>
      );

    case 'Matchmaking':
      return (
        <Matchmaking />
      );

    case 'Recordings':
      return (
        <Recordings recordingId={id} />
      );

    case 'Achievements':
      return (
        <Achievements />
      );

    case 'Tournaments':
      return (
        <Tournaments />
      );

    case 'Users':
      return (
        <Users />
      );

    case 'Splits':
      return (
        <Splits splitId={id}/>
      );

    case 'Games':
      return (
        <Games gameId={id}/>
      );

    case 'Leaderboards':
      return (
        <Leaderboards leaderboardId={id} />
      );

    case 'LeaderboardsAdmin':
      return (
        <LeaderboardsAdmin />
      );

    case 'Videos':
      return (
        <Videos videoId={id}/>
      );

    default:
      return (
        <Profile />
      );
    }
};

export default ExploreContainer;
