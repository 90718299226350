import {
  IonButtons,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
  IonButton,
  IonItem,
  IonLabel,
  IonInput,
  IonToast,
  IonIcon,
  IonLoading,
  IonImg
} from '@ionic/react';

import {
  alertCircleSharp,
  checkmarkDoneCircleOutline
} from 'ionicons/icons';

import './Reset.css';
import { useState } from 'react';
import { setUserState } from '../redux/actions';
import { sendPasswordResetEmail } from 'firebase/auth';
import { useDispatch } from 'react-redux';

import { app, auth } from '../firebase';

const Reset: React.FC = () => {

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showToast_passwordReset, setShowToast_passwordReset] = useState(false);
  const [showToast_error, setShowToast_error] = useState(false);

  const [busy, setBusy] = useState(false);

  const dispatch = useDispatch();

  function resetPassword(){
    setBusy(true);

    sendPasswordResetEmail(auth, username).then(() => {
      setShowToast_passwordReset(true);
    })
    .catch((error: any) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      setShowToast_error(true);
    });

    setBusy(false);
  }

  return (
    <IonPage>
      <IonContent fullscreen className="login-background">
        <div className="login-background-overlay">
          <div className="login-area">
            <IonImg className="esporter-logo-login" src="https://esporter.win/wp-content/uploads/2021/12/logo-nobackground-cropped1.svg" />
            <IonItem>
              <IonInput
                placeholder="Email"
                onIonChange={(event: any) => setUsername(event.target.value)}
              />
            </IonItem>
            <IonButton color="primary" expand="full" onClick={resetPassword}>
              Reset Password
            </IonButton>
            <p className="sign-up-text">Remember your Password? <a href="/Login">Log In.</a></p>
            <p className="sign-up-text">Don't have an account? <a href="/Signup">Sign Up.</a></p>
          </div>
        </div>

        <IonToast
          isOpen={showToast_error}
          onDidDismiss={() => setShowToast_error(false)}
          message="An account with that email does not exist. Contact support@esporter.win if you need help."
          duration={2000}
          color="danger"
          icon={alertCircleSharp}
        />

        <IonToast
          isOpen={showToast_passwordReset}
          onDidDismiss={() => setShowToast_passwordReset(false)}
          message="You will receive an email to reset your password."
          duration={2000}
          color="primary"
          icon={checkmarkDoneCircleOutline}
        />

        <IonLoading message="Please wait..." duration={0} isOpen={busy} />
      </IonContent>
    </IonPage>
  );
};

export default Reset;
