import {
  IonButtons,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
  IonSpinner,
  IonImg,
  IonToggle,
  IonButton,
  IonInput,
  IonItem,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardSubtitle,
  IonIcon,
  IonCardContent,
  IonList,
  IonLabel,
  IonToast
} from '@ionic/react';

import './Games.css';

import { useEffect, useState, useRef} from 'react';

import {
  pencilOutline,
  trashOutline,
  addCircleOutline,
  checkmarkDoneCircleOutline,
  warningOutline
} from 'ionicons/icons';

import {
  readGames,
  readGame,
  writeGame,
  readSplitById
} from '../../firebase';

import GameCard from './GameCard';


interface GamesProps {
  setCurrentPage?: any;
  gameId?: any;
}

const GamesHome: React.FC<GamesProps> = ({setCurrentPage}) => {

  const [busy, setBusy] = useState(true);
  const [games, setGames] = useState<any[]>([]);

  useEffect(() => {
    /** Using isMounted to prevent React state updates on unmounted components based on this: https://stackoverflow.com/questions/53949393/cant-perform-a-react-state-update-on-an-unmounted-component **/
    let isMounted = true;

    document.title = "Admin | Games";

    readGames((g: any[]) => {

      var tempGames:any[] = [];

      for (var game in g){
        tempGames.push(g[game]);
      }

      tempGames.sort((a, b)=>{
        var isActive: boolean = false;
        for (var id in a.splitIds){
          if (a.splitIds[id]){
            isActive = true;
          }
        }
        if (isActive){
          return -1;
        }
        else {
          return 1;
        }
      })

      if (isMounted) setGames(tempGames);
      if (isMounted) setBusy(false);
    });
    return () => { isMounted = false };
  }, []);

  return (
    <>
      {
        (
          busy ?
          <IonSpinner />
          :
          <div className="container games-container">
            <IonButton expand="block" fill="outline" onClick={()=>{
              window.open("/Games/New", "_self");
            }}><IonIcon slot="start" icon={addCircleOutline}/> New Game </IonButton>
            <div className="game-cards-container">
              {
                (games.length ?

                  games.map((game, index) => {
                      return (<GameCard game={game} key={index}/>);
                    })
                :
                  <div>
                    <h1>No games found.</h1>
                    <p>Please try again</p>
                  </div>
                )
              }
            </div>
          </div>

        )
      }
    </>
  );
};

const GamesIndividualGame: React.FC<GamesProps> = ({setCurrentPage, gameId}) => {

  const [busy, setBusy] = useState(true);
  const [game, setGame] = useState<any>({});
  const [isActive, setIsActive] = useState<boolean>(false);
  const [activeSplitId, setActiveSplitId] = useState<any>('');
  const [gameTypes, setGameTypes] = useState<any[]>([]);
  const [gameRounds, setGameRounds] = useState<any[]>([]);
  const [splits, setSplits] = useState<any[]>([]);

  const [newGameType, setNewGameType] = useState<any>('');
  const [newGameRound, setNewGameRound] = useState<any>('');
  const [newSplitId, setNewSplitId] = useState<any>('');

  const [showToast_save, setShowToast_save] = useState<boolean>(false);


  useEffect(() => {
    /** Using isMounted to prevent React state updates on unmounted components based on this: https://stackoverflow.com/questions/53949393/cant-perform-a-react-state-update-on-an-unmounted-component **/
    let isMounted = true;

    document.title = "Admin | "+gameId;

    readGame(gameId, (g: any) => {
      if (isMounted) setGame(g);

      var tempSplits:any[] = [];

      for (var id in g.splitIds){
        readSplitById(id, (s:any)=>{
          if (s){
            s.active = g.splitIds[id];
            tempSplits.push(s);
          }
        });
      }

      tempSplits.sort((a,b)=>{
        if (parseInt(a) > parseInt(b)){
          return -1;
        }
        else {
          return 1;
        }
      });

      if (isMounted) setSplits(tempSplits);
      if (isMounted) setBusy(false);
    });
    return () => { isMounted = false };
  }, []);

  useEffect(()=> {
    let isMounted = true;

    for (var id in game.splitIds){
      if (game.splitIds[id]){
        if (isMounted) setIsActive(true);
        if (isMounted) setActiveSplitId(id);
        break;
      }
    }

    var tempGameTypes:any[] = [];

    for (var gameType in game.gameTypes){
      if (game.gameTypes[gameType]){
        tempGameTypes.push(gameType);
      }
    }

    if (isMounted) setGameTypes(tempGameTypes);

    var tempGameRounds:any[] = [];

    for (var gameRound in game.gameRounds){
      if (game.gameRounds[gameRound]){
        tempGameRounds.push(gameRound);
      }
    }

    if (isMounted) setGameRounds(tempGameRounds);

    /*var tempSplits:any[] = [];

    for (var split in game.splitIds){
      tempSplits.push({
        splitId: split,
        active: game.splitIds[split]
      });
    }

    tempSplits.sort((a,b)=>{
      if (parseInt(a) > parseInt(b)){
        return -1;
      }
      else {
        return 1;
      }
    });

    if (isMounted) setSplits(tempSplits); */

    return () => { isMounted = false };
  }, [game])

  return (
    <>
    {
      (
        busy ?
        <IonSpinner />
        :
        <div className="container games-container">
          <div className="game-details">
            <div className="game-details-logo">
              <IonImg src={game.logoURL} />
            </div>
            <div className="game-details-info">
              <div className="game-details-info-top">
                <h1>{game.name}</h1>
                <IonButton onClick={()=> {
                  writeGame(game, ()=>{
                    setShowToast_save(true);
                  });
                }}> Save </IonButton>
              </div>
              <div className="game-details-info-active">
                <IonToggle checked={isActive} onIonChange={(e)=>{

                  var tempIsActive = !isActive;
                  var tempGame = JSON.parse(JSON.stringify(game));

                  setIsActive(tempIsActive);

                  if (tempIsActive){
                    var maxSplitId = 0;
                    for (var id in game.splitIds){
                      if (parseInt(id) > maxSplitId){
                        maxSplitId = parseInt(id);
                      }
                    }
                    setActiveSplitId(""+maxSplitId);
                    tempGame.splitIds[maxSplitId] = true;
                  }
                  else {
                    tempGame.splitIds[activeSplitId] = false;
                    setActiveSplitId("");
                  }

                  setGame(tempGame);

                }}></IonToggle>
                <p>{(isActive ? "Active Split #: "+activeSplitId : "Inactive")}</p>
              </div>
            </div>
            <div className="game-details-logo-icon-options">
              <div className="game-details-logo-url">
                <p className="game-details-logo-url-label">Logo URL</p>
                <IonInput className="game-details-logo-url-input" value={game.logoURL} onIonChange={(e)=>{

                  var tempGame = JSON.parse(JSON.stringify(game));
                  tempGame.logoURL = e.detail.value;
                  setGame(tempGame);

                }}></IonInput>
              </div>
              <IonImg className="game-details-icon" src={game.iconURL} />
              <div className="game-details-icon-url">
                <p className="game-details-icon-url-label">Icon URL</p>
                <IonInput className="game-details-icon-url-input" value={game.iconURL} onIonChange={(e)=>{

                  var tempGame = JSON.parse(JSON.stringify(game));
                  tempGame.iconURL = e.detail.value;
                  setGame(tempGame);

                }}></IonInput>
              </div>
            </div>
          </div>
          <div className="game-options">
            <IonCard className="game-options-card game-options-types">
              <IonCardHeader>
                <IonCardTitle>Game Types</IonCardTitle>
                <IonCardSubtitle>Ex. Ranked, Limited, 2v2, etc.</IonCardSubtitle>
              </IonCardHeader>
              <IonCardContent>
                <IonList>
                  {
                    (gameTypes.length ?

                      gameTypes.map((gameType, index) => {
                          return (
                            <IonItem key={index}>
                              <IonLabel>{gameType}</IonLabel>
                              <IonButton slot="end" className="game-options-splits-delete-button" fill="clear" onClick={()=>{
                                var tempGame = JSON.parse(JSON.stringify(game));
                                tempGame.gameTypes[gameType] = false;
                                setGame(tempGame);
                              }}>
                                <IonIcon icon={trashOutline}/>
                              </IonButton>
                            </IonItem>
                          );
                        })
                    :
                      <div>
                        <h1>No game types found.</h1>
                        <p>Add one:</p>
                      </div>
                    )
                  }

                  <IonItem lines="none">
                    <IonButton slot="start" fill="clear" onClick={()=>{
                      if (newGameType){
                        var tempGame = JSON.parse(JSON.stringify(game));
                        tempGame.gameTypes[newGameType] = true;
                        setGame(tempGame);
                        setNewGameType('');
                      }
                    }}>
                      <IonIcon icon={addCircleOutline} className="game-options-splits-add-button"/>
                    </IonButton>
                    <IonInput placeholder="Type Name" value={newGameType} onIonChange={(e)=>{
                      setNewGameType(e.detail.value);
                    }}> </IonInput>
                  </IonItem>

                </IonList>
              </IonCardContent>
            </IonCard>
            <IonCard className="game-options-card game-options-rounds">
              <IonCardHeader>
                <IonCardTitle>Game Rounds</IonCardTitle>
                <IonCardSubtitle>Ex. Best of 3, Best of 1, etc.</IonCardSubtitle>
              </IonCardHeader>
              <IonCardContent>
                <IonList>
                  {
                    (gameRounds.length ?

                      gameRounds.map((gameRound, index) => {
                          return (
                            <IonItem key={index}>
                              <IonLabel>{gameRound}</IonLabel>
                              <IonButton slot="end" className="game-options-splits-delete-button" fill="clear" onClick={()=>{
                                var tempGame = JSON.parse(JSON.stringify(game));
                                tempGame.gameRounds[gameRound] = false;
                                setGame(tempGame);
                              }}>
                                <IonIcon icon={trashOutline}/>
                              </IonButton>
                            </IonItem>
                          );
                        })
                    :
                      <div>
                        <h1>No game rounds found.</h1>
                        <p>Add one:</p>
                      </div>
                    )
                  }

                  <IonItem lines="none">
                    <IonButton slot="start" fill="clear" onClick={()=>{
                      if (newGameRound){
                        var tempGame = JSON.parse(JSON.stringify(game));
                        tempGame.gameRounds[newGameRound] = true;
                        setGame(tempGame);
                        setNewGameRound('');
                      }
                    }}>
                      <IonIcon icon={addCircleOutline} className="game-options-splits-add-button"/>
                    </IonButton>
                    <IonInput placeholder="Round Name" value={newGameRound} onIonChange={(e)=>{
                      setNewGameRound(e.detail.value);
                    }}> </IonInput>

                  </IonItem>
                </IonList>
              </IonCardContent>
            </IonCard>
            <IonCard className="game-options-card game-options-splits">
              <IonCardHeader>
                <IonCardTitle>Splits</IonCardTitle>
                <IonCardSubtitle>There can only be one active Split.</IonCardSubtitle>
              </IonCardHeader>
              <IonCardContent>
                <IonList>
                  {
                    (splits.length ?

                      splits.map((split, index) => {
                          return (
                            <IonItem key={index}>
                              <IonLabel className="game-options-split-name" text-wrap={true}>{split.name}</IonLabel>
                              <IonLabel slot="end" >{(split.active ? "Active" : <IonButton className="game-options-splits-active-button" fill="outline" onClick={()=>{
                                var tempGame = JSON.parse(JSON.stringify(game));
                                for (var id in tempGame.splitIds){
                                  tempGame.splitIds[id] = false;
                                }
                                tempGame.splitIds[split.splitId] = true;
                                setGame(tempGame);
                              }}>Make Active</IonButton>)}</IonLabel>
                            </IonItem>
                          );
                        })
                    :
                      <div>
                        <h1>No game types found.</h1>
                        <p>Add one:</p>
                      </div>
                    )
                  }
                </IonList>
              </IonCardContent>
            </IonCard>
          </div>
        </div>
      )
    }
    <IonToast
      isOpen={showToast_save}
      onDidDismiss={() => setShowToast_save(false)}
      message="Game Saved"
      duration={2000}
      color="primary"
      icon={checkmarkDoneCircleOutline}
      cssClass="success-toast"
    />
    </>
  );
};

const GamesNewGame: React.FC<GamesProps> = ({setCurrentPage}) => {

  const [busy, setBusy] = useState(true);
  const [game, setGame] = useState<any>({});
  const [isActive, setIsActive] = useState<boolean>(false);
  const [activeSplitId, setActiveSplitId] = useState<any>('');
  const [gameTypes, setGameTypes] = useState<any[]>([]);
  const [gameRounds, setGameRounds] = useState<any[]>([]);
  const [splits, setSplits] = useState<any[]>([]);

  const [newGameType, setNewGameType] = useState<any>('');
  const [newGameRound, setNewGameRound] = useState<any>('');
  const [newSplitId, setNewSplitId] = useState<any>('');

  const [showToast_save, setShowToast_save] = useState<boolean>(false);
  const [showToast_invalidSave, setShowToast_invalidSave] = useState<boolean>(false);


  useEffect(() => {
    /** Using isMounted to prevent React state updates on unmounted components based on this: https://stackoverflow.com/questions/53949393/cant-perform-a-react-state-update-on-an-unmounted-component **/
    let isMounted = true;

    document.title = "Admin | New Game";

    if (isMounted) setGame({
      gameRounds: {

      },
      gameTypes: {

      },
      iconURL: '',
      logoURL: '',
      name: '',
      splitIds: {

      }
    });

    if (isMounted) setBusy(false);

    return () => { isMounted = false };
  }, []);

  useEffect(()=> {
    let isMounted = true;

    for (var id in game.splitIds){
      if (game.splitIds[id]){
        if (isMounted) setIsActive(true);
        if (isMounted) setActiveSplitId(id);
        break;
      }
    }

    var tempGameTypes:any[] = [];

    for (var gameType in game.gameTypes){
      if (game.gameTypes[gameType]){
        tempGameTypes.push(gameType);
      }
    }

    if (isMounted) setGameTypes(tempGameTypes);

    var tempGameRounds:any[] = [];

    for (var gameRound in game.gameRounds){
      if (game.gameRounds[gameRound]){
        tempGameRounds.push(gameRound);
      }
    }

    if (isMounted) setGameRounds(tempGameRounds);

    var tempSplits:any[] = [];

    for (var split in game.splitIds){
      tempSplits.push({
        splitId: split,
        active: game.splitIds[split]
      });
    }

    tempSplits.sort((a,b)=>{
      if (parseInt(a) > parseInt(b)){
        return -1;
      }
      else {
        return 1;
      }
    });

    if (isMounted) setSplits(tempSplits);

    return () => { isMounted = false };
  }, [game])

  return (
    <>
    {
      (
        busy ?
        <IonSpinner />
        :
        <div className="container games-container">
          <div className="game-details">
            <div className="game-details-logo">
              {(game.logoURL ? <IonImg src={game.logoURL} /> : <IonImg src="https://esporter.win/wp-content/uploads/2023/01/placeholder.png" />)}
            </div>
            <div className="game-details-info">
              <div className="game-details-info-top">
                <div className="game-details-name">
                  <p className="game-details-name-label">Game Name</p>
                  <IonInput className="game-details-name-input" value={game.name} onIonChange={(e)=>{

                    var tempGame = JSON.parse(JSON.stringify(game));
                    tempGame.name = e.detail.value;
                    setGame(tempGame);

                  }}></IonInput>
                </div>
                <IonButton onClick={()=> {
                  if (game.name){
                    if (game.name.match(/^[\sA-Za-z0-9_-]*$/g)){
                      writeGame(game, ()=>{
                        setShowToast_save(true);
                      });
                    }
                    else {
                      setShowToast_invalidSave(true);
                    }
                  }
                  else {
                    setShowToast_invalidSave(true);
                  }

                }}> Save </IonButton>
              </div>
              <div className="game-details-info-active">
                <IonToggle checked={isActive} onIonChange={(e)=>{

                  var tempIsActive = !isActive;
                  var tempGame = JSON.parse(JSON.stringify(game));

                  setIsActive(tempIsActive);

                  if (tempIsActive){
                    if (Object.keys(game.splitIds).length){
                      var maxSplitId = 0;
                      for (var id in game.splitIds){
                        if (parseInt(id) > maxSplitId){
                          maxSplitId = parseInt(id);
                        }
                      }
                      setActiveSplitId(""+maxSplitId);
                      tempGame.splitIds[maxSplitId] = true;
                    }
                  }
                  else {
                    if (Object.keys(game.splitIds).length){
                      tempGame.splitIds[activeSplitId] = false;
                      setActiveSplitId("");
                    }
                  }

                  setGame(tempGame);

                }}></IonToggle>
                <p>{(isActive ? "Active Split #: "+activeSplitId : "Inactive")}</p>
              </div>
            </div>
            <div className="game-details-logo-icon-options">
              <div className="game-details-logo-url">
                <p className="game-details-logo-url-label">Logo URL</p>
                <IonInput className="game-details-logo-url-input" value={game.logoURL} onIonChange={(e)=>{

                  var tempGame = JSON.parse(JSON.stringify(game));
                  tempGame.logoURL = e.detail.value;
                  setGame(tempGame);

                }}></IonInput>
              </div>
              {(game.iconURL ? <IonImg className="game-details-icon" src={game.iconURL} /> : <IonImg className="game-details-icon" src="https://esporter.win/wp-content/uploads/2023/01/placeholder.png" />)}
              <div className="game-details-icon-url">
                <p className="game-details-icon-url-label">Icon URL</p>
                <IonInput className="game-details-icon-url-input" value={game.iconURL} onIonChange={(e)=>{

                  var tempGame = JSON.parse(JSON.stringify(game));
                  tempGame.iconURL = e.detail.value;
                  setGame(tempGame);

                }}></IonInput>
              </div>
            </div>
          </div>
          <div className="game-options">
            <IonCard className="game-options-card game-options-types">
              <IonCardHeader>
                <IonCardTitle>Game Types</IonCardTitle>
                <IonCardSubtitle>Ex. Ranked, Limited, 2v2, etc.</IonCardSubtitle>
              </IonCardHeader>
              <IonCardContent>
                <IonList>
                  {
                    (gameTypes.length ?

                      gameTypes.map((gameType, index) => {
                          return (
                            <IonItem key={index}>
                              <IonLabel>{gameType}</IonLabel>
                              <IonButton slot="end" className="game-options-splits-delete-button" fill="clear" onClick={()=>{
                                var tempGame = JSON.parse(JSON.stringify(game));
                                tempGame.gameTypes[gameType] = false;
                                setGame(tempGame);
                              }}>
                                <IonIcon icon={trashOutline}/>
                              </IonButton>
                            </IonItem>
                          );
                        })
                    :
                      <div>
                        <h1>No game types found.</h1>
                        <p>Add one:</p>
                      </div>
                    )
                  }

                  <IonItem lines="none">
                    <IonButton slot="start" fill="clear" onClick={()=>{
                      if (newGameType){
                        var tempGame = JSON.parse(JSON.stringify(game));
                        tempGame.gameTypes[newGameType] = true;
                        setGame(tempGame);
                        setNewGameType('');
                      }
                    }}>
                      <IonIcon icon={addCircleOutline} className="game-options-splits-add-button"/>
                    </IonButton>
                    <IonInput placeholder="Type Name" value={newGameType} onIonChange={(e)=>{
                      setNewGameType(e.detail.value);
                    }}> </IonInput>
                  </IonItem>

                </IonList>
              </IonCardContent>
            </IonCard>
            <IonCard className="game-options-card game-options-rounds">
              <IonCardHeader>
                <IonCardTitle>Game Rounds</IonCardTitle>
                <IonCardSubtitle>Ex. Best of 3, Best of 1, etc.</IonCardSubtitle>
              </IonCardHeader>
              <IonCardContent>
                <IonList>
                  {
                    (gameRounds.length ?

                      gameRounds.map((gameRound, index) => {
                          return (
                            <IonItem key={index}>
                              <IonLabel>{gameRound}</IonLabel>
                              <IonButton slot="end" className="game-options-splits-delete-button" fill="clear" onClick={()=>{
                                var tempGame = JSON.parse(JSON.stringify(game));
                                tempGame.gameRounds[gameRound] = false;
                                setGame(tempGame);
                              }}>
                                <IonIcon icon={trashOutline}/>
                              </IonButton>
                            </IonItem>
                          );
                        })
                    :
                      <div>
                        <h1>No game rounds found.</h1>
                        <p>Add one:</p>
                      </div>
                    )
                  }

                  <IonItem lines="none">
                    <IonButton slot="start" fill="clear" onClick={()=>{
                      if (newGameRound){
                        var tempGame = JSON.parse(JSON.stringify(game));
                        tempGame.gameRounds[newGameRound] = true;
                        setGame(tempGame);
                        setNewGameRound('');
                      }
                    }}>
                      <IonIcon icon={addCircleOutline} className="game-options-splits-add-button"/>
                    </IonButton>
                    <IonInput placeholder="Round Name" value={newGameRound} onIonChange={(e)=>{
                      setNewGameRound(e.detail.value);
                    }}> </IonInput>

                  </IonItem>
                </IonList>
              </IonCardContent>
            </IonCard>
            <IonCard className="game-options-card game-options-splits">
              <IonCardHeader>
                <IonCardTitle>Split IDs</IonCardTitle>
                <IonCardSubtitle>Ex. 7997</IonCardSubtitle>
              </IonCardHeader>
              <IonCardContent>
                <IonList>
                  {
                    (splits.length ?

                      splits.map((split, index) => {
                          return (
                            <IonItem key={index}>
                              <IonLabel>{split.splitId}</IonLabel>
                              <IonLabel slot="end" >{(split.active ? "Active" : <IonButton className="game-options-splits-active-button" fill="outline" onClick={()=>{
                                var tempGame = JSON.parse(JSON.stringify(game));
                                for (var id in tempGame.splitIds){
                                  tempGame.splitIds[id] = false;
                                }
                                tempGame.splitIds[split.splitId] = true;
                                setGame(tempGame);
                              }}>Make Active</IonButton>)}</IonLabel>
                            </IonItem>
                          );
                        })
                    :
                      <div>
                        <h1>No Splits found.</h1>
                        <p>Add one:</p>
                      </div>
                    )
                  }

                  <IonItem lines="none">
                    <IonButton slot="start" fill="clear" onClick={()=>{
                      if (newSplitId){
                        var tempGame = JSON.parse(JSON.stringify(game));
                        for (var id in tempGame.splitIds){
                          tempGame.splitIds[id] = false;
                        }
                        tempGame.splitIds[newSplitId] = true;
                        setGame(tempGame);
                        setNewSplitId('');
                      }
                    }}>
                      <IonIcon icon={addCircleOutline} className="game-options-splits-add-button"/>
                    </IonButton>
                    <IonInput placeholder="Split ID" value={newSplitId} onIonChange={(e)=>{
                      setNewSplitId(e.detail.value);
                    }}> </IonInput>

                  </IonItem>
                </IonList>
              </IonCardContent>
            </IonCard>
          </div>
        </div>
      )
    }
    <IonToast
      isOpen={showToast_save}
      onDidDismiss={() => setShowToast_save(false)}
      message="Game Saved"
      duration={2000}
      color="primary"
      icon={checkmarkDoneCircleOutline}
      cssClass="success-toast"
    />
    <IonToast
      isOpen={showToast_invalidSave}
      onDidDismiss={() => setShowToast_invalidSave(false)}
      message="Game Name is required and must only include spaces, numbers, and letters"
      duration={10000}
      color="danger"
      icon={warningOutline}
      cssClass="success-toast"
    />
    </>
  );
};

const Games: React.FC<GamesProps> = ({gameId}) => {

  const [currentPage, setCurrentPage] = useState(()=>{
    if(gameId){
      if (gameId == "New"){
        return "new";
      }
      else {
        return "game";
      }
    }
    else {
      return "home";
    }
  });


  switch (currentPage){
    case "home":
      return (<GamesHome setCurrentPage={setCurrentPage}/>);
    case "game":
      return(<GamesIndividualGame setCurrentPage={setCurrentPage} gameId={gameId}/>);
    case "new":
      return(<GamesNewGame setCurrentPage={setCurrentPage}/>);
    default:
      return (<GamesHome setCurrentPage={setCurrentPage}/>);
  }

  return (
    <div className="container games-container">
      <strong>Games</strong>
      <p>Coming Soon...</p>
    </div>
  );
};

export default Games;
