import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { app, auth, user } from '../../firebase';
import { useSelector } from 'react-redux';

import { IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar, IonImg, IonButton, IonToast } from '@ionic/react';
import './ProfileIGNBadge.css';

import {
  readUserProfileById
} from '../../firebase';

import {
  clipboard
} from 'ionicons/icons';

import ProfileBody from './ProfileBody';


interface ProfileIGNBadgeProps {
  game?: any;
  ign?: any;
  iconURL?: any;
}

const ProfileIGNBadge: React.FC<ProfileIGNBadgeProps> = ({game, ign, iconURL}) => {

  const userId = useSelector((state: any) => state.userData.uid);

  const [showToast_copySuccess, setShowToast_copySuccess] = useState(false);

  useEffect(() => {
    /** Using isMounted to prevent React state updates on unmounted components based on this: https://stackoverflow.com/questions/53949393/cant-perform-a-react-state-update-on-an-unmounted-component **/
    let isMounted = true;

    return () => { isMounted = false };
  }, []);

  useEffect(() => {
    /** Using isMounted to prevent React state updates on unmounted components based on this: https://stackoverflow.com/questions/53949393/cant-perform-a-react-state-update-on-an-unmounted-component **/
    let isMounted = true;

    return () => { isMounted = false };
  }, []);

  return (
    <>
      <div className="profile-header-info-details-name-ign" onClick={()=>{
        navigator.clipboard.writeText(ign);
        setShowToast_copySuccess(true);
      }}>
        <IonImg src={iconURL} className="profile-header-info-details-name-ign-icon" />
        <div className="Profile-header-info-details-name-ign-name">
          {ign}
        </div>
      </div>
      <IonToast
        isOpen={showToast_copySuccess}
        onDidDismiss={() => setShowToast_copySuccess(false)}
        message="Copied"
        duration={1000}
        color="medium"
        icon={clipboard}
        cssClass="copy-toast"
      />
    </>
  );
}

export default ProfileIGNBadge;
