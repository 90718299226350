import { IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import './Achievements.css';

const Achievements: React.FC = () => {

  return (
    <div className="container achievements-container">
      <strong>Achievements</strong>
      <p>Coming Soon...</p>
    </div>
  );
};

export default Achievements;
