import {
  IonButtons,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
  IonSpinner,
  IonList,
  IonItem,
  IonLabel,
  IonImg
} from '@ionic/react';

import './Leaderboards.css';
import LeaderboardSearchFilter from './LeaderboardSearchFilter';

import {
 readUserProfileById,
 readUserMatchmakingMatches,
 readGames,
 readSplitById,
 readAllUsers,
 readBorders,
 readBanners,
 readPictures,
 readSplits,
 readUsersWithRecords,
 readMatches
} from '../../firebase';

import moment from 'moment-timezone';

import React, { useRef, useState, useEffect } from 'react';

interface LeaderboardsProps {
  setCurrentPage?: any;
  leaderboardId?: any;
}

const Leaderboards: React.FC<LeaderboardsProps> = ({leaderboardId}) => {

  const[busy, setBusy] = useState<boolean>(true);

  const[allUsers, setAllUsers] = useState<any>([]);
  const[filteredUsers, setFilteredUsers] = useState<any>([]);

  const [banners, setBanners] = useState<any>([]);
  const [pictures, setPictures] = useState<any>([]);
  const [borders, setBorders] = useState<any>([]);

  const [allSplits, setAllSplits] = useState<any[]>([]);
  const [selectedSplits, setSelectedSplits] = useState<any[]>([]);
  const [matches, setMatches] = useState<any[]>([]);

  const [games, setGames] = useState<any>();

  useEffect(() => {
    /** Using isMounted to prevent React state updates on unmounted components based on this: https://stackoverflow.com/questions/53949393/cant-perform-a-react-state-update-on-an-unmounted-component **/
    let isMounted = true;
    document.title = "Esporter | Leaderboards";

    readGames((g:any)=>{
      if (isMounted) setGames(g);
    });

    return () => { isMounted = false };
  }, []);

  useEffect(() => {
    let isMounted = true;

    readSplits((s: any)=>{

      var tempSplits: any = {};
      var isActive: boolean = false;

      for (var id in s) {
        if (s[id].status != 'closed'){
          for (var gid in games){
            for (var splitId in games[gid].splitIds){
              if (games[gid].splitIds[splitId] && splitId == id){
                isActive = true;
              }
            }
          }
          if (isActive) {
            tempSplits[id] = s[id];
          }
        }
      }
      if (isMounted) setAllSplits(tempSplits);
      if (isMounted) setSelectedSplits(tempSplits);
    });

    readMatches((m: any)=>{
      if (isMounted) setMatches(m);
    });

    return () => { isMounted = false };
  }, [games]);

  useEffect(() => {
    /** Using isMounted to prevent React state updates on unmounted components based on this: https://stackoverflow.com/questions/53949393/cant-perform-a-react-state-update-on-an-unmounted-component **/
    let isMounted = true;


    readAllUsers((u: any[]) => {

      /*tempUsers = u.sort((a, b) => {
        if (moment(a.free_trial_started).isAfter(moment(b.free_trial_started))){
          return 1; //Sort A before B
        }
        else {
          return -1;
        }
      });*/

      var tempUsers:any[] = u.filter((user)=>{
        if (!user.subscription_status){
          return false;
        }

        switch(user.subscription_status){
          case "active":
          case "pending-cancel":
          case "trial":
            return true;
          case "pending":
          case "on-hold":
          case "expired":
          case "cancelled":
          default:
            return false;
        }
      });

      var tempSplits = JSON.parse(JSON.stringify(selectedSplits));
      var tempMatches = JSON.parse(JSON.stringify(matches));

      for (var i=0; i < tempUsers.length; i++){
        if (tempUsers[i].matches){
          for (var mid in tempUsers[i].matches){
            if (tempUsers[i].matches[mid]){
              for (var sid in tempSplits){
                if (tempSplits[sid].matches){
                  if (tempSplits[sid].status != 'closed'){
                    if (tempSplits[sid].matches[mid]){
                      if (tempMatches[mid]){
                        if (tempMatches[mid].status == 'done'){
                          var playerWon:boolean = true;

                          for (var pid in tempMatches[mid].players[tempUsers[i].userId].score){
                            if (tempMatches[mid].players[tempUsers[i].userId].score[tempUsers[i].userId] < tempMatches[mid].players[tempUsers[i].userId].score[pid]){
                              playerWon = false;
                            }
                          }

                          if (!tempUsers[i].scores){
                            tempUsers[i].scores = {};
                          }
                          if (!tempUsers[i].scores[sid]){
                            tempUsers[i].scores[sid] = {
                              wins: 0,
                              losses: 0,
                              games: 0
                            };
                          }

                          if (!tempUsers[i].scores["total"]){
                            tempUsers[i].scores["total"] = {
                              wins: 0,
                              losses: 0,
                              games: 0
                            };
                          }

                          if (playerWon){
                            tempUsers[i].scores[sid].wins = tempUsers[i].scores[sid].wins + 1;
                            tempUsers[i].scores["total"].wins = tempUsers[i].scores["total"].wins + 1;
                          }
                          else {
                            tempUsers[i].scores[sid].losses = tempUsers[i].scores[sid].losses + 1;
                            tempUsers[i].scores["total"].losses = tempUsers[i].scores["total"].losses + 1;
                          }
                          tempUsers[i].scores[sid].games = tempUsers[i].scores[sid].games + 1;
                          tempUsers[i].scores["total"].games = tempUsers[i].scores["total"].games + 1;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }

      tempUsers = tempUsers.sort((a:any, b:any)=>{
        if (!a.scores){
          return -1;
        }
        if (!b.scores){
          return -1;
        }
        if (a.scores.total.games > b.scores.total.games){
          return 1;
        }
        else {
          return 1;
        }
      });

      if (isMounted) setAllUsers(tempUsers);
      if (isMounted) setBusy(false);
    });

    return () => { isMounted = false };
  }, [selectedSplits, matches]);

  useEffect(() => {
    let isMounted = true;

    readBorders((borders: any)=>{
      var tempBorders = [];

      //For all of the borders in the database
      for (var id in borders){
        tempBorders.push(borders[id]);
      }

      if (isMounted) setBorders(tempBorders);
    });

    readBanners((banners: any)=>{
      var tempBanners = [];

      //For all of the borders in the database
      for (var id in banners){
        tempBanners.push(banners[id]);
      }
      if (isMounted) setBanners(tempBanners);
    });

    readPictures((pictures: any)=>{
      var tempPictures = [];

      //For all of the borders in the database
      for (var id in pictures){
        tempPictures.push(pictures[id]);
      }

      if (isMounted) setPictures(tempPictures);
    });

    return () => { isMounted = false };
  }, []);

  return (
    <>
    {
      busy ? <IonSpinner />
      :
      <div className="container leaderboards-container">
        <LeaderboardSearchFilter allUsers={allUsers} setAllUsers={setAllUsers} filteredUsers={filteredUsers} setFilteredUsers={setFilteredUsers} selectedSplits={selectedSplits} setSelectedSplits={setSelectedSplits} allSplits={allSplits} setAllSplits={setAllSplits}/>
        <div className="leaderboard-section">
          <IonList lines="none" className='leaderboard-list'>
          {
            filteredUsers.map((user:any, index:any)=>{

              var pictureId = '';
              var borderId = '';
              var bannerId = '';

              if (user.public && user.public.cosmetics){
                if (user.public.cosmetics.picture){
                  pictureId = user.public.cosmetics.picture;
                }

                if (user.public.cosmetics.border){
                  borderId = user.public.cosmetics.border;
                }

                if (user.public.cosmetics.banner){
                  bannerId = user.public.cosmetics.banner;
                }
              }

              if (index == 0){
                return (
                  <IonItem key={index} className="leaderboard-item leaderboard-item-first-place" style={(bannerId ? (banners.filter((banner : any) => banner.id === bannerId)[0] ? {backgroundImage: `url(${banners.filter((banner: any) => banner.id === bannerId)[0].imageURL})`} : {backgroundImage: "https://esporter.win/wp-content/uploads/2023/01/Banners-1-Topography.png"}) : {backgroundImage: "https://esporter.win/wp-content/uploads/2023/01/Banners-1-Topography.png"} )}>
                    <div className="leaderboard-card-position">
                      <IonImg src="https://esporter.win/wp-content/uploads/2023/08/Rank-Borders.png" className="leaderboard-card-rank-1-image" />
                      <IonLabel className="leaderboard-card-position-text-rank-1">
                        <h1>
                          {index+1}
                        </h1>
                      </IonLabel>
                    </div>
                    <div className="leaderboard-card-player-profile">
                      <IonImg src={(pictureId ? (pictures.filter((picture:any) => picture.id === pictureId)[0] ? pictures.filter((picture:any) => picture.id === pictureId)[0].imageURL : "https://esporter.win/wp-content/uploads/2022/03/AdobeStock_23725944-scaled.jpeg") : "https://esporter.win/wp-content/uploads/2022/03/AdobeStock_23725944-scaled.jpeg")} className="user-card-player-profile-image" />
                      <IonImg src={(borderId ? (borders.filter((border:any) => border.id === borderId)[0] ? borders.filter((border:any) => border.id === borderId)[0].imageURL : "https://esporter.win/wp-content/uploads/2022/07/esporter_profile_border_10.png") : "https://esporter.win/wp-content/uploads/2022/07/esporter_profile_border_10.png")} className="user-card-player-profile-border" />
                    </div>
                    <div className="leaderboard-card-player-name">
                      <IonLabel>
                        <h1>
                          {user.displayName}
                        </h1>
                      </IonLabel>
                    </div>
                    <div className="leaderboard-card-record">
                    <IonLabel>
                      <p>
                        Wins:
                      </p>
                      <h1>
                        {(user.scores ? user.scores.total.wins : "0")}
                      </h1>
                    </IonLabel>
                    <IonLabel>
                      <p>
                        Losses:
                      </p>
                      <h1>
                        {(user.scores ? user.scores.total.losses : "0")}
                      </h1>
                    </IonLabel>
                    <IonLabel>
                      <p>
                        Games:
                      </p>
                      <h1>
                        {(user.scores ? user.scores.total.games : "0")}
                      </h1>
                    </IonLabel>
                    </div>

                  </IonItem>
                );
              }
              else if (index < 3){
                return (
                  <IonItem key={index} className="leaderboard-item leaderboard-item-ranks-2-3" style={(bannerId ? (banners.filter((banner : any) => banner.id === bannerId)[0] ? {backgroundImage: `url(${banners.filter((banner: any) => banner.id === bannerId)[0].imageURL})`} : {backgroundImage: "https://esporter.win/wp-content/uploads/2023/01/Banners-1-Topography.png"}) : {backgroundImage: "https://esporter.win/wp-content/uploads/2023/01/Banners-1-Topography.png"} )}>
                    <div className="leaderboard-card-position">
                      <IonImg src="https://esporter.win/wp-content/uploads/2023/08/Rank-Borders2.png" className="leaderbaord-card-rank-2-3-image" />
                      <IonLabel className="leaderboard-card-position-text-ranks-2-3">
                        <h1>
                          {index+1}
                        </h1>
                      </IonLabel>
                    </div>
                    <div className="leaderboard-card-player-profile">
                      <IonImg src={(pictureId ? (pictures.filter((picture:any) => picture.id === pictureId)[0] ? pictures.filter((picture:any) => picture.id === pictureId)[0].imageURL : "https://esporter.win/wp-content/uploads/2022/03/AdobeStock_23725944-scaled.jpeg") : "https://esporter.win/wp-content/uploads/2022/03/AdobeStock_23725944-scaled.jpeg")} className="user-card-player-profile-image" />
                      <IonImg src={(borderId ? (borders.filter((border:any) => border.id === borderId)[0] ? borders.filter((border:any) => border.id === borderId)[0].imageURL : "https://esporter.win/wp-content/uploads/2022/07/esporter_profile_border_10.png") : "https://esporter.win/wp-content/uploads/2022/07/esporter_profile_border_10.png")} className="user-card-player-profile-border" />
                    </div>
                    <div className="leaderboard-card-player-name">
                      <IonLabel>
                        <h1>
                          {user.displayName}
                        </h1>
                      </IonLabel>
                    </div>
                    <div className="leaderboard-card-record">
                    <IonLabel>
                      <p>
                        Wins:
                      </p>
                      <h1>
                        {(user.scores ? user.scores.total.wins : "0")}
                      </h1>
                    </IonLabel>
                    <IonLabel>
                      <p>
                        Losses:
                      </p>
                      <h1>
                        {(user.scores ? user.scores.total.losses : "0")}
                      </h1>
                    </IonLabel>
                    <IonLabel>
                      <p>
                        Games:
                      </p>
                      <h1>
                        {(user.scores ? user.scores.total.games : "0")}
                      </h1>
                    </IonLabel>
                    </div>
                  </IonItem>
                );
              }
              else {
                return (
                  <IonItem key={index} className="leaderboard-item leaderboard-item-ranks-4-plus" style={(bannerId ? (banners.filter((banner : any) => banner.id === bannerId)[0] ? {backgroundImage: `url(${banners.filter((banner: any) => banner.id === bannerId)[0].imageURL})`} : {backgroundImage: "https://esporter.win/wp-content/uploads/2023/01/Banners-1-Topography.png"}) : {backgroundImage: "https://esporter.win/wp-content/uploads/2023/01/Banners-1-Topography.png"} )}>
                    <div className="leaderboard-card-position">
                      <IonLabel className="leaderboard-card-position-text-ranks-4-plus">
                        <h1>
                          {index+1}
                        </h1>
                      </IonLabel>
                    </div>
                    <div className="leaderboard-card-player-profile">
                      <IonImg src={(pictureId ? (pictures.filter((picture:any) => picture.id === pictureId)[0] ? pictures.filter((picture:any) => picture.id === pictureId)[0].imageURL : "https://esporter.win/wp-content/uploads/2022/03/AdobeStock_23725944-scaled.jpeg") : "https://esporter.win/wp-content/uploads/2022/03/AdobeStock_23725944-scaled.jpeg")} className="user-card-player-profile-image" />
                      <IonImg src={(borderId ? (borders.filter((border:any) => border.id === borderId)[0] ? borders.filter((border:any) => border.id === borderId)[0].imageURL : "https://esporter.win/wp-content/uploads/2022/07/esporter_profile_border_10.png") : "https://esporter.win/wp-content/uploads/2022/07/esporter_profile_border_10.png")} className="user-card-player-profile-border" />
                    </div>
                    <div className="leaderboard-card-player-name">
                      <IonLabel>
                        {user.displayName}
                      </IonLabel>
                    </div>
                    <div className="leaderboard-card-record">
                    <IonLabel>
                      <p>
                        Wins:
                      </p>
                      <h1>
                        {(user.scores ? user.scores.total.wins : "0")}
                      </h1>
                    </IonLabel>
                    <IonLabel>
                      <p>
                        Losses:
                      </p>
                      <h1>
                        {(user.scores ? user.scores.total.losses : "0")}
                      </h1>
                    </IonLabel>
                    <IonLabel>
                      <p>
                        Games:
                      </p>
                      <h1>
                        {(user.scores ? user.scores.total.games : "0")}
                      </h1>
                    </IonLabel>
                    </div>
                  </IonItem>
                );
              }
            })
          }
          </IonList>
        </div>
      </div>
    }
    </>
  );
};

export default Leaderboards;
