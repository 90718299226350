import {
  IonButtons,
  IonButton,
  IonContent,
  IonHeader,
  IonIcon,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
  IonApp,
  IonRouterOutlet,
  IonSplitPane,
  IonNav,
  IonBadge,
  setupIonicReact,
  useIonViewDidEnter
} from '@ionic/react';

import { useParams } from 'react-router';
import ExploreContainer from '../components/ExploreContainer';
import NotificationsContainer from '../components/NotificationsContainer';
import './Page.css';
import { app, auth, readUserNotifications, updateUserNotification } from '../firebase';
import { onAuthStateChanged} from 'firebase/auth';
import Login from '../pages/Login';

import { IonReactRouter } from '@ionic/react-router';
import { Redirect, Route } from 'react-router-dom';
import { notificationsOutline, notificationsSharp } from 'ionicons/icons';

import { useState, useEffect, useRef} from 'react';
import { useSelector } from 'react-redux';



const Page: React.FC = () => {

  const [hasNotification, setHasNotification] = useState(false);
  const [notifications, setNotifications] = useState<any[]>([])
  const [notificationsCount, setNotificationsCount] = useState(0);
  const [showNotifications, setShowNotifications] = useState(false);

  const notificationsWrapperRef = useRef<any>(null);

  const { name } = useParams<{ name: string; }>();
  const { id } = useParams<{ id: string; }>();
  const userId = useSelector((state: any) => state.userData.uid);

  //console.log("User ID: "+userId);

  useEffect(() => {
    let isMounted = true;

    readUserNotifications(userId, (n: any[]) => {
         if (isMounted) setNotifications(n);
         //console.log("Notifications set to..."); //TODO - remove debugging
         //console.log(notifications); //TODO - remove debugging

         var notificationsCount = n.length;
         var newNotificationsCount = 0;

         for (var i=0; i<n.length; i++) {
           if (n[i].hasBeenRead == false) {
             newNotificationsCount++;
           }
         }

         if (newNotificationsCount > 0) {
           if (isMounted) setHasNotification(true);
         }
         else {
           if (isMounted) setHasNotification(false);
         }

         if (isMounted) setNotificationsCount(notificationsCount);
    });

    return () => {
      isMounted = false;
    };
  }, []);


  useEffect(() => {
    document.addEventListener("click", handleClickOutside, false);
    return () => {
      document.removeEventListener("click", handleClickOutside, false);
    };
  }, [showNotifications]);

  const handleClickOutside = (event: any) => {
    if (showNotifications){
      if (notificationsWrapperRef.current && !notificationsWrapperRef.current.contains(event.target)) {
        setShowNotifications(false);
      }
    }
  };

  /*  //TODO implement in firebase.tsx
  readUserNotifications(userId).then((n:any)=> {
    console.log("Reading notifications...")
    if (n) {
      setHasNotification(true);
      setNotifications(n);
    }
    else {
      setHasNotification(false);
    }
  }).catch((e) => {
    console.log(e);
  });
  */

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>{name}</IonTitle>
          <IonButton slot="end" className="inbox-button" fill="clear" onClick={() => {
            if(showNotifications) {
              setShowNotifications(false);
            }
            else {
              for (var n in notifications){
                updateUserNotification(userId, notifications[n], "read");
              }
              setShowNotifications(true);
              setHasNotification(false);
            }
          }}>
            <IonIcon icon={(hasNotification ? notificationsSharp : notificationsOutline)} className={(hasNotification ? "inbox-button-icon-full" : "inbox-button-icon-empty")}></IonIcon>
            {(notificationsCount > 0 ? <IonBadge className="inbox-button-badge">{notificationsCount}</IonBadge> : '')}
          </IonButton>
        </IonToolbar>
        {(showNotifications ? <div ref={notificationsWrapperRef}><NotificationsContainer /></div>: '')}
      </IonHeader>

      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">{name}</IonTitle>
          </IonToolbar>
        </IonHeader>
        <ExploreContainer name={name} id={id}/>
      </IonContent>
    </IonPage>
  );
};

export default Page;
