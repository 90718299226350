import {
  IonButtons,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonToggle,
  IonButton,
  IonIcon
 } from '@ionic/react';
import './VideoCard.css';

import { useEffect, useState, useRef} from 'react';

import {
  readRecordingById,
  updateRecording
} from '../../firebase';

import {
  pencilOutline,
  trashOutline
} from 'ionicons/icons';

interface VideoCardProps {
  video: any;
}

const VideoCard: React.FC<VideoCardProps> = ({video}) => {

  const [isBusy, setIsBusy] = useState<boolean>(true);
  const [isAvailable, setIsAvailable] = useState(video.isAvailable);

  useEffect(() => {
    /** Using isMounted to prevent React state updates on unmounted components based on this: https://stackoverflow.com/questions/53949393/cant-perform-a-react-state-update-on-an-unmounted-component **/
    let isMounted = true;

    return () => { isMounted = false };
  }, []);

  return (
    <>
      {
          (
            video ?
            <IonCard className="game-card-container">
              <img alt={video.name} src={video.thumbnailImageURL} className="game-card-image"/>
              <IonCardHeader>
                <IonCardTitle>{video.title}</IonCardTitle>
                <IonCardSubtitle className="game-card-subtitle">
                    {(isAvailable ? "Featured: " : "Not Featured: ")}
                    {
                      <IonToggle className="game-card-subtitle-toggle" checked={isAvailable} onIonChange={(e)=>{

                        var tempIsAvailable = !isAvailable;
                        var tempVideo = JSON.parse(JSON.stringify(video));
                        tempVideo.isAvailable = tempIsAvailable;

                        setIsAvailable(tempIsAvailable);

                        updateRecording(tempVideo, ()=>{});

                      }}></IonToggle>

                    }
                  </IonCardSubtitle>
                <IonCardSubtitle className="game-card-subtitle-split-id"> {video.description}</IonCardSubtitle>
              </IonCardHeader>
              <div className="game-card-content-container">
                <div className="game-card-content">
                    {/*gameTypes.map((type, index) => {return (<p>{type}</p>);})*/}
                    <IonButton fill="clear" className="game-card-button" onClick={(e)=>{
                      window.open("/Videos/"+video.id, "_self");
                    }}>
                      <IonIcon slot="start" icon={pencilOutline} className="game-card-icon"/>
                    </IonButton>
                    <IonButton fill="clear" className="game-card-button">
                      <IonIcon slot="start" icon={trashOutline} className="game-card-icon"/>
                    </IonButton>
                </div>
              </div>

            </IonCard>
            :
            ''
          )
        }

    </>
  );
};

export default VideoCard;
