import {
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonMenu,
  IonMenuToggle,
  IonNote,
  IonImg,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardSubtitle,
  IonCardContent,
  IonToast,
  IonBadge,
  IonText,
  useIonViewDidEnter,
  useIonViewWillEnter,
} from '@ionic/react';

import {
  personOutline,
  personSharp,
  calendarOutline,
  calendarSharp,
  heartHalfOutline,
  heartHalfSharp,
  globeOutline,
  globeSharp,
  trophyOutline,
  trophySharp,
  podiumOutline,
  podiumSharp,
  logOutOutline,
  timeOutline,
  timeSharp,
  videocamOutline,
  videocamSharp,
  clipboard,
  cardOutline,
  listOutline,
  listSharp,
  logoDiscord,
  peopleOutline,
  peopleSharp,
  layersOutline,
  layersSharp,
  gameControllerOutline,
  gameControllerSharp
} from 'ionicons/icons';

import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { logoutUser, readUserProfileById, writeUserHasSeenTutorial } from '../firebase';
import { sendGetRequest } from '../http-requests';

import Joyride, { ACTIONS, EVENTS, STATUS } from 'react-joyride';

import './Menu.css';

interface AppPage {
  url: string;
  iosIcon: string;
  mdIcon: string;
  title: string;
  beta?: boolean;
  admin?: boolean;
}

const appPages: AppPage[] = [
  {
    title: 'Profile',
    url: '/Profile',
    iosIcon: personOutline,
    mdIcon: personSharp
  },
  {
    title: 'Matchmaking',
    url: '/Matchmaking',
    iosIcon: heartHalfOutline,
    mdIcon: heartHalfSharp,
    beta: true,
  },
  {
    title: 'Matches',
    url: '/Matches',
    iosIcon: listOutline,
    mdIcon: listSharp,
    beta: true
  },
  {
    title: 'Availability',
    url: '/Availability',
    iosIcon: timeOutline,
    mdIcon: timeSharp
  }
/*,
  {
    title: 'Matchmaking',
    url: '/Matchmaking',
    iosIcon: globeOutline,
    mdIcon: globeSharp
  },
  {
    title: 'Achievements',
    url: '/Achievements',
    iosIcon: trophyOutline,
    mdIcon: trophySharp
  },
  {
    title: 'Tournaments',
    url: '/Tournaments',
    iosIcon: podiumOutline,
    mdIcon: podiumSharp
  }*/
];

const appPagesSubscriber: AppPage[] = [
  {
    title: 'Profile',
    url: '/Profile',
    iosIcon: personOutline,
    mdIcon: personSharp
  },
  {
    title: 'Events',
    url: '/Events',
    iosIcon: calendarOutline,
    mdIcon: calendarSharp
  },
  {
    title: 'Recordings',
    url: '/Recordings',
    iosIcon: videocamOutline,
    mdIcon: videocamSharp
  },
  /*{
    title: 'Leaderboards',
    url: '/Leaderboards',
    iosIcon: podiumOutline,
    mdIcon: podiumSharp,
    beta: true
  },*/
  {
    title: 'Matchmaking',
    url: '/Matchmaking',
    iosIcon: heartHalfOutline,
    mdIcon: heartHalfSharp,
    beta: true
  },
  {
    title: 'Matches',
    url: '/Matches',
    iosIcon: listOutline,
    mdIcon: listSharp,
    beta: true
  },
  {
    title: 'Availability',
    url: '/Availability',
    iosIcon: timeOutline,
    mdIcon: timeSharp
  }

/*,
  {
    title: 'Matchmaking',
    url: '/Matchmaking',
    iosIcon: globeOutline,
    mdIcon: globeSharp
  },
  {
    title: 'Achievements',
    url: '/Achievements',
    iosIcon: trophyOutline,
    mdIcon: trophySharp
  },
  {
    title: 'Tournaments',
    url: '/Tournaments',
    iosIcon: podiumOutline,
    mdIcon: podiumSharp
  }*/
];

const appPagesAdmin: AppPage[] = [
  {
    title: 'Profile',
    url: '/Profile',
    iosIcon: personOutline,
    mdIcon: personSharp
  },
  {
    title: 'Events',
    url: '/Events',
    iosIcon: calendarOutline,
    mdIcon: calendarSharp
  },
  {
    title: 'Recordings',
    url: '/Recordings',
    iosIcon: videocamOutline,
    mdIcon: videocamSharp
  },
  {
    title: 'Leaderboards',
    url: '/Leaderboards',
    iosIcon: podiumOutline,
    mdIcon: podiumSharp,
  },
  {
    title: 'Matchmaking',
    url: '/Matchmaking',
    iosIcon: heartHalfOutline,
    mdIcon: heartHalfSharp,
    beta: true
  },
  {
    title: 'Matches',
    url: '/Matches',
    iosIcon: listOutline,
    mdIcon: listSharp,
    beta: true
  },
  {
    title: 'Availability',
    url: '/Availability',
    iosIcon: timeOutline,
    mdIcon: timeSharp
  },
  {
    title: 'Users',
    url: '/Users',
    iosIcon: peopleOutline,
    mdIcon: peopleSharp,
    admin: true
  },
  {
    title: 'Splits',
    url: '/Splits',
    iosIcon: layersOutline,
    mdIcon: layersSharp,
    admin: true
  },
  {
    title: 'Games',
    url: '/Games',
    iosIcon: gameControllerOutline,
    mdIcon: gameControllerSharp,
    admin: true
  },
  /*{
    title: 'Leaderboards',
    url: '/LeaderboardsAdmin',
    iosIcon: podiumOutline,
    mdIcon: podiumSharp,
    admin: true
  },*/
  {
    title: 'Videos',
    url: '/Videos',
    iosIcon: videocamOutline,
    mdIcon: videocamSharp,
    admin: true
  }
/*,
  {
    title: 'Matchmaking',
    url: '/Matchmaking',
    iosIcon: globeOutline,
    mdIcon: globeSharp
  },
  {
    title: 'Achievements',
    url: '/Achievements',
    iosIcon: trophyOutline,
    mdIcon: trophySharp
  },
  {
    title: 'Tournaments',
    url: '/Tournaments',
    iosIcon: podiumOutline,
    mdIcon: podiumSharp
  }*/
];


/*const labels = ['Family', 'Friends', 'Notes', 'Work', 'Travel', 'Reminders'];*/

const Menu: React.FC = () => {
  const location = useLocation();
  const userData = useSelector((state: any) => state.userData);
  const username = useSelector((state: any) => state.userData.displayName ? state.userData.displayName.split(" ")[0] : "Player"); // TODO - update username to pull from a custom field in the database

  const userId = useSelector((state: any) => state.userData.uid);

  //by default, use the menu for a base user (non-subscriber, non-admin)
  const [menuItems, setMenuItems] = useState(appPages);
  const [showReferral, setShowReferral] = useState<boolean>(false);
  const [referralURL, setReferralURL] = useState<string>('');

  const [isAdmin, setIsAdmin] = useState<boolean>(false);

  const [showTutorial, setShowTutorial] = useState<boolean>(false);
  const [tutorialSteps, setTutorialSteps] = useState<any[]>(
    [
      {
        target: '.side-menu',
        title: "Welcome to the Esporter App!",
        content: "Let's complete your setup.",
        disableBeacon: true,
        offset: 100000
      },
      {
        target: '.profile-games',
        title: "Completing Setup",
        content: 'Select the games that you want to play.',
      },
      {
        target: '.profile-header-profile-picture',
        title: "Your Profile Picture",
        content: 'Click here to change your profile picture and border.',
      },
      {
        target: '.profile-header-banner',
        title: "Your Banner",
        content: 'Click here to update your profile banner.',
      },
      {
        target: '.profile-header-menu-item-settings',
        title: "Your Settings",
        content: "Let's update your account settings",
      },
      {
        target: '.profile-about',
        title: "Your In-Game Names",
        content: 'Please enter your in-game names here.',
      },
      {
        target: '.side-menu',
        title: "Events",
        content: 'Check out the events calendar and save classes to your calendar.',
      },
      {
        target: '.discord-menu-item',
        title: "Join the Discord",
        content: 'We run everything through Discord. Come say hi and join the conversation.',
      }
    ]
  );

  const [showToast_copySuccess, setShowToast_copySuccess] = useState(false);


  useEffect(()=> {
    let isMounted = true;

    var profileListener = readUserProfileById(userId, (user: any) => {
      if (user == null) {
        return;
      }


      switch(user.role){
        case "admin":
          if (isMounted) setMenuItems(appPagesAdmin);
          if (isMounted) setIsAdmin(true);
          break;
          default:
          switch(user.subscription_status){
            case "active":
            case "pending-cancel":
            case "trial":
              if (isMounted) setMenuItems(appPagesSubscriber);
              if (user.referralURL){
                if (isMounted) setShowReferral(true);
                if (isMounted) setReferralURL(user.referralURL);
              }
              break;
            case "pending":
            case "on-hold":
            case "expired":
            case "cancelled":
            default:
            break;
          }
        break;
      }

      if (!user.has_seen_tutorial){
        setShowTutorial(true);
      }
    });

    return () => {
      isMounted = false;
      profileListener();
    };
  },[]);

  return (
    <IonMenu contentId="main" type="overlay">
      {( showTutorial ?
        <Joyride
          steps={tutorialSteps}
          continuous={true}
          locale={
            {
              back: 'Back',
              close: 'Close',
              last: 'Done',
              next: 'Next',
              open: 'Open the dialog',
              skip: 'Skip'
            }
          }
          showProgress={true}
          styles={
            {
              options: {
                primaryColor: '#3d85c6'
              }
            }
          }
          spotlightClicks = {true}
          disableOverlay= {false}
          spotlightPadding = {20}
          run = {true}
          callback = {(data)=>{
            const { action, index, status, type } = data;
            var new_status:any = status; //do this to assign the any type to the variable
            if ([STATUS.FINISHED, STATUS.SKIPPED].includes(new_status)) {
              writeUserHasSeenTutorial(userId);
            }
          }}
          showSkipButton = {true}
        />
        :
        ''
      )}
      <IonContent>
        <IonList id="inbox-list" className="side-menu">
          <IonListHeader>
            <IonImg className="esporter-logo-img" src="https://esporter.win/wp-content/uploads/2021/12/logo-nobackground-cropped1.svg" />
          </IonListHeader>
          <IonNote className='beta-version'>Beta v0.2.5 <a href="https://esporter.win/contact" target="_blank" className='submit-feedback-link'>Submit Feedback</a></IonNote>
            {

              menuItems.map((appPage, index) => {
                //randomize index so the list will update properly
                index = index + Math.floor(Math.random() * 10000);

                return (
                  <div key={index}>
                  {
                    (
                      !appPage.admin ?
                        <IonMenuToggle autoHide={false}>
                          <IonItem className={location.pathname === appPage.url ? 'selected' : ''} routerLink={appPage.url} routerDirection="none" lines="none" detail={false}>
                            <IonIcon slot="start" icon={appPage.mdIcon} ios={appPage.iosIcon} md={appPage.mdIcon}/>
                            <IonLabel>{appPage.title}</IonLabel>
                            {
                              (
                                appPage.beta ?
                                <IonBadge slot="end" color="light">BETA</IonBadge>
                                :
                                ''
                              )
                            }
                          </IonItem>
                        </IonMenuToggle>
                        :
                        ''
                    )
                  }
                  </div>
                );
              })

            }
        </IonList>

        {
          (
            isAdmin ?
            <>
              <br />
              <IonList id="admin-list" className="side-menu">
                <IonText color="medium" className="side-menu-admin-header">
                  <h4> Admin Panel </h4>
                </IonText>

                  {
                    menuItems.map((appPage, index) => {
                      //randomize index so the list will update properly
                      index = index + Math.floor(Math.random() * 10000);

                      return (
                        <div key={index}>
                        {
                          (
                            appPage.admin ?
                              <IonMenuToggle autoHide={false}>
                                <IonItem className={location.pathname === appPage.url ? 'selected' : ''} routerLink={appPage.url} routerDirection="none" lines="none" detail={false}>
                                  <IonIcon slot="start" icon={appPage.mdIcon} ios={appPage.iosIcon} md={appPage.mdIcon}/>
                                  <IonLabel>{appPage.title}</IonLabel>
                                  {
                                    (
                                      appPage.beta ?
                                      <IonBadge slot="end" color="light">BETA</IonBadge>
                                      :
                                      ''
                                    )
                                  }
                                </IonItem>
                              </IonMenuToggle>
                              :
                              ''
                          )
                        }
                        </div>
                      );
                    })

                  }

                </IonList>
              </>
            :
            ''
          )
        }


        <IonList id="labels-list">
          {/* <IonListHeader>Labels</IonListHeader> */}
          <IonItem button onClick={()=>{
            window.open("https://esporter.win/sign-in-purchase/?redirect_url=https://esporter.win/my-account/subscriptions/", "_blank");
          }} lines="none">
            <IonIcon color="medium" slot="start" icon={cardOutline} />
            <IonLabel color="medium">My Account</IonLabel>
          </IonItem>
          <IonItem button onClick={()=>{
            window.open("https://discord.gg/dbAHbsRcJf", "_blank");
          }} lines="none" className="discord-menu-item">
            <IonIcon color="medium" slot="start" icon={logoDiscord} />
            <IonLabel color="medium">Join the Discord</IonLabel>
          </IonItem>
          <IonItem button onClick={logoutUser} lines="none">
            <IonIcon color="danger" slot="start" icon={logOutOutline} />
            <IonLabel color="medium" >Log Out</IonLabel>
          </IonItem>

          {/*{labels.map((label, index) => (
            <IonItem lines="none" key={index}>
              <IonIcon slot="start" icon={bookmarkOutline} />
              <IonLabel>{label}</IonLabel>
            </IonItem>
          ))}*/}
        </IonList>

        {showReferral ?
          <IonCard className="menu-referral-card">
            <IonCardHeader className="menu-referral-card-header">
              <IonCardTitle className="menu-referral-card-title">Get Free Esporter</IonCardTitle>
              <IonCardSubtitle className="menu-referral-card-subtitle">Refer a Friend</IonCardSubtitle>
            </IonCardHeader>

            <IonCardContent className="menu-referral-card-content">
              Earn one month of Esporter for every friend who subscibes using your referral link.

              <div className="menu-referral-card-link" onClick={()=>{
                navigator.clipboard.writeText(referralURL);
                setShowToast_copySuccess(true);
              }}>
                Copy Link
              </div>
            </IonCardContent>
            <IonToast
              isOpen={showToast_copySuccess}
              onDidDismiss={() => setShowToast_copySuccess(false)}
              message="Copied"
              duration={1000}
              color="medium"
              icon={clipboard}
              cssClass="copy-toast"
            />
          </IonCard>
          :
          ""
        }
        </IonContent>
      </IonMenu>
  );
};

export default Menu;
