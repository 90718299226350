import {
  IonButtons,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardSubtitle,
  IonCardContent,
  IonItem,
  IonLabel,
  IonList,
  IonAccordionGroup,
  IonAccordion,
  IonToggle,
  IonIcon,
  IonButton,
  IonBadge,
  IonImg,
  IonText,
  IonInput,
  IonRadioGroup,
  IonRadio,
  IonListHeader,
  IonToast,
  IonPopover,
  IonDatetime,
  useIonAlert
 } from '@ionic/react';

 import {
   readUserProfileById,
   updateMatchWithUserStatus,
   readUserMatchById,
   updateMatchStatus,
   updateMatchWithUserScore,
   updateMatchWithUser,
   findMatchTimesForPlayers,
   readSplitById,
   readUserAvailabilityTimezone,
   writeMatchmakingMatch,
   countChallengesInSplit,
   writeUserGame
 } from '../../firebase';

import { refreshOutline, flashOutline } from 'ionicons/icons';
import './MatchmakingCardPanel.css';

import React, { useRef, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import moment from 'moment-timezone';

var currentPage:any;
var setCurrentPage:any;

interface MatchmakingCardPanelProps {
  playerId: string;
  splitId: string;
  gameName: string;
  gameType: string;
  gameRounds?: string;
}

const MatchmakingCardPanel: React.FC<MatchmakingCardPanelProps> = ({playerId, splitId, gameName, gameType, gameRounds}) => {

  const userId = useSelector((state: any) => state.userData.uid);

  const [split, setSplit] = useState<any>();

  const [createMatchTimePopoverIsOpen, setCreateMatchTimePopoverIsOpen] = useState<boolean>(false);
  const [recommendedTimePopoverIsOpen, setRecommendedTimePopoverIsOpen] = useState<boolean>(false);

  const [createMatchTime, setCreateMatchTime] = useState<any>();
  const [createMatchTimeISO, setCreateMatchTimeISO] = useState<string>();

  const [recommendedTimes, setRecommendedTimes] = useState<any>([]);
  const [playerTimezone, setPlayerTimezone] = useState<any>();

  const [challengesCount, setChallengesCount] = useState<number>(0);

  const [showToast_createMatchSuccess, setShowToast_createMatchSuccess] = useState<boolean>(false);

  const datetime = useRef<null | HTMLIonDatetimeElement>(null);

  useEffect(()=>{
    let isMounted = true;

    const timezone = async () => {
      if (isMounted) setPlayerTimezone(await readUserAvailabilityTimezone(userId));
    }
    timezone();

    return () => { isMounted = false };
  },[]);

  useEffect(()=>{
    let isMounted = true;

    var tempPlayers: any = [];

    readSplitById(splitId, (s:any) => {
      if (isMounted) setSplit(s);
    });

    var opponents: any[] = [playerId];

    console.log("Finding Match Times for Players..."); //TODO - remove debugging

    findMatchTimesForPlayers(userId, opponents, gameName, gameType, (times:any)=>{
      if (times){
        if (isMounted) setRecommendedTimes(times);
      }
    });

    countChallengesInSplit(userId, playerId, splitId, (count:any)=>{
      if (isMounted) setChallengesCount(count);
    })

    return () => { isMounted = false };
  }, []);

  return (
      <div className="matchmaking-card-panel">
       <IonButton
        className="matchmaking-card-challenge-button"
        disabled={(challengesCount == 2 ? false : false)}
        onClick={(e: any)=>{
         setRecommendedTimePopoverIsOpen(true);
       }}>
         Challenge
       </IonButton>
       <div className="matchmaking-card-panel-challenge-text">
          <p>Challenges Used: </p>
       </div>
       <div className="matchmaking-card-panel-challenge-counter">
          {
            (
              challengesCount > 0 ?
                <IonImg src="https://esporter.win/wp-content/uploads/2023/02/Challenge-Used.png" className="matchmaking-card-panel-challenge-counter-image"/>
              :
                <IonImg src="https://esporter.win/wp-content/uploads/2023/02/Challenge-Available.png" className="matchmaking-card-panel-challenge-counter-image"/>
            )
          }
       </div>
       <div className="matchmaking-card-panel-challenge-counter">
         {
           (
             challengesCount > 1 ?
               <IonImg src="https://esporter.win/wp-content/uploads/2023/02/Challenge-Used.png" className="matchmaking-card-panel-challenge-counter-image"/>
             :
               <IonImg src="https://esporter.win/wp-content/uploads/2023/02/Challenge-Available.png" className="matchmaking-card-panel-challenge-counter-image"/>
           )
         }
       </div>
       <IonPopover className="matchmaking-create-match-time-picker" isOpen={createMatchTimePopoverIsOpen} onDidDismiss={ () => {setCreateMatchTimePopoverIsOpen(false);}} >
         <IonDatetime
           value={createMatchTimeISO}
           showDefaultButtons={true}
           ref={datetime}
           min={moment().format()}
           onIonChange={(e) => {
             setCreateMatchTime(moment(e.detail.value).format('MMMM Do YYYY, h:mm a'));
             setCreateMatchTimeISO(moment(e.detail.value).format());
             setCreateMatchTimePopoverIsOpen(false);
             setRecommendedTimePopoverIsOpen(false);
             setChallengesCount(challengesCount+1);

             const new_match = {
               actual_time: moment(e.detail.value).format(),
               playersAgreeOnScore: false,
               status: "challenge",
               timezone: "America/Los_Angeles",
               wasConcededBy: "",
               players: {
                 [userId]: {
                   isWinner: false,
                   score: {
                     [userId]: 0,
                     [playerId]: 0
                   },
                   status: "challenge-issued",
                   suggestedTime: moment(e.detail.value).format()
                 },
                 [playerId]: {
                   isWinner: false,
                   score: {
                     [playerId]: 0,
                     [userId]: 0
                   },
                   status: "challenge-requested"
                 }
               },
               gameName: gameName,
               gameId: gameName,
               gameType: gameType,
               gameRounds: gameRounds
             }

             console.log("Writing Match from time picker...");
             console.log(new_match);

             writeMatchmakingMatch(userId, playerId, new_match, splitId);
             writeUserGame(userId, gameName, true, ()=>{});
             setShowToast_createMatchSuccess(true);
           }}
         >
         </IonDatetime>
       </IonPopover>
       <IonPopover className="matchmaking-create-match-recommended-times" isOpen={recommendedTimePopoverIsOpen} onDidDismiss={ () => {setRecommendedTimePopoverIsOpen(false);}} >
        <h4 className="matchmaking-create-match-recommended-times-title"> You are both available at these times </h4>
          {
            ( recommendedTimes.length > 0 ?
              recommendedTimes.map((recommendedTime:any, index:any)=>{
              var day:string = moment(recommendedTime).tz(playerTimezone).format("dddd");
              var date:string = moment(recommendedTime).tz(playerTimezone).format("MMMM Do YYYY");
              var time:string = moment(recommendedTime).tz(playerTimezone).format("h:mm a");

              return(
                <IonCard
                  className="matchmaking-create-match-recommended-time-card"
                  key={index}
                  onClick={()=>{
                    setCreateMatchTime(moment(recommendedTime).format('MMMM Do YYYY, h:mm a'));
                    setCreateMatchTimeISO(moment(recommendedTime).format());
                    setRecommendedTimePopoverIsOpen(false);

                    const new_match = {
                      actual_time: moment(recommendedTime).format(),
                      playersAgreeOnScore: false,
                      status: "challenge",
                      timezone: "America/Los_Angeles",
                      wasConcededBy: "",
                      players: {
                        [userId]: {
                          isWinner: false,
                          score: {
                            [userId]: 0,
                            [playerId]: 0
                          },
                          status: "challenge-issued",
                          suggestedTime: moment(recommendedTime).format()
                        },
                        [playerId]: {
                          isWinner: false,
                          score: {
                            [playerId]: 0,
                            [userId]: 0
                          },
                          status: "challenge-requested",
                          suggestedTime: moment(recommendedTime).format()
                        }
                      },
                      gameName: gameName,
                      gameId: gameName,
                      gameType: gameType,
                      gameRounds: gameRounds
                    }

                    console.log("Writing Match from recommended time card...");
                    console.log(new_match);

                    writeMatchmakingMatch(userId, playerId, new_match, splitId);
                    writeUserGame(userId, gameName, true, ()=>{});
                    setChallengesCount(challengesCount+1);
                    setShowToast_createMatchSuccess(true);
                  }}
                >
                <IonCardHeader>
                  <IonCardTitle>{day}</IonCardTitle>
                  <IonCardSubtitle>{date}</IonCardSubtitle>
                  </IonCardHeader>
                  <IonCardContent>
                    {time}
                  </IonCardContent>
                </IonCard>
              );
            })
              :
              <div>
                <p>
                  You and your opponent do not share any available times. Check your <a href="/Availability">availability</a> or set your own custom time.
                </p>
              </div>
            )
          }

          <IonButton
          onClick={()=>{
            setCreateMatchTimePopoverIsOpen(true);
          }}
          >
            Set Custom Time
          </IonButton>
          <IonButton
            className="matchmaking-create-match-recommended-times-refresh-button"
            onClick={()=>{
              var opponents: any[] = [playerId];
              findMatchTimesForPlayers(userId, opponents, gameName, gameType, (times:any)=>{
                setRecommendedTimes(times);
              });
            }}
          >
            <IonIcon slot="start" icon={refreshOutline} />
            Find New Times
          </IonButton>
       </IonPopover>
       <IonToast
         isOpen={showToast_createMatchSuccess}
         onDidDismiss={() => setShowToast_createMatchSuccess(false)}
         message="Challenge Issued"
         duration={3000}
         color="primary"
         icon={flashOutline}
         cssClass="create-match-success-toast"
       />

    </div>
    );
};

export default MatchmakingCardPanel;
