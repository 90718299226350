import {
  IonButtons,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
  IonSpinner,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardSubtitle,
  IonCardContent,
  IonButton
} from '@ionic/react';
import './Recordings.css';
import { useEffect, useState, useRef} from 'react';

import {
  readFeaturedRecordings,
  readRecordingById
} from '../../firebase';

interface RecordingsProps {
  recordingId?: string;
}

const RecordingsHome: React.FC<RecordingsProps> = ({recordingId}) => {

  const [busy, setBusy] = useState<boolean>(true);
  const [recordings, setRecordings] = useState<any>([]);

  useEffect(()=>{
    let isMounted = true;

    readFeaturedRecordings((r:any[]) => {
      if (isMounted) setRecordings(r);
      if (isMounted) setBusy(false);
    });

    return () => { isMounted = false };
  },[])

  return (
      busy ? <IonSpinner /> :

      <div className="container recordings-container">
        <h1 className="recordings-title"> Featured Lessons </h1>
        <div className="recording-cards-container">
        {
          (
            recordings.length ?
              recordings.map((recording:any, index:any)=>{
                return (
                  <IonCard className="recording-card" key={index} onClick={()=>{
                    window.location.href = "/Recordings/"+recording.id;
                  }}>
                    <img alt={recording.title} src={recording.thumbnailImageURL} />
                    <IonCardHeader>
                      <IonCardTitle>{recording.title}</IonCardTitle>
                      <IonCardSubtitle>{recording.subtitle}</IonCardSubtitle>
                    </IonCardHeader>

                    {/*
                      <IonCardContent>
                        {recording.description}
                      </IonCardContent>
                    */}
                  </IonCard>
                );
              })
            :
              <h1> There don't seem to be any recordings here </h1>
          )
        }
        </div>
      </div>
  );
};

const RecordingsPersonal: React.FC<RecordingsProps> = ({recordingId}) => {

  const [busy, setBusy] = useState(true);

  useEffect(()=>{
    let isMounted = true;

    if (isMounted) setBusy(false);

    return () => { isMounted = false };
  },[])

  return (
      busy ? <IonSpinner /> :

      <div className="container recordings-container">
        <h2> Personal Library Coming Soon... </h2>
      </div>
  );
};

const RecordingsIndividual: React.FC<RecordingsProps> = ({recordingId}) => {

  const [busy, setBusy] = useState(true);
  const [recording, setRecording] = useState<any>();

  useEffect(()=>{
    let isMounted = true;

    readRecordingById(recordingId, (r:any)=>{
      if (isMounted) setRecording(r);
      if (isMounted) setBusy(false);
    })

    return () => { isMounted = false };
  },[])

  return (
      busy ? <IonSpinner /> :

      <div className="container recordings-container">
        <div className="recordings-iframe-container">
          <iframe src={recording.videoURL} frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen title={recording.title} className="recordings-video-iframe">
          </iframe>
        </div>
        <script src="https://player.vimeo.com/api/player.js"></script>
      </div>
  );
};

const Recordings: React.FC<RecordingsProps> = ({recordingId}) => {

  const [busy, setBusy] = useState<boolean>(true);
  const [currentPage, setCurrentPage] = useState<string>("home");

  useEffect(()=>{
    let isMounted = true;

    if (recordingId){
      if (isMounted) setCurrentPage("individual");
    }

    if (isMounted) setBusy(false);

    return () => { isMounted = false };
  },[])

  return (
      busy ? <IonSpinner /> :

      <div className="container recordings-container">
        {/*<div className="recordings-navigation">
          <div className="recordings-navigation-item">
            <IonButton className="recordings-navigation-button" fill="clear" onClick={() => {
              setCurrentPage("home");
              window.history.pushState("", "", '/Recordings');
            }}>
              Home
            </IonButton>
            {
              (currentPage == "home" ?
                <div className="matches-navigation-selected"> </div>
                :
                ""
              )
            }
          </div>
          <div className="recordings-navigation-item">
            <IonButton className="recordings-navigation-button" fill="clear" onClick={() => {
              setCurrentPage("personal");
              window.history.pushState("", "", '/Recordings');
            }}>
              My Recordings
            </IonButton>
            {
              (currentPage == "personal" ?
                <div className="matches-navigation-selected"> </div>
                :
                ""
              )
            }
          </div>
        </div>*/}
        {
          {
            "home": <RecordingsHome />,
            "personal": <RecordingsPersonal />,
            "individual": <RecordingsIndividual recordingId={recordingId}/>
          }[currentPage]
        }
      </div>
  );
};

export default Recordings;
