import {
  IonButtons,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardSubtitle,
  IonCardContent,
  IonItem,
  IonLabel,
  IonList,
  IonAccordionGroup,
  IonAccordion,
  IonToggle,
  useIonViewWillEnter,
  useIonViewDidEnter
 } from '@ionic/react';

 import {
   writeUserAvailabilityTime,
   readUserAvailabilityTimes
 } from '../../firebase';

import { arrowDownCircle } from 'ionicons/icons';
import './AvailabilityDayCard.css';

import React, { useRef, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

interface AvailabilityDayCardProps {
  day: string;
}

/*const accordionGroupRef = useRef(null);
const logAccordionValue = () => {
  if (accordionGroupRef.current) {
    console.log(accordionGroupRef.current.value);
  }
}
const closeAccordion = () => {
  if (accordionGroupRef.current) {
    accordionGroupRef.current.value = undefined;
  }
}*/

const AvailabilityDayCard: React.FC<AvailabilityDayCardProps> = ({day}) => {

  const userId = useSelector((state: any) => state.userData.uid);
  console.log("userId is..."+userId); 

  const [anySelectedMorning, setAnySelectedMorning] = useState<boolean>();
  const [anySelectedAfternoon, setAnySelectedAfternoon] = useState<boolean>();
  const [anySelectedEvening, setAnySelectedEvening] = useState<boolean>();

  const [allSelected, setAllSelected] = useState<boolean>();
  const [allSelectedMorning, setAllSelectedMorning] = useState<boolean>();
  const [allSelectedAfternoon, setAllSelectedAfternoon] = useState<boolean>();
  const [allSelectedEvening, setAllSelectedEvening] = useState<boolean>();

  const [busy, setBusy] = useState(true);

  //TODO - load in state from the datbase
  const [is0600Selected, setIs0600Selected] = useState<boolean>();
  const [is0700Selected, setIs0700Selected] = useState<boolean>();
  const [is0800Selected, setIs0800Selected] = useState<boolean>();
  const [is0900Selected, setIs0900Selected] = useState<boolean>();
  const [is1000Selected, setIs1000Selected] = useState<boolean>();
  const [is1100Selected, setIs1100Selected] = useState<boolean>();
  const [is1200Selected, setIs1200Selected] = useState<boolean>();
  const [is1300Selected, setIs1300Selected] = useState<boolean>();
  const [is1400Selected, setIs1400Selected] = useState<boolean>();
  const [is1500Selected, setIs1500Selected] = useState<boolean>();
  const [is1600Selected, setIs1600Selected] = useState<boolean>();
  const [is1700Selected, setIs1700Selected] = useState<boolean>();
  const [is1800Selected, setIs1800Selected] = useState<boolean>();
  const [is1900Selected, setIs1900Selected] = useState<boolean>();
  const [is2000Selected, setIs2000Selected] = useState<boolean>();
  const [is2100Selected, setIs2100Selected] = useState<boolean>();

  useEffect(() => {

    readUserAvailabilityTimes(userId).then((times: any)=> {
      console.log(times);
      console.log(day);
      //console.log(times[day]["0600"]);

      if (typeof times[day] == "undefined"){
        times[day] = {};
      }

      if (typeof times[day]["0600"] == "undefined"){
        times[day]["0600"] = is0600Selected;
      }
      if (typeof times[day]["0700"] == "undefined"){
        times[day]["0700"] = is0700Selected;
      }
      if (typeof times[day]["0800"] == "undefined"){
        times[day]["0800"] = is0800Selected;
      }
      if (typeof times[day]["0900"] == "undefined"){
        times[day]["0900"] = is0900Selected;
      }
      if (typeof times[day]["1000"] == "undefined"){
        times[day]["1000"] = is1000Selected;
      }
      if (typeof times[day]["1100"] == "undefined"){
        times[day]["1100"] = is1100Selected;
      }
      if (typeof times[day]["1200"] == "undefined"){
        times[day]["1200"] = is1200Selected;
      }
      if (typeof times[day]["1300"] == "undefined"){
        times[day]["1300"] = is1300Selected;
      }
      if (typeof times[day]["1400"] == "undefined"){
        times[day]["1400"] = is1400Selected;
      }
      if (typeof times[day]["1500"] == "undefined"){
        times[day]["1500"] = is1500Selected;
      }
      if (typeof times[day]["1600"] == "undefined"){
        times[day]["1600"] = is1600Selected;
      }
      if (typeof times[day]["1700"] == "undefined"){
        times[day]["1700"] = is1700Selected;
      }
      if (typeof times[day]["1800"] == "undefined"){
        times[day]["1800"] = is1800Selected;
      }
      if (typeof times[day]["1900"] == "undefined"){
        times[day]["1900"] = is1900Selected;
      }
      if (typeof times[day]["2000"] == "undefined"){
        times[day]["2000"] = is2000Selected;
      }
      if (typeof times[day]["2100"] == "undefined"){
        times[day]["2100"] = is2100Selected;
      }

      setIs0600Selected(times[day]["0600"]);
      setIs0700Selected(times[day]["0700"]);
      setIs0800Selected(times[day]["0800"]);
      setIs0900Selected(times[day]["0900"]);
      setIs1000Selected(times[day]["1000"]);
      setIs1100Selected(times[day]["1100"]);
      setIs1200Selected(times[day]["1200"]);
      setIs1300Selected(times[day]["1300"]);
      setIs1400Selected(times[day]["1400"]);
      setIs1500Selected(times[day]["1500"]);
      setIs1600Selected(times[day]["1600"]);
      setIs1700Selected(times[day]["1700"]);
      setIs1800Selected(times[day]["1800"]);
      setIs1900Selected(times[day]["1900"]);
      setIs2000Selected(times[day]["2000"]);
      setIs2100Selected(times[day]["2100"]);

      var check0600: boolean = times[day]["0600"];
      var check0700: boolean = times[day]["0700"];
      var check0800: boolean = times[day]["0800"];
      var check0900: boolean = times[day]["0900"];
      var check1000: boolean = times[day]["1000"];
      var check1100: boolean = times[day]["1100"];
      var check1200: boolean = times[day]["1200"];
      var check1300: boolean = times[day]["1300"];
      var check1400: boolean = times[day]["1400"];
      var check1500: boolean = times[day]["1500"];
      var check1600: boolean = times[day]["1600"];
      var check1700: boolean = times[day]["1700"];
      var check1800: boolean = times[day]["1800"];
      var check1900: boolean = times[day]["1900"];
      var check2000: boolean = times[day]["2000"];
      var check2100: boolean = times[day]["2100"];



      //check if any times are selected for morning, afternoon, or evening so we can change colors
      var checkAnyMorning: boolean;
      var checkAnyAfternoon: boolean;
      var checkAnyEvening: boolean;

      if (check0600 || check0700 || check0800 || check0800 || check1000){
        checkAnyMorning = true;
      }
      else {
        checkAnyMorning = false;
      }

      if (check1100 || check1200 || check1300 || check1400 || check1500 || check1600){
        checkAnyAfternoon = true;
      }
      else {
        checkAnyAfternoon = false;
      }

      if (check1700 || check1800 || check1900 || check2000 || check2100){
        checkAnyEvening = true;
      }
      else {
        checkAnyEvening = false;
      }

      setAnySelectedMorning(checkAnyMorning);
      setAnySelectedAfternoon(checkAnyAfternoon);
      setAnySelectedEvening(checkAnyEvening);

      //check if all times are set for morning, afternoon, or evening so we can set the 'Select All' toggles
      var checkAllMorning: boolean;
      var checkAllAfternoon: boolean;
      var checkAllEvening: boolean;
      var checkAll: boolean;

      if (check0600 && check0700 && check0800 && check0900 && check1000){
        checkAllMorning = true;
      }
      else {
        checkAllMorning = false;
      }

      if (check1100 && check1200 && check1300 && check1400 && check1500 && check1600){
        checkAllAfternoon = true;
      }
      else {
        checkAllAfternoon = false;
      }

      if (check1700 && check1800 && check1900 && check2000 && check2100){
        checkAllEvening = true;
      }
      else {
        checkAllEvening = false;
      }

      if (checkAllMorning && checkAllAfternoon && checkAllEvening){
        checkAll = true;
      }
      else {
        checkAll = false;
      }

      if (checkAllMorning){
        setAllSelectedMorning(checkAllMorning);
      }
      if (checkAllAfternoon){
        setAllSelectedAfternoon(checkAllAfternoon);
      }
      if (checkAllEvening) {
        setAllSelectedEvening(checkAllEvening);
      }
      if (checkAll){
        setAllSelected(checkAll);
      }

      setBusy(false);
    }).catch((e) => {
      console.log(e);
      setBusy(false);
    });
  }, []);

  return (
    <IonCard className="availability-day-card">
      <IonCardHeader className="availability-day-card-header">
        <IonCardTitle>{day}</IonCardTitle>
      </IonCardHeader>
      <IonAccordionGroup>
        <IonItem key="all">
          <IonLabel>{allSelected ? "Deselect All" : "Select All"}</IonLabel>
          <IonToggle
            checked={allSelected}
            onClick={(e: any)=>{
              setAllSelected(e.target.checked);

              setAnySelectedMorning(e.target.checked);
              setAllSelectedMorning(e.target.checked);
              setIs0600Selected(e.target.checked);
              writeUserAvailabilityTime(userId, day, "0600", e.target.checked);
              setIs0700Selected(e.target.checked);
              writeUserAvailabilityTime(userId, day, "0700", e.target.checked);
              setIs0800Selected(e.target.checked);
              writeUserAvailabilityTime(userId, day, "0800", e.target.checked);
              setIs0900Selected(e.target.checked);
              writeUserAvailabilityTime(userId, day, "0900", e.target.checked);
              setIs1000Selected(e.target.checked);
              writeUserAvailabilityTime(userId, day, "1000", e.target.checked);

              setAnySelectedAfternoon(e.target.checked);
              setAllSelectedAfternoon(e.target.checked);
              setIs1100Selected(e.target.checked);
              writeUserAvailabilityTime(userId, day, "1100", e.target.checked);
              setIs1200Selected(e.target.checked);
              writeUserAvailabilityTime(userId, day, "1200", e.target.checked);
              setIs1300Selected(e.target.checked);
              writeUserAvailabilityTime(userId, day, "1300", e.target.checked);
              setIs1400Selected(e.target.checked);
              writeUserAvailabilityTime(userId, day, "1400", e.target.checked);
              setIs1500Selected(e.target.checked);
              writeUserAvailabilityTime(userId, day, "1500", e.target.checked);
              setIs1600Selected(e.target.checked);
              writeUserAvailabilityTime(userId, day, "1600", e.target.checked);

              setAnySelectedEvening(e.target.checked);
              setAllSelectedEvening(e.target.checked);
              setIs1700Selected(e.target.checked);
              writeUserAvailabilityTime(userId, day, "1700", e.target.checked);
              setIs1800Selected(e.target.checked);
              writeUserAvailabilityTime(userId, day, "1800", e.target.checked);
              setIs1900Selected(e.target.checked);
              writeUserAvailabilityTime(userId, day, "1900", e.target.checked);
              setIs2000Selected(e.target.checked);
              writeUserAvailabilityTime(userId, day, "2000", e.target.checked);
              setIs2100Selected(e.target.checked);
              writeUserAvailabilityTime(userId, day, "2100", e.target.checked);
            }}
          />
        </IonItem>
        <IonAccordion value="morning" toggleIcon={arrowDownCircle} className={anySelectedMorning ? "times-selected" : ""}>
          <IonItem slot="header" color="light">
            <IonLabel>Morning</IonLabel>
          </IonItem>
          <IonList slot="content">
            <IonItem key="morning">
              <IonLabel>{allSelectedMorning ? "Deselect All" : "Select All"}</IonLabel>
              <IonToggle
                checked={allSelectedMorning}
                onClick={(e: any)=>{
                  setAllSelectedMorning(e.target.checked);
                  setAnySelectedMorning(e.target.checked);
                  setIs0600Selected(e.target.checked);
                  writeUserAvailabilityTime(userId, day, "0600", e.target.checked);
                  setIs0700Selected(e.target.checked);
                  writeUserAvailabilityTime(userId, day, "0700", e.target.checked);
                  setIs0800Selected(e.target.checked);
                  writeUserAvailabilityTime(userId, day, "0800", e.target.checked);
                  setIs0900Selected(e.target.checked);
                  writeUserAvailabilityTime(userId, day, "0900", e.target.checked);
                  setIs1000Selected(e.target.checked);
                  writeUserAvailabilityTime(userId, day, "1000", e.target.checked);
                }}
              />
            </IonItem>
            <IonItem key="0600">
              <IonLabel>6:00am</IonLabel>
              <IonToggle
                checked={is0600Selected}
                onClick={(e: any)=>{
                  setIs0600Selected(e.target.checked);
                  writeUserAvailabilityTime(userId, day, "0600", e.target.checked);
                  if (e.target.checked || is0700Selected || is0800Selected || is0900Selected || is1000Selected){
                    setAnySelectedMorning(true);
                  }
                  else {
                    setAnySelectedMorning(false);
                  }

                  if (e.target.checked && is0700Selected && is0800Selected && is0900Selected && is1000Selected){
                    setAllSelectedMorning(true);
                  }
                  else {
                    setAllSelectedMorning(false);
                  }

                  if (e.target.checked && is0700Selected && is0800Selected && is0900Selected && is1000Selected && is1100Selected && is1200Selected && is1300Selected && is1400Selected && is1500Selected && is1600Selected && is1700Selected && is1800Selected && is1900Selected && is2000Selected && is2100Selected){
                    setAllSelected(true);
                  }
                  else {
                    setAllSelected(false);
                  }

                }}
              />
            </IonItem>
            <IonItem key="0700">
              <IonLabel>7:00am</IonLabel>
              <IonToggle
                checked={is0700Selected}
                onClick={(e: any)=>{
                  setIs0700Selected(e.target.checked);
                  writeUserAvailabilityTime(userId, day, "0700", e.target.checked);
                  if (is0600Selected || e.target.checked || is0800Selected || is0900Selected || is1000Selected){
                    setAnySelectedMorning(true);
                  }
                  else {
                    setAnySelectedMorning(false);
                  }

                  if (is0600Selected && e.target.checked  && is0800Selected && is0900Selected && is1000Selected){
                    setAllSelectedMorning(true);
                  }
                  else {
                    setAllSelectedMorning(false);
                  }

                  if (is0600Selected && e.target.checked && is0800Selected && is0900Selected && is1000Selected && is1100Selected && is1200Selected && is1300Selected && is1400Selected && is1500Selected && is1600Selected && is1700Selected && is1800Selected && is1900Selected && is2000Selected && is2100Selected){
                    setAllSelected(true);
                  }
                  else {
                    setAllSelected(false);
                  }
                }}
              />
            </IonItem>
            <IonItem key="0800">
              <IonLabel>8:00am</IonLabel>
              <IonToggle
                checked={is0800Selected}
                onClick={(e: any)=>{
                  setIs0800Selected(e.target.checked);
                  writeUserAvailabilityTime(userId, day, "0800", e.target.checked);
                  if (is0600Selected || is0700Selected || e.target.checked || is0900Selected || is1000Selected){
                    setAnySelectedMorning(true);
                  }
                  else {
                    setAnySelectedMorning(false);
                  }

                  if (is0600Selected && is0700Selected && e.target.checked && is0900Selected && is1000Selected){
                    setAllSelectedMorning(true);
                  }
                  else {
                    setAllSelectedMorning(false);
                  }

                  if (is0600Selected && is0700Selected && e.target.checked && is0900Selected && is1000Selected && is1100Selected && is1200Selected && is1300Selected && is1400Selected && is1500Selected && is1600Selected && is1700Selected && is1800Selected && is1900Selected && is2000Selected && is2100Selected){
                    setAllSelected(true);
                  }
                  else {
                    setAllSelected(false);
                  }
                }}
              />
            </IonItem>
            <IonItem key="0900">
              <IonLabel>9:00am</IonLabel>
              <IonToggle
                checked={is0900Selected}
                onClick={(e: any)=>{
                  setIs0900Selected(e.target.checked);
                  writeUserAvailabilityTime(userId, day, "0900", e.target.checked);
                  if (is0600Selected || is0700Selected || is0800Selected || e.target.checked || is1000Selected){
                    setAnySelectedMorning(true);
                  }
                  else {
                    setAnySelectedMorning(false);
                  }

                  if (is0600Selected && is0700Selected && is0800Selected && e.target.checked && is1000Selected){
                    setAllSelectedMorning(true);
                  }
                  else {
                    setAllSelectedMorning(false);
                  }

                  if (is0600Selected && is0700Selected && is0800Selected && e.target.checked && is1000Selected && is1100Selected && is1200Selected && is1300Selected && is1400Selected && is1500Selected && is1600Selected && is1700Selected && is1800Selected && is1900Selected && is2000Selected && is2100Selected){
                    setAllSelected(true);
                  }
                  else {
                    setAllSelected(false);
                  }
                }}
              />
            </IonItem>
            <IonItem key="1000">
              <IonLabel>10:00am</IonLabel>
              <IonToggle
                checked={is1000Selected}
                onClick={(e: any)=>{
                  setIs1000Selected(e.target.checked);
                  writeUserAvailabilityTime(userId, day, "1000", e.target.checked);
                  if (is0600Selected || is0700Selected || is0800Selected || is0900Selected || e.target.checked){
                    setAnySelectedMorning(true);
                  }
                  else {
                    setAnySelectedMorning(false);
                  }

                  if (is0600Selected && is0700Selected && is0800Selected && is0900Selected && e.target.checked){
                    setAllSelectedMorning(true);
                  }
                  else {
                    setAllSelectedMorning(false);
                  }

                  if (is0600Selected && is0700Selected && is0800Selected && is0900Selected && e.target.checked && is1100Selected && is1200Selected && is1300Selected && is1400Selected && is1500Selected && is1600Selected && is1700Selected && is1800Selected && is1900Selected && is2000Selected && is2100Selected){
                    setAllSelected(true);
                  }
                  else {
                    setAllSelected(false);
                  }

                }}
              />
            </IonItem>
          </IonList>
        </IonAccordion>
        <IonAccordion value="afternoon" toggleIcon={arrowDownCircle} className={anySelectedAfternoon ? "times-selected" : ""}>
          <IonItem slot="header" color="light">
            <IonLabel>Afternoon</IonLabel>
          </IonItem>
          <IonList slot="content">
            <IonItem key="afternoon">
              <IonLabel>{allSelectedAfternoon ? "Deselect All" : "Select All"}</IonLabel>
              <IonToggle
                checked={allSelectedAfternoon}
                onClick={(e: any)=>{
                  setAllSelectedAfternoon(e.target.checked);
                  setAnySelectedAfternoon(e.target.checked);
                  setIs1100Selected(e.target.checked);
                  writeUserAvailabilityTime(userId, day, "1100", e.target.checked);
                  setIs1200Selected(e.target.checked);
                  writeUserAvailabilityTime(userId, day, "1200", e.target.checked);
                  setIs1300Selected(e.target.checked);
                  writeUserAvailabilityTime(userId, day, "1300", e.target.checked);
                  setIs1400Selected(e.target.checked);
                  writeUserAvailabilityTime(userId, day, "1400", e.target.checked);
                  setIs1500Selected(e.target.checked);
                  writeUserAvailabilityTime(userId, day, "1500", e.target.checked);
                  setIs1600Selected(e.target.checked);
                  writeUserAvailabilityTime(userId, day, "1600", e.target.checked);
                }}
              />
            </IonItem>
            <IonItem key="1100">
              <IonLabel>11:00am</IonLabel>
              <IonToggle
                checked={is1100Selected}
                onClick={(e: any)=>{
                  setIs1100Selected(e.target.checked);
                  writeUserAvailabilityTime(userId, day, "1100", e.target.checked);

                  if (e.target.checked || is1200Selected || is1300Selected || is1400Selected || is1500Selected || is1600Selected){
                    setAnySelectedAfternoon(true);
                  }
                  else {
                    setAnySelectedAfternoon(false);
                  }

                  if (e.target.checked && is1200Selected && is1300Selected && is1400Selected && is1500Selected && is1600Selected){
                    setAllSelectedAfternoon(true);
                  }
                  else {
                    setAllSelectedAfternoon(false);
                  }

                  if (is0600Selected && is0700Selected && is0800Selected && is0900Selected && is1000Selected && e.target.checked && is1200Selected && is1300Selected && is1400Selected && is1500Selected && is1600Selected && is1700Selected && is1800Selected && is1900Selected && is2000Selected && is2100Selected){
                    setAllSelected(true);
                  }
                  else {
                    setAllSelected(false);
                  }
                }}
              />
            </IonItem>
            <IonItem key="1200">
              <IonLabel>12:00pm</IonLabel>
              <IonToggle
                checked={is1200Selected}
                onClick={(e: any)=>{
                  setIs1200Selected(e.target.checked);
                  writeUserAvailabilityTime(userId, day, "1200", e.target.checked);

                  if (is1100Selected || e.target.checked || is1300Selected || is1400Selected || is1500Selected || is1600Selected){
                    setAnySelectedAfternoon(true);
                  }
                  else {
                    setAnySelectedAfternoon(false);
                  }

                  if (is1100Selected && e.target.checked && is1300Selected && is1400Selected && is1500Selected && is1600Selected){
                    setAllSelectedAfternoon(true);
                  }
                  else {
                    setAllSelectedAfternoon(false);
                  }

                  if (is0600Selected && is0700Selected && is0800Selected && is0900Selected && is1000Selected && is1100Selected && e.target.checked && is1300Selected && is1400Selected && is1500Selected && is1600Selected && is1700Selected && is1800Selected && is1900Selected && is2000Selected && is2100Selected){
                    setAllSelected(true);
                  }
                  else {
                    setAllSelected(false);
                  }
                }}
              />
            </IonItem>
            <IonItem key="1300">
              <IonLabel>1:00pm</IonLabel>
              <IonToggle
                checked={is1300Selected}
                onClick={(e: any)=>{
                  setIs1300Selected(e.target.checked);
                  writeUserAvailabilityTime(userId, day, "1300", e.target.checked);

                  if (is1100Selected || is1200Selected || e.target.checked || is1400Selected || is1500Selected || is1600Selected){
                    setAnySelectedAfternoon(true);
                  }
                  else {
                    setAnySelectedAfternoon(false);
                  }

                  if (is1100Selected && is1200Selected && e.target.checked && is1400Selected && is1500Selected && is1600Selected){
                    setAllSelectedAfternoon(true);
                  }
                  else {
                    setAllSelectedAfternoon(false);
                  }

                  if (is0600Selected && is0700Selected && is0800Selected && is0900Selected && is1000Selected && is1100Selected && is1200Selected && e.target.checked && is1400Selected && is1500Selected && is1600Selected && is1700Selected && is1800Selected && is1900Selected && is2000Selected && is2100Selected){
                    setAllSelected(true);
                  }
                  else {
                    setAllSelected(false);
                  }
                }}
              />
            </IonItem>
            <IonItem key="1400">
              <IonLabel>2:00pm</IonLabel>
              <IonToggle
                checked={is1400Selected}
                onClick={(e: any)=>{
                  setIs1400Selected(e.target.checked);
                  writeUserAvailabilityTime(userId, day, "1400", e.target.checked);

                  if (is1100Selected || is1200Selected || is1300Selected || e.target.checked || is1500Selected || is1600Selected){
                    setAnySelectedAfternoon(true);
                  }
                  else {
                    setAnySelectedAfternoon(false);
                  }

                  if (is1100Selected && is1200Selected && is1300Selected && e.target.checked && is1500Selected && is1600Selected){
                    setAllSelectedAfternoon(true);
                  }
                  else {
                    setAllSelectedAfternoon(false);
                  }

                  if (is0600Selected && is0700Selected && is0800Selected && is0900Selected && is1000Selected && is1100Selected && is1200Selected && is1300Selected && e.target.checked && is1500Selected && is1600Selected && is1700Selected && is1800Selected && is1900Selected && is2000Selected && is2100Selected){
                    setAllSelected(true);
                  }
                  else {
                    setAllSelected(false);
                  }
                }}
              />
            </IonItem>
            <IonItem key="1500">
              <IonLabel>3:00pm</IonLabel>
              <IonToggle
                checked={is1500Selected}
                onClick={(e: any)=>{
                  setIs1500Selected(e.target.checked);
                  writeUserAvailabilityTime(userId, day, "1500", e.target.checked);

                  if (is1100Selected || is1200Selected || is1300Selected || is1400Selected || e.target.checked || is1600Selected){
                    setAnySelectedAfternoon(true);
                  }
                  else {
                    setAnySelectedAfternoon(false);
                  }

                  if (is1100Selected && is1200Selected && is1300Selected && is1400Selected && e.target.checked && is1600Selected){
                    setAllSelectedAfternoon(true);
                  }
                  else {
                    setAllSelectedAfternoon(false);
                  }

                  if (is0600Selected && is0700Selected && is0800Selected && is0900Selected && is1000Selected && is1100Selected && is1200Selected && is1300Selected && is1400Selected && e.target.checked && is1600Selected && is1700Selected && is1800Selected && is1900Selected && is2000Selected && is2100Selected){
                    setAllSelected(true);
                  }
                  else {
                    setAllSelected(false);
                  }
                }}
              />
            </IonItem>
            <IonItem key="1600">
              <IonLabel>4:00pm</IonLabel>
              <IonToggle
                checked={is1600Selected}
                onClick={(e: any)=>{
                  setIs1600Selected(e.target.checked);
                  writeUserAvailabilityTime(userId, day, "1600", e.target.checked);

                  if (is1100Selected || is1200Selected || is1300Selected || is1400Selected || is1500Selected || e.target.checked){
                    setAnySelectedAfternoon(true);
                  }
                  else {
                    setAnySelectedAfternoon(false);
                  }

                  if (is1100Selected && is1200Selected && is1300Selected && is1400Selected && is1500Selected && e.target.checked){
                    setAllSelectedAfternoon(true);
                  }
                  else {
                    setAllSelectedAfternoon(false);
                  }

                  if (is0600Selected && is0700Selected && is0800Selected && is0900Selected && is1000Selected && is1100Selected && is1200Selected && is1300Selected && is1400Selected && is1500Selected && e.target.checked && is1700Selected && is1800Selected && is1900Selected && is2000Selected && is2100Selected){
                    setAllSelected(true);
                  }
                  else {
                    setAllSelected(false);
                  }
                }}
              />
            </IonItem>
          </IonList>
        </IonAccordion>
        <IonAccordion value="evening" toggleIcon={arrowDownCircle} className={anySelectedEvening ? "times-selected" : ""}>
          <IonItem slot="header" color="light">
            <IonLabel>Evening</IonLabel>
          </IonItem>
          <IonList slot="content">
            <IonItem key="evening">
              <IonLabel>{allSelectedEvening ? "Deselect All" : "Select All"}</IonLabel>
              <IonToggle
                checked={allSelectedEvening}
                onClick={(e: any)=>{
                  setAllSelectedEvening(e.target.checked);
                  setAnySelectedEvening(e.target.checked);
                  setIs1700Selected(e.target.checked);
                  writeUserAvailabilityTime(userId, day, "1700", e.target.checked);
                  setIs1800Selected(e.target.checked);
                  writeUserAvailabilityTime(userId, day, "1800", e.target.checked);
                  setIs1900Selected(e.target.checked);
                  writeUserAvailabilityTime(userId, day, "1900", e.target.checked);
                  setIs2000Selected(e.target.checked);
                  writeUserAvailabilityTime(userId, day, "2000", e.target.checked);
                  setIs2100Selected(e.target.checked);
                  writeUserAvailabilityTime(userId, day, "2100", e.target.checked);
                }}
              />
            </IonItem>
            <IonItem key="1700">
              <IonLabel>5:00pm</IonLabel>
              <IonToggle
                checked={is1700Selected}
                onClick={(e: any)=>{
                  setIs1700Selected(e.target.checked);
                  writeUserAvailabilityTime(userId, day, "1700", e.target.checked);

                  if (e.target.checked || is1800Selected || is1900Selected || is2000Selected || is2100Selected){
                    setAnySelectedEvening(true);
                  }
                  else {
                    setAnySelectedEvening(false);
                  }

                  if (e.target.checked && is1800Selected && is1900Selected && is2000Selected && is2100Selected){
                    setAllSelectedEvening(true);
                  }
                  else {
                    setAllSelectedEvening(false);
                  }

                  if (is0600Selected && is0700Selected && is0800Selected && is0900Selected && is1000Selected && is1100Selected && is1200Selected && is1300Selected && is1400Selected && is1500Selected && is1600Selected && e.target.checked && is1800Selected && is1900Selected && is2000Selected && is2100Selected){
                    setAllSelected(true);
                  }
                  else {
                    setAllSelected(false);
                  }
                }}
              />
            </IonItem>
            <IonItem key="1800">
              <IonLabel>6:00pm</IonLabel>
              <IonToggle
                checked={is1800Selected}
                onClick={(e: any)=>{
                  setIs1800Selected(e.target.checked);
                  writeUserAvailabilityTime(userId, day, "1800", e.target.checked);

                  if (is1700Selected || e.target.checked || is1900Selected || is2000Selected || is2100Selected){
                    setAnySelectedEvening(true);
                  }
                  else {
                    setAnySelectedEvening(false);
                  }

                  if (is1700Selected && e.target.checked && is1900Selected && is2000Selected && is2100Selected){
                    setAllSelectedEvening(true);
                  }
                  else {
                    setAllSelectedEvening(false);
                  }

                  if (is0600Selected && is0700Selected && is0800Selected && is0900Selected && is1000Selected && is1100Selected && is1200Selected && is1300Selected && is1400Selected && is1500Selected && is1600Selected && is1700Selected && e.target.checked && is1900Selected && is2000Selected && is2100Selected){
                    setAllSelected(true);
                  }
                  else {
                    setAllSelected(false);
                  }
                }}
              />
            </IonItem>
            <IonItem key="1900">
              <IonLabel>7:00pm</IonLabel>
              <IonToggle
                checked={is1900Selected}
                onClick={(e: any)=>{
                  setIs1900Selected(e.target.checked);
                  writeUserAvailabilityTime(userId, day, "1900", e.target.checked);

                  if (is1700Selected|| is1800Selected || e.target.checked || is2000Selected || is2100Selected){
                    setAnySelectedEvening(true);
                  }
                  else {
                    setAnySelectedEvening(false);
                  }

                  if (is1700Selected && is1800Selected && e.target.checked && is2000Selected && is2100Selected){
                    setAllSelectedEvening(true);
                  }
                  else {
                    setAllSelectedEvening(false);
                  }

                  if (is0600Selected && is0700Selected && is0800Selected && is0900Selected && is1000Selected && is1100Selected && is1200Selected && is1300Selected && is1400Selected && is1500Selected && is1600Selected && is1700Selected && is1800Selected && e.target.checked && is2000Selected && is2100Selected){
                    setAllSelected(true);
                  }
                  else {
                    setAllSelected(false);
                  }
                }}
              />
            </IonItem>
            <IonItem key="2000">
              <IonLabel>8:00pm</IonLabel>
              <IonToggle
                checked={is2000Selected}
                onClick={(e: any)=>{
                  setIs2000Selected(e.target.checked);
                  writeUserAvailabilityTime(userId, day, "2000", e.target.checked);

                  if (is1700Selected || is1800Selected || is1900Selected || e.target.checked || is2100Selected){
                    setAnySelectedEvening(true);
                  }
                  else {
                    setAnySelectedEvening(false);
                  }

                  if (is1700Selected && is1800Selected && is1900Selected && e.target.checked && is2100Selected){
                    setAllSelectedEvening(true);
                  }
                  else {
                    setAllSelectedEvening(false);
                  }

                  if (is0600Selected && is0700Selected && is0800Selected && is0900Selected && is1000Selected && is1100Selected && is1200Selected && is1300Selected && is1400Selected && is1500Selected && is1600Selected && is1700Selected && is1800Selected && is1900Selected && e.target.checked && is2100Selected){
                    setAllSelected(true);
                  }
                  else {
                    setAllSelected(false);
                  }
                }}
              />
            </IonItem>
            <IonItem key="2100">
              <IonLabel>9:00pm</IonLabel>
              <IonToggle
                checked={is2100Selected}
                onClick={(e: any)=>{
                  setIs2100Selected(e.target.checked);
                  writeUserAvailabilityTime(userId, day, "2100", e.target.checked);

                  if (is1700Selected || is1800Selected || is1900Selected || is2000Selected || e.target.checked){
                    setAnySelectedEvening(true);
                  }
                  else {
                    setAnySelectedEvening(false);
                  }

                  if (is1700Selected && is1800Selected && is1900Selected && is2000Selected && e.target.checked){
                    setAllSelectedEvening(true);
                  }
                  else {
                    setAllSelectedEvening(false);
                  }

                  if (is0600Selected && is0700Selected && is0800Selected && is0900Selected && is1000Selected && is1100Selected && is1200Selected && is1300Selected && is1400Selected && is1500Selected && is1600Selected && is1700Selected && is1800Selected && is1900Selected && is2000Selected && e.target.checked){
                    setAllSelected(true);
                  }
                  else {
                    setAllSelected(false);
                  }
                }}
              />
            </IonItem>
          </IonList>
        </IonAccordion>
      </IonAccordionGroup>
    </IonCard>
  );
}


//export default AvailabilityDayCard;

export default ({day}: { day: string }) => (
  <AvailabilityDayCard day={day}>
  </AvailabilityDayCard>
)
