import {
  IonSearchbar,
  IonSelect,
  IonSelectOption,
  IonToast,
  IonIcon,
  IonButton,
  IonPopover,
  IonContent,
  IonRadioGroup,
  IonRadio,
  IonListHeader,
  IonItem,
  IonLabel
 } from '@ionic/react';

 import {
   readUserProfileById,
   readUserMatchmakingMatches,
   readGames,
   readSplitById,
   readAllUsers
 } from '../../firebase';

import {
  checkmarkDoneCircleOutline,
  funnel,
  options,
  archiveOutline
} from 'ionicons/icons';
import './UsersSearchFilter.css';

import React, { useRef, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import moment from 'moment-timezone';

interface UsersSearchFilterProps {
  allUsers?: any;
  setAllUsers?: any;
  filteredUsers?: any;
  setFilteredUsers?: any;
}

const compareWith = (o1: any, o2: any) => {
  return o1 && o2 ? o1.id === o2.id : o1 === o2;
};

const UsersSearchFilter: React.FC<UsersSearchFilterProps> = ({allUsers, setAllUsers, filteredUsers, setFilteredUsers}) => {

  const [games, setGames] = useState([]);
  const [searchPlayersText, setSearchPlayersText] = useState(''); //TODO - implement player search in the parent component

  const [search, setSearch] = useState(games); //TODO - specify this to "gamesSearch, setGamesSearch" and refactor in all places
  const [playerSearch, setPlayerSearch] = useState([]); //TODO - implement player search in the parent component

  const [gamesFilter, setGamesFilter] = useState<String[]>([]);
  const [playersFilter, setPlayersFilter] = useState<String[]>([]);

  const [showToast_success, setShowToast_success] = useState(false);

  const [showFilters, setShowFilters] = useState(false);
  const [showSort, setShowSort] = useState(false);
  const [showArchived, setShowArchived] = useState(false);

  const [detectSearch, setDetectSearch] = useState(0);

  const [sortParameter, setSortParameter] = useState('trialDateAscending');

  const userId = useSelector((state: any) => state.userData.uid);

  const [usersSort, setUsersSort] = useState<string>('trialDateAscending');
  const [usersFilter, setUsersFilter] = useState<any>('');
  const [tempUserSearchStrings, setTempUserSearchStrings] = useState<any>({});

  const usersSelectRef = useRef<HTMLIonSelectElement>(null);
  const [searchText, setSearchText] = useState('');

  useEffect(() => {
    /** Using isMounted to prevent React state updates on unmounted components based on this: https://stackoverflow.com/questions/53949393/cant-perform-a-react-state-update-on-an-unmounted-component **/
    let isMounted = true;

    var tempUsers = allUsers.filter((ele:any) => {

      if (ele.displayName){
        if (ele.displayName.toLowerCase().includes(searchText.toLowerCase())){
          return true;
        }
      }
      if (ele.firstName){
        if (ele.firstName.toLowerCase().includes(searchText.toLowerCase())){
          return true;
        }
      }
      if (ele.lastName){
        if (ele.lastName.toLowerCase().includes(searchText.toLowerCase())){
          return true;
        }
      }
      if (ele.discordId){
        if (ele.discordId.toLowerCase().includes(searchText.toLowerCase())){
          return true;
        }
      }
      if (ele.userId){
        if (ele.userId.toLowerCase().includes(searchText.toLowerCase())){
          return true;
        }
      }
      if (ele.igns){
        for (var ign in ele.igns){
          if (ele.igns[ign].toLowerCase().includes(searchText.toLowerCase())){
            return true;
          }
        }
      }
      if (ele.email){
        if (ele.email.toLowerCase().includes(searchText.toLowerCase())){
          return true;
        }
      }

      if (typeof(tempUserSearchStrings[ele.id]) != "undefined"){
        for (var i=0; i<tempUserSearchStrings[ele.id].length; i++){
          if (tempUserSearchStrings[ele.id][i].replace(/_/g, ' ').toLowerCase().includes(searchText.toLowerCase())){
            return true;
          }
        }
      }

      return false;

    });

    tempUsers = tempUsers.filter((user:any) => {

      if (!user.isArchived){
        user.isArchived = false;
      }

      if (user.isArchived == showArchived){
        return true;
      }
    });

    switch(usersSort){
      case 'trialDateAscending':

        if (isMounted) setFilteredUsers(tempUsers.sort((a:any, b:any) => {
          if (moment(a.free_trial_started).isAfter(moment(b.free_trial_started))){
            return 1; //Sort B before A
          }
          else {
            return -1;
          }
        }));
        break;
      case 'trialDateDescending':

        if (isMounted) setFilteredUsers(tempUsers.sort((a:any, b:any) => {
          if (!a.free_trial_started){
            return -1;
          }
          if (!b.free_trial_started){
            return -1;
          }
          if (moment(a.free_trial_started).isAfter(moment(b.free_trial_started))){
            return -1; //Sort A before B
          }
          else {
            return 1;
          }
        }));
        break;

      case 'syncDateAscending':

        if (isMounted) setFilteredUsers(tempUsers.sort((a:any, b:any) => {
          if (!a.createdAt){
            a.createdAt = a.firebase_synced_at*1000;
          }
          if (!b.createdAt){
            b.createdAt = b.firebase_synced_at*1000;
          }

          if (moment(a.createdAt).isAfter(moment(b.createdAt))){
            return 1; //Sort B before A
          }
          else {
            return -1;
          }
        }));
        break;

      case 'syncDateDescending':

        if (isMounted) setFilteredUsers(tempUsers.sort((a:any, b:any) => {
          if (!a.firebase_synced_at){
            return -1;
          }
          if (!b.firebase_synced_at){
            return -1;
          }
          if (!a.createdAt){
            a.createdAt = a.firebase_synced_at*1000;
          }
          if (!b.createdAt){
            b.createdAt = b.firebase_synced_at*1000;
          }
          if (moment(a.createdAt).isAfter(moment(b.createdAt))){
            return -1; //Sort A before B
          }
          else {
            return 1;
          }
        }));
        break;

      case 'loginDateAscending':

        if (isMounted) setFilteredUsers(tempUsers.sort((a:any, b:any) => {
          if (a.lastLoginAt <= 1642013635){
            a.lastLoginAt = a.lastLoginAt * 1000
          }
          if (b.lastLoginAt <= 1642013635){
            b.lastLoginAt = b.lastLoginAt * 1000
          }
          if (moment(a.lastLoginAt).isAfter(moment(b.lastLoginAt))){
            return 1; //Sort B before A
          }
          else {
            return -1;
          }
        }));
        break;

      case 'loginDateDescending':

        if (isMounted) setFilteredUsers(tempUsers.sort((a:any, b:any) => {
          if (a.lastLoginAt <= 9999999999){
            a.lastLoginAt = a.lastLoginAt * 1000
          }
          if (b.lastLoginAt <= 9999999999){
            b.lastLoginAt = b.lastLoginAt * 1000
          }
          if (moment(a.lastLoginAt).isAfter(moment(b.lastLoginAt))){
            return -1; //Sort A before B
          }
          else {
            return 1;
          }
        }));
        break;
      default:
        break;
    }

    return () => { isMounted = false };
  },[searchText, detectSearch, tempUserSearchStrings, usersSort, allUsers, showArchived]);

  return (
      <div className="users-search-filter">
        {/*<h2 className="matchmaking-search-filter-game-title"> Game </h2>*/}


      {/*
        <IonButton fill="outline" color="medium" id="users-filter-button">
            <IonIcon icon={options} className=""/>
        </IonButton>
        <IonPopover trigger="users-filter-button" triggerAction="click">
          <IonContent class="ion-padding">Advanced Filters Coming Soon!</IonContent>
        </IonPopover>
      */}

      <IonButton fill="outline" color="medium" id="users-sort-button">
        <IonIcon icon={funnel} className=""/>
      </IonButton>
      <IonButton fill="outline" color="medium" id="users-archive-button">
        <IonIcon icon={archiveOutline} className=""/>
      </IonButton>
      <IonPopover trigger="users-sort-button" triggerAction="click">
        <IonContent class="ion-padding">
        <IonRadioGroup
          allowEmptySelection={true}
          value={sortParameter}
          onIonChange={(e) => {
            setSortParameter(e.detail.value);
            setUsersSort(e.detail.value);
            //console.log("Setting Users Sort to: "+e.detail.value); //TODO - remove debugging
          }}
        >
          <IonListHeader>
            <IonLabel>
              Sort Users By...
            </IonLabel>
          </IonListHeader>
          <IonItem>
            <IonLabel>Trial Started ↑</IonLabel>
            <IonRadio value="trialDateDescending" />
          </IonItem>
          <IonItem>
            <IonLabel>Trial Started ↓</IonLabel>
            <IonRadio value="trialDateAscending"/>
          </IonItem>
          <IonItem>
            <IonLabel>(~) Date Registered ↑</IonLabel>
            <IonRadio value="syncDateDescending"/>
          </IonItem>
          <IonItem>
            <IonLabel>(~) Date Registered ↓</IonLabel>
            <IonRadio value="syncDateAscending"/>
          </IonItem>
          <IonItem>
            <IonLabel>Last Login ↑</IonLabel>
            <IonRadio value="loginDateDescending"/>
          </IonItem>
          <IonItem>
            <IonLabel>Last Login ↓</IonLabel>
            <IonRadio value="loginDateAscending"/>
          </IonItem>
        </IonRadioGroup>
      </IonContent>
      </IonPopover>
      <IonPopover trigger="users-archive-button" triggerAction="click">
        <IonContent class="ion-padding">
        <IonRadioGroup
          allowEmptySelection={true}
          value={showArchived}
          onIonChange={(e) => {
            setShowArchived(e.detail.value);
          }}
        >
          <IonListHeader>
            <IonLabel>
              Archive...
            </IonLabel>
          </IonListHeader>
          <IonItem>
            <IonLabel>Show Archived</IonLabel>
            <IonRadio value={true} />
          </IonItem>
          <IonItem>
            <IonLabel>Hide Archived</IonLabel>
            <IonRadio value={false}/>
          </IonItem>
        </IonRadioGroup>
      </IonContent>
      </IonPopover>


        <IonSearchbar
          value={searchText}
          className="matchmaking-search"
          placeholder="Search by username, email, discord ID, etc."
          onIonChange={(e) => {
            setSearchText(e.detail.value!);
            setDetectSearch(detectSearch + 1);
          }
        }></IonSearchbar>

        <IonToast
          isOpen={showToast_success}
          onDidDismiss={() => setShowToast_success(false)}
          message="Game Added"
          duration={2000}
          color="primary"
          icon={checkmarkDoneCircleOutline}
          cssClass="timezone-toast"
        />
      </div>
  );
}

export default UsersSearchFilter;
